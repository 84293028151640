import { useState, useEffect } from "react";
import Select from "react-select";
import * as yup from "yup";
import { toast } from 'react-toastify';
import { markutosAdminApi } from "../../../../services/Api/api";
import BannerImage from "./categoryImages/BannerImage";
import IconImage from "./categoryImages/IconImage";
import { useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import { BASE_URL } from "../../../../services/Api/api";
import { useTranslation } from "react-i18next";
import { generateSlug } from "../../../../hooks/helper";

const AddCategory = ({currentItem,setOpenModal,setReload}) => {
	const { t } = useTranslation();

	const schema = yup.object().shape({
		name: yup.string().required(t('cat_req')),
		icon: yup.string().required(t('icon_req')),
		banner: yup.string().required(t('banner_req')),
	});

	const [loading, setLoading]             = useState(false);
	const [categories, setCategoryOptions] 	= useState([]);
	const [apiPath, setApiPath] 	        = useState('');

	useEffect(() => {
		const apiPath = currentItem?.id ? `update-category` :  `add-new-category` ;
		setApiPath(apiPath);
	}, [currentItem]);

	useEffect(() => {
		markutosAdminApi()
		  .get(`/get-categories`)
		  .then((response) => {
			setCategoryOptions(response?.data);
		  });
	  }, []);

	const formik = useFormik({
		validationSchema: schema,
		initialValues: { 
			name: currentItem?.name || "" ,
			slug : currentItem?.slug ||  "",
			parent_category : currentItem?.parent_category || 0,
			order_level : currentItem?.order_level || 0,
			digital : currentItem?.digital || 0,
			banner : currentItem?.banner || "",
			icon : currentItem?.icon || "", // image
			category_icon : currentItem?.category_icon || "",
			commision_rate : currentItem?.commision_rate || "",
			meta_title : currentItem?.meta_title || "",
			meta_description : currentItem?.meta_description || "",
		} ,
		enableReinitialize: true,
		onSubmit: (values, action) => {
			values.slug = values.slug == "" ? generateSlug(values.name) : generateSlug(values.slug);

			if (currentItem) {
				values.category_id = currentItem.id
			}
			setLoading(true)
			markutosAdminApi()
			.post( apiPath , values )
			.then((res) => {
				if (res.data.result == false ) {
					toast.error(res.data.message);
				} else {
					toast.success(res.data.success);	
					setReload(pre=>!pre);
				}
				setLoading(false);
				if (currentItem) {
					setOpenModal(false)
				}
				else{
					action.resetForm();
				}
			})
			.catch((e) => {
				setLoading(false);
				toast.error(e.response.data.slug[0]);
			});
		},
	  });
	
	  const {
		values,
		setErrors,
		handleChange,
		touched,
		errors,
		handleSubmit,
		handleBlur,
		setFieldValue,
	  } = formik;

	return (
		<div>
		<div className="single-widget">
			<div className="widget-title">{`${ currentItem ? t('edit') : t('add')}`} {t('category')}</div>
			<form onSubmit={(e) => e.preventDefault()}>
				<FocusError formik={formik} />
				<div className="widget-container vartical-inputs">
					<div>
						<label htmlFor="name">{t('name')}</label>
						<input 
							type="text" 
							name="name" 
							id="name" 
							value={values.name}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
						{errors.name && touched.name && <p className="error"> {errors.name} </p>}
					</div>
					<div>
						<label htmlFor="slug">{t('slug')}</label>
						<input 
							type="text"
							name="slug"  
							id="slug" 
							value={values.slug == "" ? generateSlug(values.name) : generateSlug(values.slug) }
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</div>
					<div>
						<label htmlFor="parent_category">{t('parent') + ` `+ t('category') } </label>
						<Select options={categories}  
						value={categories.find((option) => {
							return option.value == currentItem?.parent_category;
						  })}
						onChange={(option) => setFieldValue("parent_category", option.value)}
						id="parent_category"
						label={t('all') + ` `+t('categories')}
						/>
						{errors.parent_category && touched.parent_category && <p className="error"> {errors.parent_category} </p>}
					</div>
					<div>
						<label>{t('digital') + ` `+t('category')}</label>
						<label className="confi-switch">
							<input type="checkbox" name="digital"
								onChange={(e)=>setFieldValue('digital',e.target.checked)}
								checked={values.digital} />
							<span className="slider round"></span>
						</label>
					</div>
					<div>
						<label htmlFor="ordering-num">{t('ordering') + ` `+t('number')} </label>
						<input 
							type="text" 
							name="order_level"
							id="ordering-num" 
							value={values.order_level}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</div>
					<div>
						<label htmlFor="category-icon">{t('icon')}</label>
						<input 
							type="text" 
							name="category_icon"
							id="category-icon" 
							value={values.category_icon}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
						<small>{t('icon_desc')+` `} : {`<i class='fa fa-...'></i>`}</small>
					</div>
					
					<IconImage
						setFieldValue={setFieldValue}
						values={values}
						BASE_URL={BASE_URL}
						handleBlur={handleBlur}
						handleChange={handleChange}
						handleSubmit={handleSubmit}
						errors={errors}
						touched={touched}
					/>

					<BannerImage
						setFieldValue={setFieldValue}
						values={values}
						BASE_URL={BASE_URL}
						handleBlur={handleBlur}
						handleChange={handleChange}
						handleSubmit={handleSubmit}
						errors={errors}
						touched={touched}
					/>

					<div>
						<label htmlFor="commision_rate">{t('comission')+` `+t('rate')}</label>
						<input 
							type="text" 
							id="commision_rate" 
							name="commision_rate"
							value={values.commision_rate}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</div>
					<div>
						<label htmlFor="meta-title">{t('meta_title')}</label>
						<input 
							type="text" 
							id="meta-title" 
							name="meta_title"
							value={values.meta_title}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</div>
					<div>
						<label htmlFor="meta-desc">{t('meta_description')}</label>
						<textarea 
							rows={4} 
							type="text" 
							id="meta-desc"
							name="meta_description"
							value={values.meta_description}
							onChange={handleChange}
							onBlur={handleBlur} 
						/>
					</div>
					<div className="btns-container">
						<button className="add-btn"
						    onClick={handleSubmit}
 							type="submit" disabled={loading}>
							{loading && <span className="spinner-grow spinner-grow-sm"></span>}
							{ currentItem ? t('edit') : t('add')}
						</button>
					</div>
				</div>
			</form>
		</div>
		</div>
	);
};

export default AddCategory;

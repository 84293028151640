import { useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FocusError } from "focus-formik-error";
import { markutosAdminApi } from "../../../services/Api/api";
import authHeader from "../../../services/auth-header";
import { useTranslation } from "react-i18next";


const RefundsConfig = () => {
  const { t,i18n } = useTranslation();
  const schema = yup.object().shape({
    refund_time: yup.string().required(t('reject_time_req')),
  });
  
  const [loading, setLoading] = useState(false);
	const [currentItem, setCurrentItem] = useState("");

  useEffect(() => {
    markutosAdminApi()
      .get(`/refund-configuration`)
      .then((res) => {
        setCurrentItem(res.data);
      })
      .catch((e) => {
        toast.error(e.message);
      });

  }, []);

	const formik = useFormik({
		validationSchema: schema,
		initialValues: {
			refund_time: currentItem?.refund_time || 15,
		},
		enableReinitialize: true,
		onSubmit: (values, action) => {
			if (currentItem) {
				values.refund_time_id = currentItem.id
			}
			setLoading(true)
			markutosAdminApi()
			.post(`/refund-configuration/post`, values )
			.then((res) => {
				setLoading(false);
				toast.success(res.data.message);
				if (!currentItem) {
					action.resetForm();
				}
			})
			.catch((e) => {
				setLoading(false)
			  	toast.error(e.message);
			});
		},
	});
	
	const {
	values,
	setErrors,
	handleChange,
	touched,
	errors,
	handleSubmit,
	handleBlur,
	setFieldValue,
	} = formik;
  return (
    <div
      style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "15px" }}
    >
      <div className="single-widget">
        <div className="widget-header">
          <div className="widget-title">{t('set_refund_time')}</div>
        </div>
        <form  onSubmit={(e) => e.preventDefault()}>
          <FocusError formik={formik} />
          <div className="widget-container">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "200px auto 50px",
                alignItems: "center",
                gap: "15px",
                padding: "10px 0",
              }}
            >
              <label htmlFor="refund-time">
                {t('set_ref_req_desc')}
              </label>
              <input type="text" name="refund_time" id="refund-time" min={1}
                  value={values.refund_time}
                  onChange={handleChange}
                  onBlur={handleBlur} />
              <p>{t('days')}</p>
              {errors.refund_time && touched.refund_time && (
                <small className="text-danger"> {errors.refund_time} </small>
              )}
            </div>
            <div className="btns-container">
              <button 
                type="submit"
                onClick={handleSubmit}
                disabled={loading}
                className="add-btn">
                {loading && (
                  <span className="spinner-grow spinner-grow-sm"></span>
                )}
                  {t('save_changes')}
                </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RefundsConfig;

import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import RippleButton from "../../../common/ui/rippleButton";
import "./InHouseProdSale.css";
import { FaFileExport } from "react-icons/fa";
import { FiFilter } from "react-icons/fi";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import DateRangeSelector from "../../../common/ui/dateRangeSelector";
import PaginationCom from "../../../common/pagination/PaginationCom";
import SimpleLoading from "../../SimpleLoading";
import { markutosAdminApi } from "../../../services/Api/api";
import { priceFormat } from "../../../hooks/helper";
import { CSVLink } from "react-csv";
import ReactToPrint from "react-to-print";
import { useTranslation } from "react-i18next";

const InHouseProdSale = () => {
  const { t } = useTranslation();
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [catId, setCatId] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const componentRef = useRef();

  const csvHeaders = [
    {
      label: t("index"),
      key: "id",
    },
    {
      label: t("product_name"),
      key: "name",
    },
    {
      label: t("total")+` `+t("sale"),
      key: "total_sale",
    },
    {
      label:t("total")+` `+t("sale")+` `+ t("amount"),
      key: "total_sale_amount",
    },
  ];

  useEffect(() => {
    markutosAdminApi()
      .get(`/reports/get-categories`)
      .then((response) => {
        setCategoryOptions(response?.data);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    markutosAdminApi()
      .get(`/reports/inhouse-sales?category_id=${catId}&date_from=${startDate}&date_to=${endDate}&perPage=${perPage}&page=${currentPage}`)
      .then((response) => {
        setLoading(false);
        setCurrentItems(response?.data?.data);
        setCurrentPage(response?.data?.current_page);
        setPageCount(response?.data?.last_page);
      });
  }, [perPage, currentPage, startDate, endDate, catId]);

  return (
    <div className="responsive-table-container">
      <div className="single-widget table">
        <div className="table-top-header">
          <div className="table-title">{t("in_house")+` `+t("product")+` `+t("sale")}</div>
          <div className="table-filters">
            <Select
              options={categoryOptions}
              placeholder={t("sort_by_cat")}
              onChange={(e) => setCatId(e.value)}
            />
            <div style={{ display: "flex" }}>
              <DateRangeSelector
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
              <button className="table-filter-btn">
                <FiFilter />
              </button>
            </div>
            <ReactToPrint
              trigger={() => (
                <RippleButton className="table-export-btn">
                  <FaFileExport />
                  <span>{t("print")}</span>
                </RippleButton>
              )}
              content={() => componentRef.current}
            />
            <CSVLink data={currentItems} headers={csvHeaders}>
              <RippleButton className="table-export-btn">
                <BsFillFileEarmarkPdfFill />
                <span>{t("export")}</span>
              </RippleButton>
            </CSVLink>
          </div>
        </div>
        <div className="widget-container">
          <div ref={componentRef}>
            <div
              className="table-header"
              style={{ gridTemplateColumns: "50px 1fr 1fr 1fr 130px" }}
            >
              <div className="text-center">#</div>
              <div>{t('name')}</div>
              <div>{t('image')}</div>
              <div className="text-center">{t('total')+` `+t('sales')}</div>
              <div className="text-center">{t('total')+` `+t('sales')+` `+t('amount')}</div>
            </div>
            <div>
              {loading ? (
                <SimpleLoading />
              ) : (
                currentItems.map((stock, index) => (
                  <div key={index}>
                    <div
                      className="table-row"
                      style={{ gridTemplateColumns: "50px 1fr 1fr 1fr 130px" }}
                    >
                      <div className="text-center">{stock.id}</div>
                      <div>{stock.name}</div>
                      <div className="table-img">
                        <img src={stock.image} height="50" alt="" />
                      </div>
                      <div className="text-center">{stock.total_sale}</div>
                      <div className="text-center">
                        {priceFormat(stock.total_sale_amount)}
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          {currentItems.length > 0 && (
            <PaginationCom
              currentItem={currentItems}
              perPage={perPage}
              pageCount={pageCount}
              currentPage={currentPage}
              setPerPage={setPerPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default InHouseProdSale;

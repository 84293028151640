import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { HiOutlineChevronRight } from "react-icons/hi";
import { RiDeleteBinLine } from "react-icons/ri";
import Select from "react-select";
import DateRangeSelector from "../../../common/ui/dateRangeSelector";
import PaginationCom from "../../../common/pagination/PaginationCom";
import SimpleLoading from "../../SimpleLoading";
import { markutosAdminApi } from "../../../services/Api/api";
import { useDebounce } from "../../../hooks/useDebounce";
import { priceFormat } from "../../../hooks/helper";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const DateFilterOptions = [
  { value: "Today", label: "Today" },
  { value: "Yesterday", label: "Yesterday" },
  { value: "Last 7 days", label: "Last 7 days" },
  { value: "Last 30 days", label: "Last 30 days" },
  { value: "This Month", label: "This Month" },
  { value: "Last Month", label: "Last Month" },
  { value: "Custom Range", label: "Custom Range" },
];

const InHouseOrders = () => {
	const { t,i18n } = useTranslation();
	const deliveryFilterOptions = [
		{ value: "Pending", label: t("pending") },
		{ value: "Confirmed", label: t("confirmed") },
		{ value: "On delivery", label: t("on") +` `+ t("delivery") },
		{ value: "Delivered", label: t("delivered")},
		{ value: "Canceled", label: t("canceled")},
	];
	  
	const [current, setCurrent] = useState(null);
	const [editModal, setEditModal] = useState(false);
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(1);
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(false);
	const debouncedSearchTerm = useDebounce(search, 500);
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [deliveryStatus, setSetDeliveryStatus] = useState('');
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const toggle = (index) => {
		if (index === current) setCurrent(null);
		else setCurrent(index);
	};

	useEffect(() => {
		setLoading(true);
		markutosAdminApi()
		.get(`/orders/in-house-orders?search_value=${search}&delivery_status=${deliveryStatus}&date_from=${startDate}&date_to=${endDate}&per_page=${perPage}&page=${currentPage}`)
		.then((response) => {
			setLoading(false);
			setCurrentItems(response?.data?.data);
			setCurrentPage(response?.data?.current_page);
			setPerPage(response?.data?.per_page);
			setPageCount(response?.data?.last_page);
		})
  }, [perPage, currentPage, search , deliveryStatus, startDate, endDate]);

	useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);

  const removeItem = (id) => {
    Swal.fire({
		title: t("are_sure"),
		text: t("delete_revert"),
		confirmButtonText: t("yes_delete_it"),
		icon: "warning",
		showCancelButton: true,
		confirmButtonColor: "#3085d6",
		cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        markutosAdminApi()
          .get(`/orders/delete-order?order_id=${id}`)
          .then((response) => {
            toast(response.data.message);
			setLoading(true);
          });
      }
    });
  };


  return (
    <div className="responsive-table-container">
      <div className="single-widget table">
        <div className="table-top-header">
		<div className="table-title">{t("in_house") +` `+ t("orders")}</div>
          <div className="table-filters">
            <input
              type="text"
              className="table-search-input"
			  placeholder={t('type_ord_code')}
			  onChange={(e) => setSearch(e.target.value)}
            />
            {/* <Select options={DateFilterOptions} placeholder="Filter by date" /> */}
            <Select
              options={deliveryFilterOptions}
              placeholder={t("delivery") +` `+ t("status")}
              onChange={(e)=>setSetDeliveryStatus(e.value)}
            />
			<div className="text-end pe-4">
				<DateRangeSelector
					startDate={startDate}
					endDate={endDate}
					setStartDate={setStartDate}
					setEndDate={setEndDate}
				/>
			</div>
          </div>
        </div>
        <div className="widget-container">
          <div
            className="table-header"
            style={{
              gridTemplateColumns: "50px 50px auto 100px 150px 150px 150px 50px",
            }}
          >
            <div></div>
            <div>#</div>
            <div>{t("order_id")}</div>
            <div>{t("amount")}</div>
            <div>{t("delivery") +` `+ t("status")}</div>
            <div>{t("payment") +` `+ t("status")}</div>
            <div>{t("order") +` `+ t("date")}</div>
            <div className="text-center">{t("options")}</div>
          </div>
          <div>
			{loading ? (
				<SimpleLoading />
				) : (
				currentItems?.map((order, index) => (
					<div key={index}>
						<div
							className="table-row"
							style={{
								gridTemplateColumns:
									"50px 50px auto 100px 150px 150px 150px 50px",
							}}
						>
							<div
								className="action-column"
								style={{ cursor: "pointer" }}
								onClick={() => toggle(index)}
							>
								<HiOutlineChevronRight />
							</div>
							<div>{order.id}</div>
							<div>{order.code}</div>
							<div>{priceFormat(order.grand_total)}</div>
							<div>
									{order.delivery_status}
							</div>
							<div>{order.payment_status}</div>
							<div>{order.created_at}</div>
							<div className="action-column">
								<button title="Delete" className="table-btn tb-delete" onClick={() => removeItem(`${order.id}`)}>
									<RiDeleteBinLine />
								</button>
							</div>
						</div>
						{current === index && (
							<div className="row-extra">
								<div className="row-extra-row">
									<div>Customer</div>
									<div>{order.customer_name}</div>
								</div>
								<div className="row-extra-row">
									<div>Num of Products</div>
									<div>{order.num_of_products}</div>
								</div>
								<div className="row-extra-row">
									<div>Product details</div>
									<Table bordered>
										<thead>
											<tr>
												<th>Name</th>
												<th>Quantity</th>
												<th>Price</th>
											</tr>
										</thead>
										<tbody>
										{ order.products?.map((order, j ) => (
											<tr key={j}>
												<td>{order.product_name}</td>
												<td>{order.quantity}</td>
												<td>{order.price}</td>
											</tr>
										))
										}
										</tbody>
									</Table>
								</div>
								<div className="row-extra-row">
									<div>Pay method</div>
									<div>{order.payment_type}</div>
								</div>
								<div className="row-extra-row">
									<div>Refund</div>
									<div>{order.refund ? order.refund : "No"} refunded</div>
								</div>
							</div>
						)}
					</div>
				))
			)}
          </div>
			{currentItems.length > 0 && (
				<PaginationCom
					currentItem={currentItems}
					perPage={perPage}
					pageCount={pageCount}
					currentPage={currentPage}
					setPerPage={setPerPage}
					setCurrentPage={setCurrentPage}
				/>
			)}
        </div>
      </div>
    </div>
  );
};
export default InHouseOrders;

import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { HiOutlineChevronRight } from "react-icons/hi";
import { RiDeleteBinLine, RiUserUnfollowLine } from "react-icons/ri";
import Select from "react-select";
import DateRangeSelector from "../../common/ui/dateRangeSelector";
import PaginationCom from "../../common/pagination/PaginationCom";
import SimpleLoading from "../SimpleLoading";
import { markutosAdminApi } from "../../services/Api/api";
import { useDebounce } from "../../hooks/useDebounce";
import { priceFormat } from "../../hooks/helper";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import addUrlParams from "../../utils/addUrlParams";
import { set } from "immutable";
import formatDate from "../../utils/formatDate";
import { FiEdit } from "react-icons/fi";

const DateFilterOptions = [
  { value: "Today", label: "Today" },
  { value: "Yesterday", label: "Yesterday" },
  { value: "Last 7 days", label: "Last 7 days" },
  { value: "Last 30 days", label: "Last 30 days" },
  { value: "This Month", label: "This Month" },
  { value: "Last Month", label: "Last Month" },
  { value: "Custom Range", label: "Custom Range" },
];

const AllSales = () => {
  const { t, i18n } = useTranslation();
  const deliveryFilterOptions = [
    { value: "Pending", label: t("pending") },
    { value: "Confirmed", label: t("confirmed") },
    { value: "On delivery", label: t("on") + ` ` + t("delivery") },
    { value: "Delivered", label: t("delivered") },
    { value: "Canceled", label: t("canceled") },
  ];
  const [current, setCurrent] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [deliveryStatus, setSetDeliveryStatus] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [saleId, setsaleId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [saleList, setsaleList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [reload,setReload] = useState(false)
  const toggle = (index) => {
    if (index === current) setCurrent(null);
    else setCurrent(index);
  };

  useEffect(() => {
    const parameterizedUrl = addUrlParams("/pos/sales", {
      date_from: startDate ? formatDate(startDate) : null,
      date_to: startDate ? formatDate(endDate) : null,
      sale_id: saleId,
      created_by: userId,
      per_page: perPage,
      page: currentPage,
    });
    setLoading(true);
    markutosAdminApi()
      .get(parameterizedUrl)
      .then((response) => {
        setCurrentItems(response?.data?.sales?.data);
        setCurrentPage(response?.data?.sales?.current_page);
        setPerPage(response?.data?.sales?.per_page);
        setPageCount(response?.data?.sales?.last_page);
        setsaleList(response?.data?.sale_filters);
        setUserList(response?.data?.created_by_filters);
		setReload(false)
        setLoading(false);
      });
  }, [perPage, currentPage, search, deliveryStatus, startDate, endDate,reload]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);

  const removeItem = (id) => {
    Swal.fire({
      title: t("are_sure"),
      text: t("delete_revert"),
      confirmButtonText: t("yes_delete_it"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        markutosAdminApi()
          .get(`/pos/sales/delete?sale_id=${id}`)
          .then((response) => {
            setLoading(true);
            toast(response.data.message);
			setReload(true)
          });
      }
    });
  };

  return (
    <div className="responsive-table-container">
      <div className="single-widget table">
        <div className="table-top-header">
          <div className="table-title">{t("sale") + ` ` + t("list")}</div>
          <div className="table-filters">
            <Select
              options={saleList}
              placeholder={t("sale")}
              onChange={(e) => setsaleId(e.id)}
              getOptionLabel={(option) => option.full_name}
              getOptionValue={(option) => option.id}
            />
            <Select
              options={userList}
              placeholder={t("created_by")}
              onChange={(e) => setUserId(e.id)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
            <div className="text-end pe-4 ">
              <DateRangeSelector
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </div>
          </div>
        </div>
        <div className="widget-container">
          <div
            className="table-header"
            style={{
				gridTemplateColumns:
				"50px  130px 80px 100px 100px 80px 80px 80px 80px 80px 80px",
			}}
          >
            <div>#</div>
            <div>{t("sales_id")}</div>
            <div>{t("po_no")}</div>
            <div>{t("created_by")}</div>
            <div>{t("sale") + ` ` + t("date")}</div>
            <div>{t("total") + ` ` + t("payment")}</div>
            <div>{t("paid") + ` ` + t("payment")}</div>
            <div>{t("due")}</div>
            <div>{t("payment") + ` ` + t("status")}</div>
            <div className="text-center">{t("actions")}</div>
          </div>
          <div>
            {loading ? (
              <SimpleLoading />
            ) : (
              currentItems?.length > 0 &&
              currentItems?.map((sale, index) => (
                <div key={index}>
                  <div
                    className="table-row"
                    style={{
                      gridTemplateColumns:
                        "50px  130px 80px 100px 100px 80px 80px 80px 80px 80px 80px",
                    }}
                  >
                    <div>{sale.id}</div>
                    <div>{sale?.sale_id}</div>
                    <div>{sale?.po_no || "N/A"}</div>
                    <div>{sale.created_by?.name}</div>
                    <div>{sale?.sale_date}</div>
                    <div>{sale?.grand_total}</div>
                    <div>{sale?.paid}</div>
                    <div>{sale?.due}</div>
                    <div >{sale?.payment_status === "paid" ? "Paid" : "Unpaid"}</div>
                    <div className="action-column">
                      {/* <button
                        title="Edit"
                        className="table-btn tb-edit"
                        onClick={() => navigate(`/sales/${sale.id}/edit`)}
                      >
                        <FiEdit />
                      </button> */}
                      <button
                        className="table-btn tb-delete"
                        onClick={() => removeItem(sale.id)}
                      >
                        <RiUserUnfollowLine />
                      </button>
                    </div>
                  </div>
                  {current === index && (
                    <div className="row-extra">
                      <div className="row-extra-row">
                        <div>{t("email")}</div>
                        <div>{sale.emails?.[0]}</div>
                      </div>
                      <div className="row-extra-row">
                        <div>{t("package")}</div>
                        <div>{sale.package}</div>
                      </div>
                      <div className="row-extra-row">
                        <div>{t("wallet_balance")}</div>
                        <div>{sale.wallet_balance}</div>
                      </div>
                    </div>
                  )}
                </div>
              ))
            )}
            {currentItems?.length > 0 && (
              <PaginationCom
                currentItem={currentItems}
                perPage={perPage}
                pageCount={pageCount}
                currentPage={currentPage}
                setPerPage={setPerPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </div>
          
        </div>
      </div>
    </div>
  );
};
export default AllSales;

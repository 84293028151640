export const products = [
  {
    name: "Xiaomi SmartMi",
    img: "https://i.ibb.co/W3VjdpM/oil.jpg",
    price: 350,
    stock: 10,
  },
  {
    name: "Xiaomi Mi DSXB01RM 4L",
    img: "https://i.ibb.co/12tB3nR/detol.jpg",
    price: 250,
    stock: 10,
  },
  {
    name: "Fresh Soyabean Oil",
    img: "https://i.ibb.co/W36zcS2/bag.jpg",
    price: 380,
    stock: 10,
  },
  {
    name: "Goalini Full Cream Milk",
    img: "https://i.ibb.co/LC0ccYV/mask.jpg",
    price: 300,
    stock: 10,
  },
  {
    name: "Dettol Shower Gel",
    img: "https://i.ibb.co/CBJGtLH/milk.jpg",
    price: 470,
    stock: 10,
  },
];
export const customers = [
  {
    id: 1,
    name: "Shelli Kave",
    phone: "401-594-6232",
    email: "skave0@japanpost.jp",
    package: null,
    wallet_balance: 164,
  },
  {
    id: 2,
    name: "Gallagher Ludlow",
    phone: "782-799-4008",
    email: "gludlow1@guardian.co.uk",
    package: null,
    wallet_balance: 811,
  },
  {
    id: 3,
    name: "Agnesse Clemes",
    phone: "852-723-8147",
    email: "aclemes2@unicef.org",
    package: null,
    wallet_balance: 772,
  },
  {
    id: 4,
    name: "Carson Gonzales",
    phone: "707-586-1298",
    email: "cgonzales3@samsung.com",
    package: null,
    wallet_balance: 527,
  },
  {
    id: 5,
    name: "Jemmy Erickson",
    phone: "781-318-8500",
    email: "jerickson4@alexa.com",
    package: null,
    wallet_balance: 143,
  },
  {
    id: 6,
    name: "Norbert McGirr",
    phone: "318-803-3858",
    email: "nmcgirr5@census.gov",
    package: null,
    wallet_balance: 91,
  },
  {
    id: 7,
    name: "Barclay Town",
    phone: "335-454-5006",
    email: "btown6@sohu.com",
    package: null,
    wallet_balance: 804,
  },
  {
    id: 8,
    name: "Cord Dilke",
    phone: "412-725-7938",
    email: "cdilke7@adobe.com",
    package: null,
    wallet_balance: 975,
  },
  {
    id: 9,
    name: "Dell Behnecke",
    phone: "274-262-6403",
    email: "dbehnecke8@usda.gov",
    package: null,
    wallet_balance: 537,
  },
  {
    id: 10,
    name: "Ricardo Delia",
    phone: "964-788-2867",
    email: "rdelia9@who.int",
    package: null,
    wallet_balance: 769,
  },
  {
    id: 11,
    name: "Henri Seifenmacher",
    phone: "679-820-5693",
    email: "hseifenmachera@admin.ch",
    package: null,
    wallet_balance: 110,
  },
  {
    id: 12,
    name: "Keenan Alben",
    phone: "137-376-4408",
    email: "kalbenb@nsw.gov.au",
    package: null,
    wallet_balance: 947,
  },
  {
    id: 13,
    name: "Benji Scone",
    phone: "474-720-2521",
    email: "bsconec@artisteer.com",
    package: null,
    wallet_balance: 918,
  },
  {
    id: 14,
    name: "Genny MacDougal",
    phone: "982-200-0117",
    email: "gmacdougald@seattletimes.com",
    package: null,
    wallet_balance: 454,
  },
  {
    id: 15,
    name: "Lawrence Blinde",
    phone: "514-780-7737",
    email: "lblindee@pcworld.com",
    package: null,
    wallet_balance: 725,
  },
  {
    id: 16,
    name: "Hayyim Harrisson",
    phone: "978-861-8515",
    email: "hharrissonf@netvibes.com",
    package: null,
    wallet_balance: 248,
  },
  {
    id: 17,
    name: "Sonnie Crosfield",
    phone: "481-587-1076",
    email: "scrosfieldg@msn.com",
    package: null,
    wallet_balance: 661,
  },
  {
    id: 18,
    name: "Britta Whittle",
    phone: "411-140-7835",
    email: "bwhittleh@edublogs.org",
    package: null,
    wallet_balance: 844,
  },
  {
    id: 19,
    name: "Ingeberg Hovington",
    phone: "256-876-8071",
    email: "ihovingtoni@microsoft.com",
    package: null,
    wallet_balance: 904,
  },
  {
    id: 20,
    name: "Yalonda MacFarlane",
    phone: "497-702-1025",
    email: "ymacfarlanej@google.com.hk",
    package: null,
    wallet_balance: 616,
  },
  {
    id: 21,
    name: "Cullen Ashfold",
    phone: "137-667-5634",
    email: "cashfoldk@cnbc.com",
    package: null,
    wallet_balance: 17,
  },
  {
    id: 22,
    name: "Arnie Pedlow",
    phone: "297-879-0308",
    email: "apedlowl@fda.gov",
    package: null,
    wallet_balance: 11,
  },
  {
    id: 23,
    name: "Raphaela Arkill",
    phone: "803-830-6852",
    email: "rarkillm@over-blog.com",
    package: null,
    wallet_balance: 909,
  },
  {
    id: 24,
    name: "Giffer Mackerel",
    phone: "948-430-5575",
    email: "gmackereln@storify.com",
    package: null,
    wallet_balance: 72,
  },
  {
    id: 25,
    name: "Steve Petrovykh",
    phone: "433-225-1558",
    email: "spetrovykho@php.net",
    package: null,
    wallet_balance: 479,
  },
  {
    id: 26,
    name: "Valentin Dudlestone",
    phone: "370-821-0538",
    email: "vdudlestonep@photobucket.com",
    package: null,
    wallet_balance: 80,
  },
  {
    id: 27,
    name: "Redd Attryde",
    phone: "390-487-3936",
    email: "rattrydeq@w3.org",
    package: null,
    wallet_balance: 465,
  },
  {
    id: 28,
    name: "Charmain Manson",
    phone: "734-735-3271",
    email: "cmansonr@about.me",
    package: null,
    wallet_balance: 369,
  },
  {
    id: 29,
    name: "Brigit Jouannot",
    phone: "939-839-4458",
    email: "bjouannots@tamu.edu",
    package: null,
    wallet_balance: 215,
  },
  {
    id: 30,
    name: "Joe Pude",
    phone: "336-226-9204",
    email: "jpudet@wunderground.com",
    package: null,
    wallet_balance: 28,
  },
];
export const uploadedFiles = [
  {
    img: "https://i.ibb.co/54pG5yX/b9-FZ9-Bg3c8hjda3prd-Rf-Lv-Bxg-Qlh-So-WGc1s7-Yt82.png",
    name: "product1.png",
    size: "3kb",
  },
  {
    img: "https://i.ibb.co/r0PGwLp/Bja-Okpusnsh0h-Fhfsz-Ax-Pwswl-Ksb7-Un-QOps-X6-JV5.png",
    name: "product2.png",
    size: "7kb",
  },
  {
    img: "https://i.ibb.co/61DH7FP/BNMH61mikpe0q5l5b-QCw-PFour4-KL91-Zthm-IXtd8t.png",
    name: "product3.png",
    size: "19kb",
  },
  {
    img: "https://i.ibb.co/9h4W1pf/CAb2-KLPKmv-J0-X1z-Xi0d-P2l-IVFdglpy199-Yb-A89-Oa.png",
    name: "product4.png",
    size: "5kb",
  },
  {
    img: "https://i.ibb.co/ftf7rM1/dq-YEQK55-VZShibd9-ZTFo2dwh9fp6-Iu848b-Ol-Iu-Ae.png",
    name: "product5.png",
    size: "3kb",
  },
  {
    img: "https://i.ibb.co/PrxNC1L/Pb-O1ctng03a76-O2-Hw-NZe-Xq-OWCRDA7d-VADcxm-L0-We.png",
    name: "product6.png",
    size: "29kb",
  },
  {
    img: "https://i.ibb.co/7p1thFz/R2-NLXc-CSAFv-Vp-Ua-Th3ve4-O4whv8-TU4o3-Kotr-H1g-L.png",
    name: "product7.png",
    size: "4kb",
  },
  {
    img: "https://i.ibb.co/jg8M5JL/TUl-GRHTz-Dt2-VCOYf-Ykov-Zixif1-Ir-Vr-Lbso-SILIMM.png",
    name: "product8.png",
    size: "29kb",
  },
  {
    img: "https://i.ibb.co/Mkw1W6J/yd1l-Kx56-WYc6ji-HRKxq-VG6w-Rcg1-Br-Mpkmnz-Dw-OQN.png",
    name: "product9.png",
    size: "219kb",
  },
  {
    img: "https://i.ibb.co/BKGp6hG/y-Fs-Ko-Cyofrd-X5028g-Ex-MWx-VLv00u-Nzd-Bz-Hw1x-FZe.png",
    name: "product10.png",
    size: "209kb",
  },
];

export const allProducts = [
  {
    id: 1,
    name: "Veal - Sweetbread",
    img: "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
    added_by: "Valaria Eley",
    sale_count: 315,
    base_price: 684,
    rating: 6,
    stock: 337,
    todays_deal: false,
    published: true,
    featured: false,
  },
  {
    id: 2,
    name: "Wine - German Riesling",
    img: "http://dummyimage.com/100x100.png/dddddd/000000",
    added_by: "Redd Brockie",
    sale_count: 921,
    base_price: 427,
    rating: 1,
    stock: 389,
    todays_deal: true,
    published: false,
    featured: true,
  },
  {
    id: 3,
    name: "Flour - Whole Wheat",
    img: "http://dummyimage.com/100x100.png/cc0000/ffffff",
    added_by: "Morse Fragino",
    sale_count: 158,
    base_price: 597,
    rating: 6,
    stock: 488,
    todays_deal: true,
    published: false,
    featured: false,
  },
  {
    id: 4,
    name: "Syrup - Chocolate",
    img: "http://dummyimage.com/100x100.png/dddddd/000000",
    added_by: "Jareb Rix",
    sale_count: 282,
    base_price: 607,
    rating: 7,
    stock: 260,
    todays_deal: false,
    published: false,
    featured: false,
  },
  {
    id: 5,
    name: "Oil - Cooking Spray",
    img: "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
    added_by: "Veda Delete",
    sale_count: 882,
    base_price: 389,
    rating: 6,
    stock: 124,
    todays_deal: true,
    published: true,
    featured: true,
  },
  {
    id: 6,
    name: "Salt - Seasoned",
    img: "http://dummyimage.com/100x100.png/dddddd/000000",
    added_by: "Marinna Pinnocke",
    sale_count: 320,
    base_price: 116,
    rating: 8,
    stock: 303,
    todays_deal: true,
    published: false,
    featured: false,
  },
  {
    id: 7,
    name: "Coke - Classic, 355 Ml",
    img: "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
    added_by: "Haskel Godber",
    sale_count: 885,
    base_price: 271,
    rating: 3,
    stock: 243,
    todays_deal: false,
    published: false,
    featured: true,
  },
  {
    id: 8,
    name: "Soup - Campbells, Beef Barley",
    img: "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
    added_by: "Crystie Doche",
    sale_count: 983,
    base_price: 654,
    rating: 3,
    stock: 145,
    todays_deal: false,
    published: false,
    featured: true,
  },
  {
    id: 9,
    name: "Brandy - Orange, Mc Guiness",
    img: "http://dummyimage.com/100x100.png/dddddd/000000",
    added_by: "Renard Beadles",
    sale_count: 851,
    base_price: 418,
    rating: 0,
    stock: 141,
    todays_deal: true,
    published: true,
    featured: false,
  },
  {
    id: 10,
    name: "Muffin Mix - Carrot",
    img: "http://dummyimage.com/100x100.png/ff4444/ffffff",
    added_by: "Melva Sudy",
    sale_count: 258,
    base_price: 258,
    rating: 2,
    stock: 11,
    todays_deal: false,
    published: false,
    featured: false,
  },
];

export const attributes = [
  {
    id: 1,
    name: "Bag",
  },
  {
    id: 2,
    name: "Size",
  },
  {
    id: 3,
    name: "Fabric",
  },
];

export const categoriesStructure = [
  {
    id: "sdf3fed3",
    name: "category 1",
    parent: "",
    order: "1",
    traceId: "1",
  },
  {
    id: "sd23fed3",
    name: "category 2",
    parent: "",
    order: "2",
    traceId: "2",
  },
  {
    id: "sd23f31d3",
    name: "category 1 child[2]",
    parent: "1", //parent traceId
    order: "2",
    traceId: "3",
  },
  {
    id: "sd23f234d3",
    name: "category 1 child's child",
    parent: "3", //parent traceId
    order: "1",
    traceId: "4",
  },
  {
    id: "sd23f2333",
    name: "category 1 child[1]",
    parent: "1", //parent traceId
    order: "1",
    traceId: "5",
  },
];

export const categories = [
  {
    id: 1,
    name: "Skipstorm",
    parent: "Buzzbean",
    order_level: 5,
    level: 1,
    banner: "http://dummyimage.com/200x200.png/cc0000/ffffff",
    icon: "http://dummyimage.com/100x100.png/dddddd/000000",
    featured: true,
    commission: 0,
  },
  {
    id: 2,
    name: "Vitz",
    parent: "Linkbuzz",
    order_level: 4,
    level: 3,
    banner: "http://dummyimage.com/200x200.png/5fa2dd/ffffff",
    icon: "http://dummyimage.com/100x100.png/dddddd/000000",
    featured: true,
    commission: 0,
  },
  {
    id: 3,
    name: "Voomm",
    parent: "Kimia",
    order_level: 4,
    level: 3,
    banner: "http://dummyimage.com/200x200.png/5fa2dd/ffffff",
    icon: "http://dummyimage.com/100x100.png/ff4444/ffffff",
    featured: false,
    commission: 0,
  },
  {
    id: 4,
    name: "Browsezoom",
    parent: "Innojam",
    order_level: 2,
    level: 1,
    banner: "http://dummyimage.com/200x200.png/dddddd/000000",
    icon: "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
    featured: true,
    commission: 0,
  },
  {
    id: 5,
    name: "Zoomcast",
    parent: "Bubbletube",
    order_level: 1,
    level: 2,
    banner: "http://dummyimage.com/200x200.png/5fa2dd/ffffff",
    icon: "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
    featured: true,
    commission: 0,
  },
  {
    id: 6,
    name: "Edgetag",
    parent: "Skidoo",
    order_level: 4,
    level: 1,
    banner: "http://dummyimage.com/200x200.png/cc0000/ffffff",
    icon: "http://dummyimage.com/100x100.png/ff4444/ffffff",
    featured: true,
    commission: 0,
  },
  {
    id: 7,
    name: "Mudo",
    parent: "Voolia",
    order_level: 2,
    level: 3,
    banner: "http://dummyimage.com/200x200.png/5fa2dd/ffffff",
    icon: "http://dummyimage.com/100x100.png/dddddd/000000",
    featured: false,
    commission: 0,
  },
  {
    id: 8,
    name: "Flipopia",
    parent: "Demivee",
    order_level: 3,
    level: 3,
    banner: "http://dummyimage.com/200x200.png/dddddd/000000",
    icon: "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
    featured: false,
    commission: 0,
  },
  {
    id: 9,
    name: "Feedspan",
    parent: "Thoughtbridge",
    order_level: 4,
    level: 1,
    banner: "http://dummyimage.com/200x200.png/cc0000/ffffff",
    icon: "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
    featured: true,
    commission: 0,
  },
  {
    id: 10,
    name: "Yodo",
    parent: "Flashspan",
    order_level: 3,
    level: 3,
    banner: "http://dummyimage.com/200x200.png/cc0000/ffffff",
    icon: "http://dummyimage.com/100x100.png/cc0000/ffffff",
    featured: true,
    commission: 0,
  },
  {
    id: 11,
    name: "Trupe",
    parent: "Youspan",
    order_level: 4,
    level: 1,
    banner: "http://dummyimage.com/200x200.png/dddddd/000000",
    icon: "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
    featured: true,
    commission: 0,
  },
  {
    id: 12,
    name: "InnoZ",
    parent: "Yotz",
    order_level: 3,
    level: 2,
    banner: "http://dummyimage.com/200x200.png/ff4444/ffffff",
    icon: "http://dummyimage.com/100x100.png/dddddd/000000",
    featured: true,
    commission: 0,
  },
  {
    id: 13,
    name: "Realblab",
    parent: "Dynava",
    order_level: 2,
    level: 3,
    banner: "http://dummyimage.com/200x200.png/cc0000/ffffff",
    icon: "http://dummyimage.com/100x100.png/cc0000/ffffff",
    featured: false,
    commission: 0,
  },
  {
    id: 14,
    name: "Tazz",
    parent: "Yotz",
    order_level: 1,
    level: 2,
    banner: "http://dummyimage.com/200x200.png/cc0000/ffffff",
    icon: "http://dummyimage.com/100x100.png/dddddd/000000",
    featured: false,
    commission: 0,
  },
  {
    id: 15,
    name: "Gabcube",
    parent: "Minyx",
    order_level: 3,
    level: 3,
    banner: "http://dummyimage.com/200x200.png/ff4444/ffffff",
    icon: "http://dummyimage.com/100x100.png/dddddd/000000",
    featured: false,
    commission: 0,
  },
  {
    id: 16,
    name: "Janyx",
    parent: "Twitterbridge",
    order_level: 4,
    level: 2,
    banner: "http://dummyimage.com/200x200.png/cc0000/ffffff",
    icon: "http://dummyimage.com/100x100.png/dddddd/000000",
    featured: false,
    commission: 0,
  },
  {
    id: 17,
    name: "Skynoodle",
    parent: "Linkbridge",
    order_level: 3,
    level: 2,
    banner: "http://dummyimage.com/200x200.png/dddddd/000000",
    icon: "http://dummyimage.com/100x100.png/dddddd/000000",
    featured: false,
    commission: 0,
  },
  {
    id: 18,
    name: "Einti",
    parent: "Yotz",
    order_level: 3,
    level: 2,
    banner: "http://dummyimage.com/200x200.png/ff4444/ffffff",
    icon: "http://dummyimage.com/100x100.png/cc0000/ffffff",
    featured: false,
    commission: 0,
  },
  {
    id: 19,
    name: "JumpXS",
    parent: "Tekfly",
    order_level: 1,
    level: 3,
    banner: "http://dummyimage.com/200x200.png/5fa2dd/ffffff",
    icon: "http://dummyimage.com/100x100.png/ff4444/ffffff",
    featured: false,
    commission: 0,
  },
  {
    id: 20,
    name: "Zoonder",
    parent: "Jaxbean",
    order_level: 2,
    level: 1,
    banner: "http://dummyimage.com/200x200.png/5fa2dd/ffffff",
    icon: "http://dummyimage.com/100x100.png/dddddd/000000",
    featured: false,
    commission: 0,
  },
];

export const orders = [
  {
    id: 1,
    order_id: "42291-711",
    quantity: 2,
    customer_name: "Terrance Croy",
    amount: 110,
    delivery_status: 1,
    payment_method: 2,
    payment_status: false,
    refund: 2,
  },
  {
    id: 2,
    order_id: "60505-0688",
    quantity: 6,
    customer_name: "Janey Annetts",
    amount: 61,
    delivery_status: 1,
    payment_method: 3,
    payment_status: false,
    refund: 1,
  },
  {
    id: 3,
    order_id: "36987-2776",
    quantity: 2,
    customer_name: "Enid Van Der Weedenburg",
    amount: 52,
    delivery_status: 4,
    payment_method: 2,
    payment_status: false,
    refund: 2,
  },
  {
    id: 4,
    order_id: "52686-238",
    quantity: 7,
    customer_name: "Sheelagh Gaize",
    amount: 27,
    delivery_status: 3,
    payment_method: 3,
    payment_status: false,
    refund: 0,
  },
  {
    id: 5,
    order_id: "42627-265",
    quantity: 6,
    customer_name: "Hannie Montes",
    amount: 75,
    delivery_status: 4,
    payment_method: 3,
    payment_status: true,
    refund: 2,
  },
  {
    id: 6,
    order_id: "41167-0773",
    quantity: 4,
    customer_name: "Shelton Lightewood",
    amount: 50,
    delivery_status: 1,
    payment_method: 1,
    payment_status: false,
    refund: 2,
  },
  {
    id: 7,
    order_id: "47682-509",
    quantity: 4,
    customer_name: "Rollo Carnelley",
    amount: 26,
    delivery_status: 1,
    payment_method: 2,
    payment_status: false,
    refund: 2,
  },
  {
    id: 8,
    order_id: "59779-409",
    quantity: 3,
    customer_name: "Katerina Littlejohn",
    amount: 57,
    delivery_status: 1,
    payment_method: 1,
    payment_status: true,
    refund: 0,
  },
  {
    id: 9,
    order_id: "46123-039",
    quantity: 2,
    customer_name: "Carleton Mateev",
    amount: 89,
    delivery_status: 3,
    payment_method: 3,
    payment_status: true,
    refund: 2,
  },
  {
    id: 10,
    order_id: "0904-5098",
    quantity: 3,
    customer_name: "Currey Dhenin",
    amount: 95,
    delivery_status: 4,
    payment_method: 1,
    payment_status: false,
    refund: 0,
  },
  {
    id: 11,
    order_id: "59746-306",
    quantity: 7,
    customer_name: "Diann Fessler",
    amount: 65,
    delivery_status: 4,
    payment_method: 3,
    payment_status: true,
    refund: 0,
  },
  {
    id: 12,
    order_id: "36987-2270",
    quantity: 6,
    customer_name: "Andeee Hukin",
    amount: 74,
    delivery_status: 5,
    payment_method: 2,
    payment_status: false,
    refund: 0,
  },
  {
    id: 13,
    order_id: "37808-402",
    quantity: 6,
    customer_name: "Brew Keiley",
    amount: 37,
    delivery_status: 5,
    payment_method: 2,
    payment_status: true,
    refund: 2,
  },
  {
    id: 14,
    order_id: "55513-075",
    quantity: 7,
    customer_name: "Grantley Pinder",
    amount: 103,
    delivery_status: 4,
    payment_method: 1,
    payment_status: false,
    refund: 1,
  },
  {
    id: 15,
    order_id: "0378-0130",
    quantity: 2,
    customer_name: "Reade Dods",
    amount: 74,
    delivery_status: 3,
    payment_method: 2,
    payment_status: false,
    refund: 1,
  },
  {
    id: 16,
    order_id: "51770-110",
    quantity: 3,
    customer_name: "Jo McEniry",
    amount: 75,
    delivery_status: 4,
    payment_method: 1,
    payment_status: false,
    refund: 0,
  },
  {
    id: 17,
    order_id: "10191-1938",
    quantity: 3,
    customer_name: "Lani Longfield",
    amount: 49,
    delivery_status: 4,
    payment_method: 3,
    payment_status: true,
    refund: 1,
  },
  {
    id: 18,
    order_id: "67544-326",
    quantity: 4,
    customer_name: "Lawrence Emmerson",
    amount: 60,
    delivery_status: 4,
    payment_method: 2,
    payment_status: true,
    refund: 1,
  },
  {
    id: 19,
    order_id: "10742-8236",
    quantity: 2,
    customer_name: "Catherine Iacivelli",
    amount: 63,
    delivery_status: 2,
    payment_method: 1,
    payment_status: false,
    refund: 1,
  },
  {
    id: 20,
    order_id: "0280-1012",
    quantity: 2,
    customer_name: "Rhea Roset",
    amount: 53,
    delivery_status: 3,
    payment_method: 3,
    payment_status: false,
    refund: 0,
  },
];

export const refunds = [
  {
    id: 1,
    refund_id: "55154-8277",
    seller_name: "Lyell Ream",
    product_name: "Bractless Blazingstar",
    product_price: 38,
    product_img: "http://dummyimage.com/200x200.png/ff4444/ffffff",
    seller_approval: true,
    admin_approval: true,
    refund_status: true,
  },
  {
    id: 2,
    refund_id: "58878-0001",
    seller_name: "Ailee Jeaneau",
    product_name: "Cream Sacs",
    product_price: 96,
    product_img: "http://dummyimage.com/200x200.png/ff4444/ffffff",
    seller_approval: true,
    admin_approval: false,
    refund_status: true,
  },
  {
    id: 3,
    refund_id: "53217-070",
    seller_name: "Baryram Bruckman",
    product_name: "Whitethorn Acacia",
    product_price: 92,
    product_img: "http://dummyimage.com/200x200.png/cc0000/ffffff",
    seller_approval: true,
    admin_approval: true,
    refund_status: false,
  },
  {
    id: 4,
    refund_id: "35418-136",
    seller_name: "Carola Tomankiewicz",
    product_name: "Grimmia Dry Rock Moss",
    product_price: 50,
    product_img: "http://dummyimage.com/200x200.png/cc0000/ffffff",
    seller_approval: true,
    admin_approval: false,
    refund_status: true,
  },
  {
    id: 5,
    refund_id: "47335-153",
    seller_name: "Barnett Riceards",
    product_name: "Winecolor Iris",
    product_price: 23,
    product_img: "http://dummyimage.com/200x200.png/ff4444/ffffff",
    seller_approval: false,
    admin_approval: true,
    refund_status: true,
  },
  {
    id: 6,
    refund_id: "17478-934",
    seller_name: "Sherwin Camous",
    product_name: "Chaff Flower",
    product_price: 27,
    product_img: "http://dummyimage.com/200x200.png/cc0000/ffffff",
    seller_approval: false,
    admin_approval: false,
    refund_status: true,
  },
  {
    id: 7,
    refund_id: "55670-675",
    seller_name: "Salomo Mammatt",
    product_name: "Beard Lichen",
    product_price: 36,
    product_img: "http://dummyimage.com/200x200.png/cc0000/ffffff",
    seller_approval: false,
    admin_approval: false,
    refund_status: true,
  },
  {
    id: 8,
    refund_id: "98132-883",
    seller_name: "Griselda Goldsbrough",
    product_name: "Denseflower Witchweed",
    product_price: 99,
    product_img: "http://dummyimage.com/200x200.png/dddddd/000000",
    seller_approval: true,
    admin_approval: true,
    refund_status: false,
  },
  {
    id: 9,
    refund_id: "0591-3468",
    seller_name: "Padgett Wethey",
    product_name: "Tree Marigold",
    product_price: 25,
    product_img: "http://dummyimage.com/200x200.png/dddddd/000000",
    seller_approval: false,
    admin_approval: true,
    refund_status: false,
  },
  {
    id: 10,
    refund_id: "63629-2733",
    seller_name: "Walton Strettell",
    product_name: "Rim Lichen",
    product_price: 22,
    product_img: "http://dummyimage.com/200x200.png/ff4444/ffffff",
    seller_approval: true,
    admin_approval: false,
    refund_status: false,
  },
  {
    id: 11,
    refund_id: "76439-259",
    seller_name: "Cassandry Doumerque",
    product_name: "Scareweed",
    product_price: 57,
    product_img: "http://dummyimage.com/200x200.png/cc0000/ffffff",
    seller_approval: false,
    admin_approval: false,
    refund_status: true,
  },
  {
    id: 12,
    refund_id: "55863-431",
    seller_name: "Kora de Mendoza",
    product_name: "Dot Lichen",
    product_price: 55,
    product_img: "http://dummyimage.com/200x200.png/ff4444/ffffff",
    seller_approval: true,
    admin_approval: true,
    refund_status: false,
  },
  {
    id: 13,
    refund_id: "57664-642",
    seller_name: "Waite Standbrooke",
    product_name: "Rubber Rabbitbrush",
    product_price: 93,
    product_img: "http://dummyimage.com/200x200.png/ff4444/ffffff",
    seller_approval: true,
    admin_approval: false,
    refund_status: false,
  },
  {
    id: 14,
    refund_id: "0781-7094",
    seller_name: "Jocelyn Raithby",
    product_name: "Parish's Snowberry",
    product_price: 40,
    product_img: "http://dummyimage.com/200x200.png/dddddd/000000",
    seller_approval: false,
    admin_approval: true,
    refund_status: true,
  },
  {
    id: 15,
    refund_id: "50484-212",
    seller_name: "Teddi Wilshire",
    product_name: "Fairyduster",
    product_price: 66,
    product_img: "http://dummyimage.com/200x200.png/cc0000/ffffff",
    seller_approval: false,
    admin_approval: true,
    refund_status: true,
  },
  {
    id: 16,
    refund_id: "57520-0931",
    seller_name: "Jasmina Le Marquis",
    product_name: "Smooth Yellow Vetch",
    product_price: 61,
    product_img: "http://dummyimage.com/200x200.png/5fa2dd/ffffff",
    seller_approval: false,
    admin_approval: true,
    refund_status: true,
  },
  {
    id: 17,
    refund_id: "64950-354",
    seller_name: "Felicity Seden",
    product_name: "Spiny Kidneywood",
    product_price: 91,
    product_img: "http://dummyimage.com/200x200.png/dddddd/000000",
    seller_approval: true,
    admin_approval: false,
    refund_status: false,
  },
  {
    id: 18,
    refund_id: "55154-5697",
    seller_name: "Hernando Ivatt",
    product_name: "Carveseed",
    product_price: 22,
    product_img: "http://dummyimage.com/200x200.png/cc0000/ffffff",
    seller_approval: false,
    admin_approval: false,
    refund_status: true,
  },
  {
    id: 19,
    refund_id: "68828-067",
    seller_name: "Jedd MacIllrick",
    product_name: "Purple Amaranth",
    product_price: 30,
    product_img: "http://dummyimage.com/200x200.png/ff4444/ffffff",
    seller_approval: false,
    admin_approval: true,
    refund_status: true,
  },
  {
    id: 20,
    refund_id: "51198-001",
    seller_name: "Rudy Girvan",
    product_name: "Jelly Lichen",
    product_price: 55,
    product_img: "http://dummyimage.com/200x200.png/5fa2dd/ffffff",
    seller_approval: false,
    admin_approval: false,
    refund_status: false,
  },
];

export const sellers = [
  {
    id: 1,
    name: "Lucais Tong",
    phone: "317-676-8721",
    email: "ltong0@princeton.edu",
    verified: true,
    total_products: 8,
    due_to_seller: 28,
  },
  {
    id: 2,
    name: "Kathy Somerbell",
    phone: "245-305-7476",
    email: "ksomerbell1@sohu.com",
    verified: true,
    total_products: 7,
    due_to_seller: 169,
  },
  {
    id: 3,
    name: "Fraser Murfin",
    phone: "202-418-9836",
    email: "fmurfin2@rediff.com",
    verified: false,
    total_products: 14,
    due_to_seller: 48,
  },
  {
    id: 4,
    name: "Michel Bebis",
    phone: "483-602-2550",
    email: "mbebis3@nydailynews.com",
    verified: false,
    total_products: 20,
    due_to_seller: 104,
  },
  {
    id: 5,
    name: "Currey Capstack",
    phone: "877-579-6883",
    email: "ccapstack4@amazon.co.uk",
    verified: true,
    total_products: 20,
    due_to_seller: 26,
  },
  {
    id: 6,
    name: "Nessy Breinl",
    phone: "910-281-8625",
    email: "nbreinl5@va.gov",
    verified: false,
    total_products: 3,
    due_to_seller: 127,
  },
  {
    id: 7,
    name: "Jonie Beese",
    phone: "864-448-2489",
    email: "jbeese6@fastcompany.com",
    verified: false,
    total_products: 6,
    due_to_seller: 61,
  },
  {
    id: 8,
    name: "Gal Tootal",
    phone: "203-408-1446",
    email: "gtootal7@yellowpages.com",
    verified: false,
    total_products: 19,
    due_to_seller: 159,
  },
  {
    id: 9,
    name: "Phebe Briddock",
    phone: "336-596-0025",
    email: "pbriddock8@sakura.ne.jp",
    verified: true,
    total_products: 18,
    due_to_seller: 30,
  },
  {
    id: 10,
    name: "Rick Mindenhall",
    phone: "362-571-6850",
    email: "rmindenhall9@amazon.de",
    verified: false,
    total_products: 17,
    due_to_seller: 107,
  },
  {
    id: 11,
    name: "Carin Plews",
    phone: "565-608-1588",
    email: "cplewsa@deviantart.com",
    verified: true,
    total_products: 18,
    due_to_seller: 143,
  },
  {
    id: 12,
    name: "Olimpia Commin",
    phone: "465-935-9029",
    email: "ocomminb@opera.com",
    verified: false,
    total_products: 19,
    due_to_seller: 153,
  },
  {
    id: 13,
    name: "Magda Ballingal",
    phone: "275-184-6738",
    email: "mballingalc@foxnews.com",
    verified: true,
    total_products: 12,
    due_to_seller: 107,
  },
  {
    id: 14,
    name: "Godart Dumphry",
    phone: "125-372-2196",
    email: "gdumphryd@ed.gov",
    verified: true,
    total_products: 9,
    due_to_seller: 10,
  },
  {
    id: 15,
    name: "Bryant Jocic",
    phone: "205-701-9534",
    email: "bjocice@yandex.ru",
    verified: true,
    total_products: 8,
    due_to_seller: 140,
  },
  {
    id: 16,
    name: "Drake Elloway",
    phone: "537-189-3647",
    email: "dellowayf@globo.com",
    verified: false,
    total_products: 8,
    due_to_seller: 110,
  },
  {
    id: 17,
    name: "Anjela Sporle",
    phone: "455-250-4035",
    email: "asporleg@cafepress.com",
    verified: false,
    total_products: 2,
    due_to_seller: 60,
  },
  {
    id: 18,
    name: "Sarah Goundry",
    phone: "302-721-3545",
    email: "sgoundryh@163.com",
    verified: true,
    total_products: 5,
    due_to_seller: 45,
  },
  {
    id: 19,
    name: "Currie McCarry",
    phone: "229-367-7505",
    email: "cmccarryi@furl.net",
    verified: false,
    total_products: 1,
    due_to_seller: 189,
  },
  {
    id: 20,
    name: "Sigismund Sacco",
    phone: "915-132-0580",
    email: "ssaccoj@mayoclinic.com",
    verified: false,
    total_products: 5,
    due_to_seller: 175,
  },
];

export const payouts = [
  {
    id: 1,
    name: "Ricky Willbourne",
    shop_name: "Twimm",
    amount: 669,
    date: "5/22/2022",
    payment_details: "Online",
  },
  {
    id: 2,
    name: "Raquela Wark",
    shop_name: "Trudoo",
    amount: 535,
    date: "6/20/2022",
    payment_details: "Cash",
  },
  {
    id: 3,
    name: "Margaux Meese",
    shop_name: "Pixoboo",
    amount: 251,
    date: "9/28/2021",
    payment_details: "Cash",
  },
  {
    id: 4,
    name: "Cynthie Tomaszynski",
    shop_name: "Eamia",
    amount: 577,
    date: "10/21/2021",
    payment_details: "Online",
  },
  {
    id: 5,
    name: "Catlee Zarfati",
    shop_name: "Topicblab",
    amount: 435,
    date: "6/28/2022",
    payment_details: "Online",
  },
];

export const payoutsRequested = [
  {
    id: 1,
    name: "Alvin Roch",
    shop_name: "Ooba",
    requested_amount: 301,
    date: "10/20/2021",
    message: "Donec semper sapien a libero.",
    status: false,
  },
  {
    id: 2,
    name: "Oberon Scutching",
    shop_name: "Topicshots",
    requested_amount: 965,
    date: "7/19/2022",
    message: "Integer a nibh.",
    status: false,
  },
  {
    id: 3,
    name: "Benedetta Dench",
    shop_name: "Yotz",
    requested_amount: 679,
    date: "10/28/2021",
    message: "Quisque ut erat.",
    status: false,
  },
  {
    id: 4,
    name: "Fax MacIan",
    shop_name: "Demizz",
    requested_amount: 205,
    date: "3/13/2022",
    message:
      "Vivamus metus arcu, adipiscing molestie, hendrerit at, vulputate vitae, nisl.",
    status: false,
  },
  {
    id: 5,
    name: "Tatum Stronge",
    shop_name: "Kare",
    requested_amount: 371,
    date: "10/12/2021",
    message:
      "Morbi sem mauris, laoreet ut, rhoncus aliquet, pulvinar sed, nisl.",
    status: false,
  },
];

export const prodStock = [
  {
    id: 1,
    name: "Browning Caramel Glace",
    product_img: "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
    stock: 79,
    amount: 10177,
    seller: "Hillyer Greathead",
  },
  {
    id: 2,
    name: "Cabbage - Green",
    product_img: "http://dummyimage.com/100x100.png/dddddd/000000",
    stock: 58,
    amount: 5044,
    seller: "Durand De Ferraris",
  },
  {
    id: 3,
    name: "Sugar - Monocystal / Rock",
    product_img: "http://dummyimage.com/100x100.png/ff4444/ffffff",
    stock: 407,
    amount: 8391,
    seller: "Marylin Loyndon",
  },
  {
    id: 4,
    name: "Chips Potato Salt Vinegar 43g",
    product_img: "http://dummyimage.com/100x100.png/cc0000/ffffff",
    stock: 203,
    amount: 4062,
    seller: "Aurilia Busain",
  },
  {
    id: 5,
    name: "Soup - Beef, Base Mix",
    product_img: "http://dummyimage.com/100x100.png/ff4444/ffffff",
    stock: 357,
    amount: 8326,
    seller: "Maje Heady",
  },
  {
    id: 6,
    name: "Toothpick Frilled",
    product_img: "http://dummyimage.com/100x100.png/cc0000/ffffff",
    stock: 320,
    amount: 6055,
    seller: "Julianne Page",
  },
  {
    id: 7,
    name: "Thyme - Dried",
    product_img: "http://dummyimage.com/100x100.png/cc0000/ffffff",
    stock: 225,
    amount: 8296,
    seller: "Ricki Golley",
  },
  {
    id: 8,
    name: "Chocolate Bar - Coffee Crisp",
    product_img: "http://dummyimage.com/100x100.png/cc0000/ffffff",
    stock: 395,
    amount: 11336,
    seller: "Dori Spittal",
  },
  {
    id: 9,
    name: "Bread Cranberry Foccacia",
    product_img: "http://dummyimage.com/100x100.png/dddddd/000000",
    stock: 227,
    amount: 5670,
    seller: "Aviva Axten",
  },
  {
    id: 10,
    name: "Cheese - Brie,danish",
    product_img: "http://dummyimage.com/100x100.png/ff4444/ffffff",
    stock: 105,
    amount: 9795,
    seller: "Amelie McQuilliam",
  },
];

export const blogs = [
  {
    id: 1,
    title: "Nulla facilisi.",
    category: "Tringa glareola",
    banner: "http://dummyimage.com/200x200.png/dddddd/000000",
    short_desc:
      "Mauris enim leo, rhoncus sed, vestibulum sit amet, cursus id, turpis. Integer aliquet, massa id lobortis convallis, tortor risus dapibus augue, vel accumsan tellus nisi eu orci. Mauris lacinia sapien quis libero. Nullam sit amet turpis elementum ligula vehicula consequat.",
    published: true,
  },
  {
    id: 2,
    title:
      "Mauris enim leo, rhoncus sed, vestibulum sit amet, cursus id, turpis.",
    category: "Anthropoides paradisea",
    banner: "http://dummyimage.com/200x200.png/5fa2dd/ffffff",
    short_desc:
      "Pellentesque viverra pede ac diam. Cras pellentesque volutpat dui. Maecenas tristique, est et tempus semper, est quam pharetra magna, ac consequat metus sapien ut nunc.",
    published: true,
  },
  {
    id: 3,
    title: "Donec odio justo, sollicitudin ut, suscipit a, feugiat et, eros.",
    category: "Bubalus arnee",
    banner: "http://dummyimage.com/200x200.png/5fa2dd/ffffff",
    short_desc:
      "Nulla mollis molestie lorem. Quisque ut erat. Curabitur gravida nisi at nibh. In hac habitasse platea dictumst.",
    published: false,
  },
  {
    id: 4,
    title: "Mauris lacinia sapien quis libero.",
    category: "Lemur fulvus",
    banner: "http://dummyimage.com/200x200.png/cc0000/ffffff",
    short_desc:
      "Maecenas tristique, est et tempus semper, est quam pharetra magna, ac consequat metus sapien ut nunc. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Mauris viverra diam vitae quam. Suspendisse potenti.",
    published: true,
  },
  {
    id: 5,
    title: "Sed accumsan felis.",
    category: "Catharacta skua",
    banner: "http://dummyimage.com/200x200.png/5fa2dd/ffffff",
    short_desc:
      "Nulla ut erat id mauris vulputate elementum. Nullam varius. Nulla facilisi. Cras non velit nec nisi vulputate nonummy.",
    published: true,
  },
  {
    id: 6,
    title: "Duis consequat dui nec nisi volutpat eleifend.",
    category: "Equus burchelli",
    banner: "http://dummyimage.com/200x200.png/ff4444/ffffff",
    short_desc:
      "Aenean lectus. Pellentesque eget nunc. Donec quis orci eget orci vehicula condimentum.",
    published: false,
  },
  {
    id: 7,
    title: "Ut at dolor quis odio consequat varius.",
    category: "Uraeginthus granatina",
    banner: "http://dummyimage.com/200x200.png/dddddd/000000",
    short_desc:
      "Vivamus vestibulum sagittis sapien. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Etiam vel augue.",
    published: true,
  },
  {
    id: 8,
    title:
      "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Duis faucibus accumsan odio.",
    category: "Anthropoides paradisea",
    banner: "http://dummyimage.com/200x200.png/dddddd/000000",
    short_desc:
      "Nunc purus. Phasellus in felis. Donec semper sapien a libero. Nam dui.",
    published: false,
  },
  {
    id: 9,
    title: "Vestibulum quam sapien, varius ut, blandit non, interdum in, ante.",
    category: "Geochelone elephantopus",
    banner: "http://dummyimage.com/200x200.png/5fa2dd/ffffff",
    short_desc:
      "In hac habitasse platea dictumst. Maecenas ut massa quis augue luctus tincidunt. Nulla mollis molestie lorem.",
    published: false,
  },
  {
    id: 10,
    title: "Duis consequat dui nec nisi volutpat eleifend.",
    category: "Galago crassicaudataus",
    banner: "http://dummyimage.com/200x200.png/dddddd/000000",
    short_desc:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Proin interdum mauris non ligula pellentesque ultrices. Phasellus id sapien in sapien iaculis congue. Vivamus metus arcu, adipiscing molestie, hendrerit at, vulputate vitae, nisl.",
    published: true,
  },
];

export const coupons = [
  {
    id: 1,
    name: "41163-190",
    start_date: "06/07/2022",
    end_date: "05/11/2021",
    coupon_type: "For Products",
    active: true,
  },
  {
    id: 2,
    name: "63148-251",
    start_date: "06/08/2022",
    end_date: "13/11/2021",
    coupon_type: "For Products",
    active: true,
  },
  {
    id: 3,
    name: "42669-999",
    start_date: "16/08/2022",
    end_date: "28/08/2021",
    coupon_type: "For Total Orders",
    active: false,
  },
  {
    id: 4,
    name: "21130-550",
    start_date: "30/06/2022",
    end_date: "12/08/2022",
    coupon_type: "For Products",
    active: false,
  },
  {
    id: 5,
    name: "52125-411",
    start_date: "04/07/2022",
    end_date: "23/12/2021",
    coupon_type: "For Total Orders",
    active: false,
  },
];

export const subscribers = [
  {
    id: 1,
    email: "slettson0@moonfruit.com",
    date: "08/08/2022",
  },
  {
    id: 2,
    email: "kdachs1@dell.com",
    date: "26/07/2022",
  },
  {
    id: 3,
    email: "hcallacher2@163.com",
    date: "11/07/2022",
  },
  {
    id: 4,
    email: "jburborough3@yellowpages.com",
    date: "30/07/2022",
  },
  {
    id: 5,
    email: "mogg4@ehow.com",
    date: "18/08/2022",
  },
  {
    id: 6,
    email: "erableau5@reddit.com",
    date: "13/08/2022",
  },
  {
    id: 7,
    email: "wmacgeaney6@myspace.com",
    date: "19/08/2022",
  },
  {
    id: 8,
    email: "owillatt7@mashable.com",
    date: "09/07/2022",
  },
  {
    id: 9,
    email: "npeacock8@fotki.com",
    date: "07/07/2022",
  },
  {
    id: 10,
    email: "skyle9@ebay.co.uk",
    date: "19/08/2022",
  },
];

export const supports = [
  {
    id: 1,
    subject: "Proin leo odio, porttitor id, consequat in, consequat ut, nulla.",
    ticket_id: 533898,
    creating_date: "17/08/2022",
    status: false,
    last_replay: "15/07/2022",
    seller: "Timothy Leverentz",
  },
  {
    id: 2,
    subject: "Phasellus id sapien in sapien iaculis congue.",
    ticket_id: 399914,
    creating_date: "01/08/2022",
    status: false,
    last_replay: "10/07/2022",
    seller: "Tiertza Merali",
  },
  {
    id: 3,
    subject:
      "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec pharetra, magna vestibulum aliquet ultrices, erat tortor sollicitudin mi, sit amet lobortis sapien sapien non mi.",
    ticket_id: 452724,
    creating_date: "22/07/2022",
    status: true,
    last_replay: "27/07/2022",
    seller: "Merrill Doggerell",
  },
  {
    id: 4,
    subject: "Nam dui.",
    ticket_id: 422043,
    creating_date: "22/06/2022",
    status: true,
    last_replay: "14/08/2022",
    seller: "Aguie Ainslie",
  },
];

export const posProducts = [
  {
    id: 1,
    name: "Mibro Lite Smart Watch AMOLED Screen With SpO2",
    type: "simple",
    thumbnail_img: "https://api.markutos.com/thumbnail.jpg",
    added_by: "admin",
    seller_name: 1,
    num_of_sale: 0,
    unit_price: 500,
    rating: 0,
    current_stock: 100,
    daily_deals: 0,
    published: 1,
    featured: 0,
    most_popular: 0,
    total_review: 0,
    featured_products: 0,
  },
  {
    id: 2,
    name: "Mibro Lite 2 Smart Watch AMOLED Screen With SpO2",
    type: "simple",
    thumbnail_img: "https://api.markutos.com/thumbnail.jpg",
    added_by: "admin",
    seller_name: 1,
    num_of_sale: 0,
    unit_price: 400,
    rating: 0,
    current_stock: 100,
    daily_deals: 0,
    published: 1,
    featured: 0,
    most_popular: 0,
    total_review: 0,
    featured_products: 0,
  },
];

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiEdit } from "react-icons/fi";
import { RiUserUnfollowLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import PaginationCom from "../../../common/pagination/PaginationCom";
import { useDebounce } from "../../../hooks/useDebounce";
import { markutosAdminApi } from "../../../services/Api/api";
import SimpleLoading from "../../SimpleLoading";

const AllQuotations = () => {
  const { t } = useTranslation();
  const [reload, setReload] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [paymentType, setPaymentType] = useState("all");
  const [current, setCurrent] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  const toggle = (index) => {
    if (index === current) setCurrent(null);
    else setCurrent(index);
  };

  useEffect(() => {
    setLoading(true);
    markutosAdminApi()
      .get(
        `/pos/quotations?per_page=${perPage}&page=${currentPage}&search_value=${debouncedSearchTerm}`
      )
      .then((response) => {
        setLoading(false);
        setCurrentItems(response?.data?.data);
        setCurrentPage(response?.data?.current_page);
        setPerPage(response?.data?.per_page);
        setPageCount(response?.data?.last_page);
      });
  }, [perPage, currentPage, reload, debouncedSearchTerm, paymentType]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);

  const removeItem = (id) => {
    Swal.fire({
      title: t("are_sure"),
      text: t("delete_revert"),
      confirmButtonText: t("yes_delete_it"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        markutosAdminApi()
          .get(`/pos/quotations/delete?quotation_id=${id}`)
          .then((response) => {
            toast(response.data.message);
            setReload(true);
            setLoading(true);
          });
      }
    });
  };

  return (
    <div className="responsive-table-container">
      <div className="single-widget table">
        <div className="table-top-header">
          <div className="table-title">{t("all") + ` ` + t("quotation")}</div>
          <div className="table-filters">
            <input
              type="text"
              placeholder="Search by Name"
              className="table-search-input"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="widget-container">
          <div
            className="table-header"
            style={{
              gridTemplateColumns: "0.5fr 1.5fr 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr",
            }}
          >
            <div>#</div>
            <div>{t("customer")}</div>
            <div>{t("grand_total")}</div>
            <div>{t("created_by")}</div>
            <div>{t("status")}</div>
            <div>{t("create_date")}</div>
            <div>{t("expiry_date")}</div>
            <div>{t("monitored")}</div>
            <div className="text-center">{t("actions")}</div>
          </div>
          <div>
            {loading ? (
              <SimpleLoading />
            ) : (
              currentItems?.length > 0 &&
              currentItems?.map((quotation, index) => (
                <div key={index}>
                  <div
                    className="table-row"
                    style={{
                      gridTemplateColumns:
                        "0.5fr 1.5fr 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr",
                    }}
                  >
                    <div>{quotation?.id}</div>
                    <div>{quotation?.customer?.name}</div>
                    <div>{quotation?.grand_total}</div>
                    <div>{quotation?.created_by?.name}</div>
                    <div>{quotation?.status ? "Active" : "Inactive"}</div>
                    <div>{quotation?.creation_date}</div>
                    <div>{quotation?.expiry_date}</div>
                    <div>{quotation?.monitored ? "Yes" : "No"}</div>
                    <div className="action-column">
                      <button
                        title="Edit"
                        className="table-btn tb-edit"
                        onClick={() =>
                          navigate(`/quotation/${quotation.id}/edit`)
                        }
                      >
                        <FiEdit />
                      </button>
                      <button
                        className="table-btn tb-delete"
                        onClick={() => removeItem(quotation.id)}
                      >
                        <RiUserUnfollowLine />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            )}
            {currentItems?.length > 0 && (
              <PaginationCom
                currentItem={currentItems}
                perPage={perPage}
                pageCount={pageCount}
                currentPage={currentPage}
                setPerPage={setPerPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllQuotations;

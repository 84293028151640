import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { markutosAdminApi } from "../../services/Api/api";
import Checkbox from "./Checkbox";

const SelectPermissions = (props) => {
  const { values, setFieldValue } = props;

  const [permissionList, setPermissionList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (id,event) => {
    if(event.target.checked){
      setFieldValue("permissions", [...values?.permissions, id]);
    }else{
      setFieldValue("permissions", values?.permissions.filter(permission => permission !== id));
    }
  }
  useEffect(() => {
    setLoading(true);
    markutosAdminApi()
      .get("/roles/all-permissions")
      .then((response) => setPermissionList(response.data))
      .catch((e) => {
        toast.error(e?.message || "Something went wrong!");
      }).finally(()=>{setLoading(false)})
  }, []);

  return (
    <Container>
      {loading && <div className="spinner-grow text-primary" role="status"></div>}
      {!loading && permissionList.map((permission) => (
        <>
          <h3 className="my-3">{permission.parent}</h3>
          {permission.bases?.map((base, roleIndex) => (
            <Card key={roleIndex} className="mb-3">
              <Card.Header>{base.base_title}</Card.Header>
              <Card.Body>
                {base.permissions.map((basePermission) => (
                  <Checkbox
                    key={basePermission.id}
                    label={basePermission.name}
                    checked={values?.permissions?.includes(basePermission.id)}
                    onChange={(event) =>
                      handleChange(basePermission?.id,event)
                    }
                  />
                ))}
              </Card.Body>
            </Card>
          ))}
        </>
      ))}
    </Container>
  );
};

export default SelectPermissions;

import { useState, useEffect } from "react";
import "./SupportTickets.css";
import { useNavigate } from "react-router-dom";
import { HiOutlineChevronRight } from "react-icons/hi";
import { IoIosOpen } from "react-icons/io";
import { RiDeleteBinLine } from "react-icons/ri";
import PaginationCom from "../../common/pagination/PaginationCom";
import SimpleLoading from "../SimpleLoading";
import { markutosAdminApi } from "../../services/Api/api";
import { useDebounce } from "../../hooks/useDebounce";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const SupportTickets = () => {
  const { t } = useTranslation();
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [current, setCurrent] = useState(null);
  const toggle = (index) => {
    if (index === current) setCurrent(null);
    else setCurrent(index);
  };
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    markutosAdminApi()
      .get(`/support-tickets?search_value=${search}&per_page=${perPage}&page=${currentPage}`)
      .then((response) => {
        setLoading(false);
        setCurrentItems(response?.data?.data);
        setCurrentPage(response?.data?.current_page);
        setPerPage(response?.data?.per_page);
        setPageCount(response?.data?.last_page);
      });
  }, [perPage, currentPage, search]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);

  const removeItem = (id) => {
    Swal.fire({
      title: t("are_sure"),
      text: t("delete_revert"),
      confirmButtonText: t("yes_delete_it"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        markutosAdminApi()
          .get(`/support-tickets/delete?ticket_id=${id}`)
          .then((response) => {
            toast(response.data.message);
            setLoading(true);
          });
      }
    });
  };

  return (
    <div className="responsive-table-container">
      <div className="single-widget table">
        <div className="table-top-header">
          <div className="table-title">{t('all_sup_ticks')}</div>
          <div className="table-filters">
            <input
              type="text"
              className="table-search-input"
              placeholder={t('type_tick_id')}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="widget-container">
          <div
            className="table-header"
            style={{
              gridTemplateColumns: "50px 50px 1fr 1fr 100px 150px",
            }}
          >
            <div></div>
            <div>#</div>
            <div>{t('subject')}</div>
            <div>{t('ticket_id')}</div>
            <div className="text-center">{t('status')}</div>
            <div className="text-center">{t('options')}</div>
          </div>
          <div>
            {loading ? (
              <SimpleLoading />
            ) : (
              currentItems?.map((support, index) => (
                <div key={index}>
                  <div
                    className="table-row"
                    style={{
                      gridTemplateColumns: "50px 50px 1fr 1fr 100px 150px",
                    }}
                  >
                    <div
                      className="action-column"
                      style={{ cursor: "pointer" }}
                      onClick={() => toggle(index)}
                    >
                      <HiOutlineChevronRight />
                    </div>
                    <div>{support.id}</div>
                    <div>{support.subject}</div>
                    <div>{support.code}</div>
                    <div className="action-column">{support.status}</div>
                    <div className="action-column">
                      <button
                        title="View"
                        className="table-btn tb-edit"
                        onClick={() => navigate(`${support.id}`)}
                      >
                        <IoIosOpen />
                      </button>
                      <button
                        title="Delete"
                        className="table-btn tb-delete"
                        onClick={() => removeItem(`${support.id}`)}
                      >
                        <RiDeleteBinLine />
                      </button>
                    </div>
                  </div>
                  {current === index && (
                    <div className="row-extra">
                      <div className="row-extra-row">
                        <div>{t('last_reply')}</div>
                        <div>{support.last_reply}</div>
                      </div>
                      <div className="row-extra-row">
                        <div></div>
                        <div>{t('query')}</div>
                        <div>{support.details}</div>
                      </div>
                    </div>
                  )}
                </div>
              ))
            )}
            {currentItems.length > 0 && (
              <PaginationCom
                currentItem={currentItems}
                perPage={perPage}
                pageCount={pageCount}
                currentPage={currentPage}
                setPerPage={setPerPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportTickets;

import { useEffect, useState} from "react";
import Select from "react-select";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import * as yup from "yup";
import { toast } from "react-toastify";
import { markutosAdminApi } from "../../../services/Api/api";
import { useTranslation } from "react-i18next";

const Newsletters = () => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    emails: yup
    .array()
    .min(1, t("emails")+` `+t("are_req"))
    .required(t("email")+` `+t("is_req")),
    subject: yup.string().required(t("subject")+` `+t("is_req")),
    message: yup.string().required(t("message")+` `+t("is_req")),
  });

  const [loading, setLoading] = useState(false);
  const [subscriberEmails, setSubscriberEmails] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleEditorChange = (state) => {
    setEditorState(state);
    sendContent();
    setFieldValue("message", sendContent());
  };
  
  const sendContent = () => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  useEffect(() => {
    // get Subscriber email
    markutosAdminApi()
      .get(`/marketing/subscribers`)
      .then((response) => {
        setSubscriberEmails(response?.data);
      });
  }, []);


  const formik = useFormik({
		validationSchema: schema,
		initialValues: {
			emails: [],
			subject: "",
			message: "",
		},
		enableReinitialize: false,
		onSubmit: (values, action) => {
			setLoading(true)
			markutosAdminApi()
			.post(`/marketing/send-news-letters`, values )
			.then((res) => {
				setLoading(false);
				toast.success(res.data.message);
        action.resetForm();
			})
			.catch((e) => {
				setLoading(false)
			  	toast.error(e.message);
			});
		},
	});

  const maultiSelectHandle = (item) =>{
    return Array.from(item, option => option.value);
  }

	const {
    values,
    setErrors,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
	} = formik;

  return (
    <div style={{ maxWidth: "800px", margin: "0 auto" }}>
      <div className="single-widget">
        <div className="table-top-header">
          <div className="table-title">{t("send")+` `+t("newsletter")}</div>
        </div>
        <form  onSubmit={(e) => e.preventDefault()} >
				  <FocusError formik={formik} />
          <div className="widget-container">
            <div className="vartical-inputs">
              <label htmlFor="recipients">{t("emails")} ({t("recipients")})</label>
              <Select isMulti 
                placeholder={t("select")+` `+(t("emails"))}
                name="emails" 
                options={subscriberEmails}
                id="email"
                onBlur={handleBlur}
                onChange={(item) => setFieldValue("emails", maultiSelectHandle(item)) }
               />
              { errors.emails && (
                <small className="text-danger"> {errors.emails} </small>
              )}

              <label htmlFor="newsletter-subject">{t("newsletter")+` `+(t("subject"))}</label>
              <input type="text" name="subject" id="newsletter-subject"
                  value={values.subject}
                  onChange={handleChange}
                  onBlur={handleBlur}
               />
              {errors.subject && (
                <small className="text-danger"> {errors.subject} </small>
              )}

              <label htmlFor="newsletter-content">{t("newsletter")+` `+(t("content"))}</label>
              <div className="prod-desc-container">
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={handleEditorChange}
                />
              </div>
              { errors.message && (
                <small className="text-danger"> {errors.message} </small>
              )}

            </div>
            <div className="btns-container">
              <button className="add-btn"
                  type="submit"
                  disabled={loading}
                  onClick={handleSubmit}
              >
                {loading && <span className="spinner-grow spinner-grow-sm"></span>}
                {t("send")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Newsletters;

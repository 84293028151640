import React, { useState, useEffect } from "react";
import styles from './Coupon.module.css';
import { HiOutlineChevronRight } from "react-icons/hi";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import AddCouponModal from "./AddCouponModal";
import RippleButton from "../../../common/ui/rippleButton";
import { AiOutlinePlus } from "react-icons/ai";
import PaginationCom from "../../../common/pagination/PaginationCom";
import SimpleLoading from "../../SimpleLoading";
import { markutosAdminApi } from "../../../services/Api/api";
import { useDebounce } from "../../../hooks/useDebounce";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const Coupon = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [current, setCurrent] = useState(null);
	const [currentItems, setCurrentItems] = useState([]);
	const [editItem, setEditItem] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [reload, setReload] = useState(false);

  const toggle = (index) => {
    if (index === current) setCurrent(null);
    else setCurrent(index);
  };


	useEffect(() => {
		setLoading(true);
		markutosAdminApi().get(
		`/coupons?search_value=${search}&per_page=${perPage}&page=${currentPage}`).then((response) => {
			setLoading(false);
			setCurrentItems(response?.data?.data);
			setCurrentPage(response?.data?.current_page);
			setPerPage(response?.data?.per_page);
			setPageCount(response?.data?.last_page);
		})
  }, [perPage, currentPage, search, reload]);

	useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);

  const removeItem = (id) => {
    Swal.fire({
      title: t("are_sure"),
      text: t("delete_revert"),
      confirmButtonText: t("yes_delete_it"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        markutosAdminApi()
          .get(`/coupon/delete?coupon_id=${id}`)
          .then((response) => {
            setReload(pre => !pre);
            toast(response.data.message);
          });
      }
    });
  };

  // Update cupon status
  const changeCuponStatus =(id,checked)=>{
    markutosAdminApi()
    .get(`/coupon/change-status?coupon_id=${id}`)
    .then((response) => {
      setReload(pre => !pre);
      toast(response.data.message);
    });
  }
  const singleItem = (item) => {
    setOpenModal(true);
    setEditItem(item);
  };
  const addingCoupon = () => {
      setOpenModal(true) ;
      setEditItem(null);
  }
  return (
    <div className="single-widget">
      <div className="table-top-header">
        <div className="table-title">{t("all_coupons")}</div>
        <div className={styles.tableTopHeaderSearchSection}>
        <div className="table-filters">
            <input
              type="text"
              className="table-search-input"
              placeholder={t("search_coupon_code")}
							value={search}
							onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        <div>
          <RippleButton
            style={{ height: "40px" }}
            onClick={(e) => addingCoupon() }
          >
            <AiOutlinePlus style={{ fontSize: "18px" }} />
            <span>{t("add_new_coupon")}</span>
          </RippleButton>
        </div>
        </div>
      </div>
      <div className="widget-container">
        <div
          className="table-header"
          style={{ gridTemplateColumns: "50px 50px 1fr 1fr 100px 100px 100px" }}
        >
          <div></div>
          <div>#</div>
          <div>{t("coupon")+` `+t("code")}</div>
          <div>{t("coupon")}</div>
          <div>{t("discount")}</div>
          <div>{t("active")}</div>
          <div className="text-center">{t("actions")}</div>
        </div>
        <div>
					{ loading ? (<SimpleLoading />) : (
          currentItems?.map((coupon, index) => (
            <div key={index}>
              <div
                className="table-row"
                style={{
                  gridTemplateColumns: "50px 50px 1fr 1fr 100px 100px 100px",
                }}
              >
                <div
                  className="action-column"
                  style={{ cursor: "pointer" }}
                  onClick={() => toggle(index)}
                >
                  <HiOutlineChevronRight />
                </div>
                <div>{coupon.id}</div>
                <div>{coupon.code}</div>
                <div>{coupon.type}</div>
                <div>{coupon.discount}</div>
                <label className="confi-switch">
                  <input type="checkbox" 
                    onChange={(e)=>changeCuponStatus(coupon.id,e.target.checked)}
                    defaultChecked={parseInt(coupon.status)} />
                  <span className="slider round"></span>
                </label>
                <div className="action-column">
                  <button className="table-btn tb-edit"
                    onClick={() => singleItem(coupon)}
                  >
                    <FiEdit />
                  </button>
                  <button title="Delete" className="table-btn tb-delete" onClick={() => removeItem(`${coupon.id}`)}>
                    <RiDeleteBinLine />
                  </button>
                </div>
              </div>
              {current === index && (
                <div className="row-extra">
                  <div className="row-extra-row">
                    <div>{t("details")}</div>
                    <div>{coupon.details}</div>
                  </div>
                  <div className="row-extra-row">
                    <div>{t("start_date")}</div>
                    <div>{coupon.start_date}</div>
                  </div>
                  <div className="row-extra-row">
                    <div>{t("end_date")}</div>
                    <div>{coupon.end_date}</div>
                  </div>
                  <div className="row-extra-row">
                    <div>{t("status")}</div>
                    <div>{coupon.status}</div>
                  </div>
                </div>
              )}
            </div>
      			))
					)}
          {currentItems.length > 0 && (
          <PaginationCom
            currentItem={currentItems}
            perPage={perPage}
            pageCount={pageCount}
            currentPage={currentPage}
            setPerPage={setPerPage}
            setCurrentPage={setCurrentPage}
          />
			    )}
        </div>
      </div>
      {
        openModal == true &&
        (
      <AddCouponModal 
        currentItem={editItem}
        openModal={openModal}
        setOpenModal={setOpenModal}
        setReload={setReload}
        />
        )
      }

    </div>
  );
};

export default Coupon;

import { Fragment, useEffect, useState } from "react";
import ManageProfile from "../manageProfile/ManageProfile";
import { useTranslation } from "react-i18next";

const AddUser = ({ currentItem , setOpenModal , setReload }) => {
	const { t } = useTranslation();

  const [apiPath, setApiPath] = useState(`add-new-user`);

  useEffect(() => {
    const apiPath = currentItem?.id ? `update-user` : `add-new-user`;
    setApiPath(apiPath);
  }, [currentItem]);

  return (
    <Fragment>
      <ManageProfile setReload={setReload} apiUrl={apiPath} currentItem={currentItem} setOpenModal={setOpenModal} />
    </Fragment>

  );
};

export default AddUser;

import * as yup from "yup";

export const addProductSchema = yup.object({
  name: yup.string().required("Please enter product name"),
  category_id: yup.string().nullable(),
  stock: yup.number().min(1).nullable(),
  brand_id: yup.string().nullable(),
  barcode: yup.string().nullable(),
  thumbnail: yup.string().nullable(),
  photos: yup.array().nullable(),
  video_provider: yup.string().nullable(),
  video_link: yup.string().nullable(),
  stock_visibility_state: yup.string().nullable(),
  purchase_price: yup
    .number()
    .when("product_type", {
      is: (product_type) => product_type == "simple",
      then: yup.number(),
    })
    .nullable(),
  unit_price: yup.number() || yup.array().of(yup.number()).nullable(),
  tax: yup.number().nullable(),
  tax_type: yup.string().nullable(),
  discount: yup.number().nullable(),
  discount_type: yup.string().nullable(),
  colors: yup.array().nullable(),
  size: yup.array().nullable(),
  choice_no: yup.array().nullable(),
  choice_options: yup.array().nullable(),
  variants: yup.array().nullable(),
  product_specification: yup.string().nullable(),
  est_shipping_days: yup.number().nullable(),
  pdf: yup.string().nullable(),
  shipping_type: yup.string().nullable(),
  cash_on_delivery: yup.number().nullable(),
  featured: yup.number().nullable(),
  todays_deal: yup.number().nullable(),
});

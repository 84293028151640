import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import DashboardTopProducts from "./components/dasboardTopProducts/DashboardTopProducts";
import Counts from "./components/counts/Counts";
import Revenue from "./components/revenue/Revenue";
import RecentReviews from "./components/recentReviews/RecentReviews";
import { markutosAdminApi } from "../../services/Api/api";

const defaultData = {
  card_items: {
    total_earning: 0,
    total_orders: 25,
    total_sales: 0,
    total_customers: 11,
  },
  recent_reviews: [
    {
      id: 500,
      product_id: "26",
      user_id: "2",
      rating: "5",
      comment:
        "Quo minus expedita dolores quia sint. Atque sit aperiam aperiam perspiciatis omnis non iste quod. Voluptas quidem quasi in est.",
      status: "1",
      viewed: "0",
      created_at: "2022-09-16 16:25:29",
      updated_at: "2022-09-16 16:25:29",
      customer_name: "Mr Customer 660",
      profile_image: "https://api.markutos.com/assets/images/avatar.png",
    },
    {
      id: 499,
      product_id: "394",
      user_id: "5",
      rating: "5",
      comment:
        "Magnam voluptatum sapiente ut recusandae aperiam neque. Totam voluptatem aut deleniti impedit repudiandae porro praesentium accusantium.",
      status: "1",
      viewed: "0",
      created_at: "2022-09-16 16:25:29",
      updated_at: "2022-09-16 16:25:29",
      customer_name: "Mr Customer 441",
      profile_image: "https://api.markutos.com/assets/images/avatar.png",
    },
    {
      id: 498,
      product_id: "807",
      user_id: "5",
      rating: "4",
      comment:
        "Dolorem et qui blanditiis autem cum unde quis. A adipisci quo ipsa sit cumque omnis adipisci. Eos nemo assumenda aliquam.",
      status: "1",
      viewed: "0",
      created_at: "2022-09-16 16:25:29",
      updated_at: "2022-09-16 16:25:29",
      customer_name: "Mr Customer 441",
      profile_image: "https://api.markutos.com/assets/images/avatar.png",
    },
    {
      id: 497,
      product_id: "479",
      user_id: "1",
      rating: "3",
      comment:
        "Eum qui ut laudantium nemo libero nisi ipsa magnam. Incidunt ut expedita sequi nostrum. Quo et odit dolorum facere et.",
      status: "1",
      viewed: "0",
      created_at: "2022-09-16 16:25:29",
      updated_at: "2022-09-16 16:25:29",
      customer_name: "Mr Customer 969",
      profile_image: "https://api.markutos.com/assets/images/avatar.png",
    },
    {
      id: 496,
      product_id: "427",
      user_id: "2",
      rating: "3",
      comment:
        "Quia non provident cum est blanditiis. Culpa non quod quia et. Numquam vero dolor non quisquam quia id omnis.",
      status: "1",
      viewed: "0",
      created_at: "2022-09-16 16:25:29",
      updated_at: "2022-09-16 16:25:29",
      customer_name: "Mr Customer 660",
      profile_image: "https://api.markutos.com/assets/images/avatar.png",
    },
  ],
  top_products: [
    {
      id: "6",
      name: "Repudiandae ut quisquam dolorem explicabo",
      slug: "repudiandae-ut-quisquam-dolorem-explicabo-ghzu4",
      thumbnail_img: "https://api.markutos.com/assets/images/placeholder.jpg",
      unit_price: "444.00",
    },
    {
      id: "2",
      name: "Iure esse laudantium sit voluptas",
      slug: "iure-esse-laudantium-sit-voluptas-skocj",
      thumbnail_img: "https://api.markutos.com/assets/images/placeholder.jpg",
      unit_price: "298.00",
    },
    {
      id: "4",
      name: "Minima voluptate quasi totam deleniti",
      slug: "minima-voluptate-quasi-totam-deleniti-ywfew",
      thumbnail_img: "https://api.markutos.com/assets/images/placeholder.jpg",
      unit_price: "491.00",
    },
    {
      id: "256",
      name: "Ut voluptas similique aut voluptatem",
      slug: "ut-voluptas-similique-aut-voluptatem-rbgt8",
      thumbnail_img: "https://api.markutos.com/assets/images/placeholder.jpg",
      unit_price: "140.00",
    },
    {
      id: "512",
      name: "Eum libero amet quia a",
      slug: "eum-libero-amet-quia-a-ug3n5",
      thumbnail_img: "https://api.markutos.com/assets/images/placeholder.jpg",
      unit_price: "477.00",
    },
    {
      id: "768",
      name: "Rerum ratione recusandae magni accusantium",
      slug: "rerum-ratione-recusandae-magni-accusantium-aq2rr",
      thumbnail_img: "https://api.markutos.com/assets/images/placeholder.jpg",
      unit_price: "496.00",
    },
    {
      id: "1",
      name: "Eius laborum iusto nemo possimus",
      slug: "eius-laborum-iusto-nemo-possimus-veldf",
      thumbnail_img: "https://api.markutos.com/assets/images/placeholder.jpg",
      unit_price: "485.00",
    },
    {
      id: "257",
      name: "Est quis ex cupiditate sint",
      slug: "est-quis-ex-cupiditate-sint-mvnoe",
      thumbnail_img: "https://api.markutos.com/assets/images/placeholder.jpg",
      unit_price: "128.00",
    },
    {
      id: "513",
      name: "Voluptatem asperiores id cumque alias",
      slug: "voluptatem-asperiores-id-cumque-alias-elde1",
      thumbnail_img: "https://api.markutos.com/assets/images/placeholder.jpg",
      unit_price: "222.00",
    },
    {
      id: "769",
      name: "At quod quas nam exercitationem",
      slug: "at-quod-quas-nam-exercitationem-vqn1f",
      thumbnail_img: "https://api.markutos.com/assets/images/placeholder.jpg",
      unit_price: "439.00",
    },
  ],
  revenue_orders: 25,
  revenue_earnings: 0,
  revenue_refunds: 0,
  revenue_conversation_ratio: 39.99,
  revenues: [
    {
      name: "January",
      order: 0,
      revenue: 10000,
      refund: 0,
    },
    {
      name: "February",
      order: 0,
      revenue: 15000,
      refund: 0,
    },
    {
      name: "March",
      order: 0,
      revenue: 17000,
      refund: 0,
    },
    {
      name: "April",
      order: 0,
      revenue: 22000,
      refund: 0,
    },
    {
      name: "May",
      order: 0,
      revenue: 25000,
      refund: 0,
    },
    {
      name: "June",
      order: 0,
      revenue: 29000,
      refund: 0,
    },
    {
      name: "July",
      order: 0,
      revenue: 30000,
      refund: 0,
    },
    {
      name: "August",
      order: 0,
      revenue: 35000,
      refund: 0,
    },
    {
      name: "September",
      order: 25,
      revenue: 37000,
      refund: 0,
    },
    {
      name: "October",
      order: 0,
      revenue: 35000,
      refund: 0,
    },
    {
      name: "November",
      order: 0,
      revenue: 40000,
      refund: 0,
    },
    {
      name: "December",
      order: 0,
      revenue: 50000,
      refund: 0,
    },
  ],
};

const Dashboard = () => {
  const [allData, setAllData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    markutosAdminApi()
      .get(`/pos/dashboard`)
      .then((response) => {
        setAllData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="dashboard">
      <Counts loading={loading} card_items={allData?.card_items} />
      <div>
        <Revenue allData={allData} />
      </div>
      <div className="dashboard-third-widget">
        <DashboardTopProducts
          loading={loading}
          top_products={allData?.top_products}
        />
      </div>
    </div>
  );
};

export default Dashboard;

import { useTranslation } from "react-i18next";
import "./ShippingTime.css";

const ShippingTime = ({
  values,
  handleBlur,
  handleChange,
  errors,
  touched,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  return (
    <div className="add-product-single-widget">
      <div className="widget-title">{t('est_shipping_time')} </div>
      <div className="widget-content-container">
        <div className="shipping-input-container">
          <input
            name="est_shipping_days"
            value={values.est_shipping_days}
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            min={1}
            placeholder={t('enter_shipping_time')}
          />
          <span>{t('days')}</span>
        </div>

        {errors.est_shipping_days && touched.est_shipping_days && (
          <small className="text-danger">{errors.est_shipping_days}</small>
        )}
      </div>
    </div>
  );
};

export default ShippingTime;

import * as yup from "yup";

export const addCustomerSchema = yup.object({
  full_name: yup.string().min(2, "At least 2 characters"),
  designation: yup.string().min(2, "At least 2 characters"),
  dept: yup.string().min(2, "At least 2 characters"),
  company_name: yup.string(),
  emails: yup.array().of(yup.string().email("Invalid email format")),
  phones: yup
    .array()
    .of(yup.string().matches(/^[0-9]+$/, "Phone number must be digits only")),
  mobiles: yup
    .array()
    .of(yup.string().matches(/^[0-9]+$/, "Mobile number must be digits only")),
  fax: yup.string().matches(/^[0-9]+$/, "Fax number must be digits only"),
  address: yup.string().min(4, "At least 4 characters"),
  city: yup.string(),
  state: yup.string(),
  zip_code: yup.string().matches(/^[0-9]+$/, "Zip Code must be numerical only"),
  country: yup.string(),
  tax_number: yup.string(),
  gst_number: yup.string(),
  previous_due: yup.number(),
  customer_type: yup.string(),
  sell_type: yup.string(),
  payment_terms: yup.string(),
  industry: yup.string(),
  deposit: yup.number(),
});

import  { Fragment } from "react";
import { useState } from "react";
import "./ProductImages.css";
import UploadFilesModal from "../../../../../common/UploadFiles/UploadFilesModal";
import { useTranslation } from "react-i18next";

const BannerImage = ({
  values,
  errors,
  setFieldValue,
  catImage,
  BASE_URL
}) => {
	const { t } = useTranslation();
	const [show, setShow] = useState(false);
	const [imageFor, setImageFor] = useState("");
	const [format, setFormat] = useState("");
	const openThumbnail = () => {
		setImageFor("banner");
		setFormat("string");
		setShow(!show);
	};
  return (
	<Fragment>
		<label>{t('banner')}</label>
		<div onClick={openThumbnail}  className="custom-browse">
			<div>{t('browse')}</div>
			<div>
			{ BASE_URL + values?.banner || t('choose_file')}
			</div>
		</div>
		<div>
			{errors.banner && (
			<small className="text-danger">{errors.banner}</small>
			)}
		</div>
		<p>
		{t('banner')} <span className="helper-text">(1300x650)</span>
		</p>

		<UploadFilesModal
			setFieldValue={setFieldValue}
			useForm={true}
			format={format}
			values={values}
			imageFor={imageFor}
			show={show}
			setShow={setShow}
		/>
  	</Fragment>
  );
};

export default BannerImage;

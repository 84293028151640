import React, { useState, useEffect } from "react";
import Select from "react-select";
import { HiOutlineChevronRight } from "react-icons/hi";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import PaginationCom from "../../../common/pagination/PaginationCom";
import SimpleLoading from "../../SimpleLoading";
import { markutosAdminApi } from "../../../services/Api/api";
import { useDebounce } from "../../../hooks/useDebounce";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { priceFormat } from "../../../hooks/helper";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { set } from "immutable";

const ExpenseList = () => {
  const { t, i18n } = useTranslation();
  const [current, setCurrent] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [sortByPrice, setSortByPrice] = useState("price_high_to_low");
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [catId, setCatId] = useState("");
  const [reload, setReload] = useState(false);

  useEffect(() => {
    markutosAdminApi()
      .get(`/pos/expenses/all-categories?search_value=&per_page=99999999`)
      .then((response) => {
        setCategoryOptions(response?.data?.data);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    setReload(false);
    markutosAdminApi()
      .get(
        `/pos/all-expenses?category_id=${catId}&per_page=${perPage}&page=${currentPage}`
      )
      .then((response) => {
        setLoading(false);
        setCurrentItems(response?.data?.data);
        setCurrentPage(response?.data?.current_page);
        setPerPage(response?.data?.per_page);
        setPageCount(response?.data?.last_page);
      });
  }, [perPage, currentPage, search, sortByPrice, catId, reload]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);

  const priceSort = [
    { label: t("price_high_to_low"), value: "price_high_to_low" },
    { label: t("price_low_to_high"), value: "price_low_to_high" },
  ];

  const removeItem = (id) => {
    Swal.fire({
      title: t("are_sure"),
      text: t("delete_revert"),
      confirmButtonText: t("yes_delete_it"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        markutosAdminApi()
          .get(`pos/delete-expense?expense_id=${id}`)
          .then((response) => {
            setLoading(true);
            setReload(true);
            toast(response.data.message);
          });
      }
    });
  };

  return (
    <div className="responsive-table-container">
      <div className="single-widget table">
        <div className="table-top-header">
          <div className="table-title">{t("expense_list")} </div>
          <div className="table-filters">
            <Select
              options={categoryOptions}
              placeholder={t("sort_by_ex_cat")}
              onChange={(e) => setCatId(e.value)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
          </div>
        </div>
        {/* <div className="top-section">{t("balance")} : 0 </div> */}
        <div className="widget-container">
          <div
            className="table-header"
            style={{
              gridTemplateColumns: "50px 1.5fr 1fr 1fr 1fr 1.5fr 1.5fr 120px",
            }}
          >
            <div>#</div>
            <div>{t("expense_for")}</div>
            <div>{t("amount")}</div>
            <div>{t("category")}</div>
            <div>{t("date")}</div>
            <div>{t("created_by")}</div>
            <div>{t("expense_by")}</div>
            <div className="text-center">{t("actions")}</div>
          </div>
          <div>
            {loading ? (
              <SimpleLoading />
            ) : (
              currentItems?.map((expense, index) => (
                <div key={index}>
                  <div
                    className="table-row"
                    style={{
                      gridTemplateColumns:
                        "50px 1.5fr 1fr 1fr 1fr 1.5fr 1.5fr 120px",
                    }}
                  >
                    <div>{expense?.id}</div>

                    <div>{expense?.expense_for}</div>
                    <div>
                      {priceFormat(expense?.amount || 0, i18n.language)}
                    </div>
                    <div>{expense?.category_name}</div>
                    <div>{expense?.date}</div>
                    <div>{expense?.created_by}</div>
                    <div>{expense?.expense_by}</div>
                    <div className="action-column">
                      <button title="Edit" className="table-btn tb-edit">
                        <NavLink
                          style={{
                            width: "100%",
                            padding: "6px 8px",
                            borderRadius: "50%",
                            color: "white",
                          }}
                          to={`/expenses/edit/${expense.id}`}
                        >
                          <FiEdit />
                        </NavLink>
                      </button>

                      <button
                        title="Delete"
                        className="table-btn tb-delete"
                        onClick={() => removeItem(`${expense.id}`)}
                      >
                        <RiDeleteBinLine />
                      </button>
                    </div>
                    <div>{expense.name}</div>
                  </div>
                </div>
              ))
            )}
          </div>
          {currentItems.length > 0 && (
            <PaginationCom
              currentItem={currentItems}
              perPage={perPage}
              pageCount={pageCount}
              currentPage={currentPage}
              setPerPage={setPerPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ExpenseList;

import React, { useState, useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import SimpleLoading from "../../../SimpleLoading";
import PaginationCom from "../../../../common/pagination/PaginationCom";
import { markutosAdminApi } from "../../../../services/Api/api";
import { useDebounce } from "../../../../hooks/useDebounce";
import AddAttributeModal from "./AddAttributeModal";
import { useTranslation } from "react-i18next";

const AttributeTable = ({setReload,reload}) => {
	const { t } = useTranslation();
	const [openModal, setOpenModal] = useState(false);
	const [currentItems, setCurrentItems] = useState([]);
	const [currentItem, setCurrentItem] = useState('');
	const [pageCount, setPageCount] = useState(1);
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(false);
	const debouncedSearchTerm = useDebounce(search, 500);
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		setLoading(true);
		markutosAdminApi()
		.get(`/attributes?search_value=${search}&per_page=${perPage}&page=${currentPage}`)
		.then((response) => {
			setLoading(false);
			setCurrentItems(response?.data?.data);
			setCurrentPage(response?.data?.current_page);
			setPerPage(response?.data?.per_page);
			setPageCount(response?.data?.last_page);
		})
  }, [perPage, currentPage, search , reload ]);

	useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);

	const singleItem = (item) => {
		setOpenModal(true);
		setCurrentItem(item);
	}

  return (
    <div className="single-widget">
	<div className="table-top-header">
		<div className="table-title">{t('all') +` `+ t('attributes')}</div>
		<div className="tableFilters">
			<input
				type="text"
				className="table-search-input"
				placeholder={t('search_by_attr')}
				value={search}
				onChange={(e) => setSearch(e.target.value)}
			/>
		</div>
	</div>
      <div className="widget-container">
        <div
          className="table-header"
          style={{ gridTemplateColumns: "50px auto 100px" }}
        >
          <div className="text-center">#</div>
          <div>{t('name')}</div>
          <div>{t('actions')}</div>
        </div>
        <div>
			{ loading ? (
				<SimpleLoading />
				) : (
					currentItems.length > 0 &&
					currentItems.map((attribute, index) => (
					<div key={index}>
					<div
						className="table-row"
						style={{ gridTemplateColumns: "50px auto 100px" }}
					>
						<div className="text-center">{attribute.id}</div>
						<div>{attribute.name}</div>
						<div className="action-column">
						<button className="table-btn tb-edit" onClick={(e)=>singleItem(attribute)}>
							<FiEdit />
						</button>
						<button className="table-btn tb-delete">
							<RiDeleteBin6Line />
						</button>
						</div>
					</div>
					</div>
				))
			)}
        </div>
		{currentItems.length > 0 && (
			<PaginationCom
				currentItem={currentItems}
				perPage={perPage}
				pageCount={pageCount}
				currentPage={currentPage}
				setPerPage={setPerPage}
				setCurrentPage={setCurrentPage}
			/>
		)}
		<AddAttributeModal currentItem={currentItem} setReload={setReload} openModal={openModal} setOpenModal={setOpenModal} />
      </div>
    </div>
  );
};

export default AttributeTable;

import {useEffect,useState} from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FocusError } from "focus-formik-error";
import { markutosAdminApi } from "../../../../services/Api/api";
import * as yup from "yup";
import { textFormat } from "../../../../hooks/helper";
import { useTranslation } from "react-i18next";

const AddBlogCategory = ({currentItem,setOpenModal,setReload}) => {
	const { t } = useTranslation();
	const schema = yup.object().shape({
		name: yup.string().required(t('cat_req')),
	});

	const [loading, setLoading] = useState(false);
	const [apiPath, setApiPath] = useState(false);

	useEffect(() => {
		const apiPath = currentItem?.id ? `update-category` :  `add-new-category` ;
		setApiPath(apiPath);
	}, [currentItem]);

	const formik = useFormik({
		validationSchema: schema,
		initialValues: {
		  name: currentItem?.name || "",
		},
		enableReinitialize: true,
		onSubmit: (values, action) => {
			var slug = textFormat(values.name);
			values.slug = slug;
			if (currentItem) {
				values.category_id = currentItem.id;
			}
			setLoading(true)
			markutosAdminApi()
			.post( `/blog-categories/${apiPath}` , values )
			.then((res) => {
				setReload(pre=>!pre);
				setLoading(false);
				toast.success(res.data.message);
				if (currentItem) {
					setOpenModal(false)
				}else{
					action.resetForm();
				}
			})
			.catch((e) => {
				setLoading(false)
			  	toast.error(e.message);
			});
		},
	});
	
	const {
		values,
		setErrors,
		handleChange,
		touched,
		errors,
		handleSubmit,
		handleBlur,
		setFieldValue,
	} = formik;

  return (
    <div>
      <div className="single-widget">
		<div className="widget-title">{`${ currentItem ? t('edit') : t('add')}`} {t('blog')+` `+t('category')}</div>

		<form  onSubmit={(e) => e.preventDefault()}>
			<FocusError formik={formik} />
			<div className="widget-container vartical-inputs">
			<div>
				<label htmlFor="name">{t('name')}</label>
				<input type="text" name="name" id="name" placeholder={t('name')}
					value={values.name}
					onChange={handleChange}
					onBlur={handleBlur}
				/>
				{errors.name && touched.name && (
                	<small className="text-danger"> {errors.name} </small>
              	)}
			</div>
			<div className="btns-container">
				<button 
				type="submit"
                onClick={handleSubmit}
				disabled={loading}
				className="add-btn">
				{loading && (
					<span className="spinner-grow spinner-grow-sm"></span>
				)}
				{ currentItem ? t('edit') : t('add')}
				</button>
			</div>
			</div>
		</form>
      </div>
    </div>
  );
};

export default AddBlogCategory;

import { useState, useEffect } from "react";
import { HiOutlineChevronRight } from "react-icons/hi";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { toast } from "react-toastify";
import SimpleLoading from "../../../SimpleLoading";
import PaginationCom from "../../../../common/pagination/PaginationCom";
import { BASE_URL, markutosAdminApi } from "../../../../services/Api/api";
import { useDebounce } from "../../../../hooks/useDebounce";
import AddCategoryModal from "./AddCategoryModal";
import { useTranslation } from "react-i18next";

const CategoryTable = ({ reload, setReload }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [currentItem, setCurrentItem] = useState("");
  const [current, setCurrent] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  console.log(reload);
  useEffect(() => {
    setLoading(true);
    markutosAdminApi()
      .get(
        `/all-category?search_value=${search}&per_page=${perPage}&page=${currentPage}`
      )
      .then((response) => {
        setLoading(false);
        setCurrentItems(response?.data?.data);
        setCurrentPage(response?.data?.current_page);
        setPerPage(response?.data?.per_page);
        setPageCount(response?.data?.last_page);
      });
  }, [perPage, currentPage, search, reload]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);

  const deleteCategoryItem = (id) => {
    markutosAdminApi()
      .get(`/delete-category?category_id=${id}`)
      .then((res) => {
        setReload(true);
        toast(res?.data?.message);
      })
      .catch((error) => {});
  };

  const singleItem = (item) => {
    setOpenModal(true);
    setCurrentItem(item);
  };

  return (
    <div className="single-widget">
      <div className="table-top-header">
        <div className="table-title">All Categories</div>
        <div className="tableFilters">
          <input
            type="text"
            className="table-search-input"
            placeholder="Search category by name"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="widget-container">
        <div
          className="table-header"
          style={{ gridTemplateColumns: "50px 2fr 1fr 100px" }}
        >
          <div>#</div>
          <div>{t("name")}</div>
          <div>{t("total_product")}</div>
          <div>{t("options")}</div>
        </div>
        <div>
          {loading ? (
            <SimpleLoading />
          ) : (
            currentItems?.map((category, index) => (
              <div key={index}>
                <div
                  className="table-row"
                  style={{ gridTemplateColumns: "50px 2fr 1fr 100px" }}
                >
                  <div>{category.id}</div>
                  <div>{category.name}</div>
                  <div>{category.total_product}</div>
                  <div className="action-column">
                    <button
                      title="Edit"
                      className="table-btn tb-edit"
                      onClick={(e) => singleItem(category)}
                    >
                      <FiEdit />
                    </button>
                    <button
                      title="Delete"
                      onClick={() => deleteCategoryItem(category?.id)}
                      className="table-btn tb-delete"
                    >
                      <RiDeleteBinLine />
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        {currentItems.length > 0 && (
          <PaginationCom
            currentItem={currentItems}
            perPage={perPage}
            pageCount={pageCount}
            currentPage={currentPage}
            setPerPage={setPerPage}
            setCurrentPage={setCurrentPage}
          />
        )}
        <AddCategoryModal
          openModal={openModal}
          currentItem={currentItem}
          setOpenModal={setOpenModal}
          setReload={setReload}
        />
      </div>
    </div>
  );
};

export default CategoryTable;

import { FocusError } from "focus-formik-error";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import * as yup from "yup";
import Modal from "../../../common/modal";

const ProductPriceEditModal = ({ open, setOpen, data, handleEdit }) => {
  const { t } = useTranslation();

  const schema = yup.object({
    full_name: yup.string().min(2, "At least 2 characters"),
    designation: yup.string().min(2, "At least 2 characters"),
    dept: yup.string().min(2, "At least 2 characters"),
    company_name: yup.string(),
    emails: yup.array().of(yup.string().email("Invalid email format")),
    phones: yup
      .array()
      .of(yup.string().matches(/^[0-9]+$/, "Phone number must be digits only")),
  });

  const formik = useFormik({
    validationSchema: schema,
    initialValues: data,
    enableReinitialize: true,
    onSubmit: (values, action) => {
      handleEdit(values);
      setOpen(false);
    },
  });

  const {
    values,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = formik;

  return (
    <Modal
      open={open}
      title={t("edit") + " " + t("product")}
      onClose={() => setOpen(false)}
      maxWidth={"600px"}
      buttons={
        <button type="submit" onClick={handleSubmit} className="add-btn">
          {t("save")}
        </button>
      }
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <FocusError formik={formik} />

        <div className="widget-container">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "200px auto",
              gap: "15px",
            }}
          >
            <label htmlFor="name">{t("name")}</label>
            <input
              type="text"
              id="name"
              name="name"
              value={values?.name}
              disabled={true}
            />

            <label htmlFor="tax_type">{t("tax_type")}</label>
            <Select
              id="tax_type"
              options={[
                {
                  label: "Select One",
                  value: "default",
                },
                {
                  label: "Exclusive",
                  value: "exclusive",
                },
                {
                  label: "Inclusive",
                  value: "inclusive",
                },
              ]}
              placeholder={t("select_tax_type")}
              onChange={(e) => {
                setFieldValue("tax_type", e.value);
              }}
            />
            <label htmlFor="tax">{t("tax")}</label>
            <Select
              id="tax"
              options={[
                {
                  label: "Vat 5%",
                  value: "vat_5",
                },
                {
                  label: "Tax 10%",
                  value: "tax_10",
                },
                {
                  label: "Tax 18%",
                  value: "tax_18",
                },
              ]}
              placeholder={t("select_tax")}
              onChange={(e) => {
                setFieldValue("tax", e.value);
              }}
            />
            <label htmlFor="discount_type">{t("discount_type")}</label>
            <Select
              id="discount_type"
              options={[
                {
                  label: "Fixed",
                  value: "fixed",
                },
                {
                  label: "Percentage",
                  value: "percentage",
                },
              ]}
              placeholder={t("discount_type")}
              onChange={(e) => {
                setFieldValue("discount_type", e.value);
              }}
            />

            <label htmlFor="discount">{t("discount")}</label>
            <input
              type="number"
              id="discount"
              name="discount"
              value={values?.discount}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`form-control ${
                errors.discount && touched.discount ? "is-invalid" : ""
              }`}
            />
            {errors.discount && touched.discount && (
              <small className="text-danger">{errors.discount}</small>
            )}
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ProductPriceEditModal;

import Modal from "../../../common/modal";
import AddSeller from "./AddSeller";

const AddSellerModal = ({ currentItem, openModal, setOpenModal,setReload }) => {
  return (
    <Modal
      open={openModal}
      title={``}
      onClose={() => setOpenModal(false)}
      maxWidth={"800px"}
    >
      <AddSeller setReload={setReload} currentItem={currentItem} setOpenModal={setOpenModal} />
    </Modal>
  );
};

export default AddSellerModal;

import "@pathofdev/react-tag-input/build/index.css";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { markutosAdminApi } from "../../../../../services/Api/api";
import "./ExpenseForm.css";

const ExpenseForm = ({
  values,
  categoryId,
  setCategoryId,
  handleBlur,
  handleChange,
  errors,
  touched,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [expenseDate, setExpenseDate] = useState(null);

  useEffect(() => {
    markutosAdminApi()
      .get("/pos/expenses/all-categories?search_value=&per_page=99999999")
      .then((res) => {
        setCategoryOptions(res?.data?.data);
      })
      .catch((e) => {});

    if (values?.date) {
      setExpenseDate(new Date(values?.date));
      setCategoryId(values?.category_id);
    }
  }, []);
  return (
    <div className="add-product-single-widget">
      <div className="widget-content-container">
        <div className="product-informaiton-form">
          <label htmlFor="expense_date">
            <span>{t("expense_date")}</span>
            <i>*</i>
          </label>
          <div id="expense_date">
            <DatePicker
              dateFormat={"yyy-MM-dd"}
              name={"date"}
              selected={expenseDate}
              onChange={(date) => {
                console.log(date);
                setFieldValue("date", date);
                setExpenseDate(date);
              }}
            />
          </div>
          <label htmlFor="category_id">
            <span>{t("category")}</span>
            <i>*</i>
          </label>
          <div>
            <Select
              onBlur={handleBlur}
              name="category_id"
              onChange={(option) => {
                setCategoryId(option.id);
                setFieldValue("category_id", option?.id);
              }}
              id="category_id"
              options={categoryOptions}
              placeholder="Categories"
              value={categoryOptions.find((option) => {
                return option.id === categoryId;
              })}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
            {errors?.category_id && touched?.category_id && (
              <small className="text-danger"> {errors?.category_id} </small>
            )}
          </div>
          <label htmlFor="expense_for">
            <span>{t("expense_for")}</span>
            <i>*</i>
          </label>
          <div>
            <input
              name="expense_for"
              value={values?.expense_for}
              onChange={handleChange}
              onBlur={handleBlur}
              id="expense_for"
              type="text"
            />
            {errors?.expense_for && touched?.expense_for && (
              <small className="text-danger"> {errors?.expense_for} </small>
            )}
          </div>
          <label htmlFor="expense_by">
            <span>{t("expense_by")}</span>
            <i>*</i>
          </label>
          <div>
            <input
              name="expense_by"
              value={values?.expense_by}
              onChange={handleChange}
              onBlur={handleBlur}
              id="expense_by"
              type="text"
            />
            {errors?.expense_by && touched?.expense_by && (
              <small className="text-danger"> {errors?.expense_by} </small>
            )}
          </div>
          <label htmlFor="amount">
            <span>{t("amount")}</span>
          </label>
          <div>
            <input
              name="amount"
              value={values?.amount}
              onChange={handleChange}
              onBlur={handleBlur}
              id="amount"
              type="number"
            />
          </div>
          <label htmlFor="details">
            <span>{t("expense") + ` ` + t("details")}</span>
          </label>
          <div>
            <textarea
              onBlur={handleBlur}
              name="details"
              value={values?.details}
              onChange={handleChange}
              id="details"
              rows="5"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseForm;

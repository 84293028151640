import React from "react";
import "./Counts.css";
import { TbUsers } from "react-icons/tb";
import { FiDollarSign } from "react-icons/fi";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { TbArrowDownRight, TbArrowUpRight } from "react-icons/tb";
import SimpleLoading from "../../../SimpleLoading";
import { priceFormat } from "../../../../hooks/helper";
import { useTranslation } from "react-i18next";

const Counts = (props) => {
  const { loading, card_items } = props;
  const { t, i18n } = useTranslation();
  return (
    <div className="count-boxes">
      <div className="single-widget">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <div className="count-box-title">{t("total_earning")}</div>
            <div className="count-box-value">
              {loading ? (
                <SimpleLoading />
              ) : (
                priceFormat(card_items?.total_earning || 0, i18n.language)
              )}
            </div>
          </div>
          <div className="count-box-icon">
            <FiDollarSign />
          </div>
        </div>
        <div className="count-box-footer">
          <div className="percentage-show" style={{ color: "red" }}>
            {(card_items?.compare_earning || 0) > 0 ? (
              <TbArrowUpRight />
            ) : (
              <TbArrowDownRight />
            )}{" "}
            <span>{card_items?.compare_earning} %</span>
          </div>
          <p>
            {t("compare_to") + ` `}
            {card_items?.previous_month} {card_items?.current_year}
          </p>
        </div>
      </div>

      <div className="single-widget">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <div className="count-box-title">
              {t("total") + ` ` + t("orders")}{" "}
            </div>
            <div className="count-box-value">
              {loading ? <SimpleLoading /> : card_items?.total_orders}
            </div>
          </div>
          <div className="count-box-icon">
            <HiOutlineShoppingBag />
          </div>
        </div>
        <div className="count-box-footer">
          <div className="percentage-show" style={{ color: "red" }}>
            {card_items?.compare_orders > 0 ? (
              <TbArrowUpRight />
            ) : (
              <TbArrowDownRight />
            )}{" "}
            <span>{card_items?.compare_orders} %</span>
          </div>
          <p>
            {t("compare_to") + ` `} {card_items?.previous_month}{" "}
            {card_items?.current_year}
          </p>
        </div>
      </div>
      <div className="single-widget">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <div className="count-box-title">
              {t("total") + ` ` + t("sale")}
            </div>
            <div className="count-box-value">
              {loading ? (
                <SimpleLoading />
              ) : (
                priceFormat(card_items?.total_sales, i18n.language)
              )}
            </div>
          </div>
          <div className="count-box-icon">
            <FiDollarSign />
          </div>
        </div>
        <div className="count-box-footer">
          <div className="percentage-show" style={{ color: "red" }}>
            {card_items?.compare_sales > 0 ? (
              <TbArrowUpRight />
            ) : (
              <TbArrowDownRight />
            )}{" "}
            <span>{card_items?.compare_sales} %</span>
          </div>
          <p>
            {t("compare_to") + ` `} {card_items?.previous_month}{" "}
            {card_items?.current_year}
          </p>
        </div>
      </div>
      <div className="single-widget">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <div className="count-box-title">{t("CUSTOMERS")}</div>
            <div className="count-box-value">
              {loading ? <SimpleLoading /> : card_items?.total_customers}
            </div>
          </div>
          <div className="count-box-icon">
            <TbUsers />
          </div>
        </div>
        <div className="count-box-footer">
          <div className="percentage-show" style={{ color: "#00db00" }}>
            {card_items?.compare_customers > 0 ? (
              <TbArrowUpRight />
            ) : (
              <TbArrowDownRight />
            )}{" "}
            <span>{card_items?.compare_customers}%</span>
          </div>
          <p>
            {t("compare_to") + ` `} {card_items?.previous_month}{" "}
            {card_items?.current_year}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Counts;

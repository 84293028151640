import Modal from "../../common/modal";
import ManageProfile from "../manageProfile/ManageProfile";

const NewUserModal = ({ currentItem, openModal, setOpenModal,setReload }) => {
  return (
    <Modal
      open={openModal}
      title={``}
      onClose={() => setOpenModal(false)}
      maxWidth={"800px"}
    >
      <ManageProfile setReload={setReload} apiUrl={`update-user`} currentItem={currentItem} setOpenModal={setOpenModal} />

    </Modal>
  );
};

export default NewUserModal;

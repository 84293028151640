import { useTranslation } from "react-i18next";
const ShippingConfiguration = () => {
  const { t } = useTranslation();
  return (
    <div className="add-product-single-widget">
      <div className="widget-title">{t('shipping_config')}</div>
      <div className="widget-content-container">
        <small>{t('shipping_config_desc')}</small>
      </div>
    </div>
  );
};

export default ShippingConfiguration;

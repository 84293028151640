import axios from "axios";
import authHeader from "../auth-header";

// Production

export const BASE_URL = window?.REACT_APP_CONFIG?.BASE_URL;
export const API_URL = BASE_URL + "/api/v1/admin";
// export const API_URL = "http://localhost:8000/api/v1/admin";
// export const BASE_URL = "http://localhost:8000/";

export const markutosAdminApi = () => {
  const defaultOptions = {
    baseURL: API_URL,
  };

  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    config.headers.Authorization = authHeader();

    return config;
  });

  return instance;
};

import React, { useState, useEffect } from "react";
import { HiOutlineChevronRight } from "react-icons/hi";
import PaginationCom from "../../../common/pagination/PaginationCom";
import SimpleLoading from "../../SimpleLoading";
import { markutosAdminApi } from "../../../services/Api/api";
import { useDebounce } from "../../../hooks/useDebounce";
import DateRangeSelector from "../../../common/ui/dateRangeSelector";
import { priceFormat } from "../../../hooks/helper";
import { useTranslation } from "react-i18next";


const Payouts = () => {
	const { t,i18n } = useTranslation();
	const [current, setCurrent] = useState(null);
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(1);
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(false);
	const debouncedSearchTerm = useDebounce(search, 500);
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const toggle = (index) => {
		if (index === current) setCurrent(null);
		else setCurrent(index);
	};

	useEffect(() => {
		setLoading(true);
		markutosAdminApi()
		.get(`/all-sellers/payouts?search_value=${search}&date_from=${startDate}&date_to=${endDate}&per_page=${perPage}&page=${currentPage}`)
		.then((response) => {
			setLoading(false);
			setCurrentItems(response?.data?.data);
			setCurrentPage(response?.data?.current_page);
			setPerPage(response?.data?.per_page);
			setPageCount(response?.data?.last_page);
		})
  }, [perPage, currentPage, search , startDate, endDate ]);

	useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);

  return (
    <div className="responsive-table-container">
      <div className="single-widget table">
		<div className="table-top-header">
          <div className="table-title">{t('seller')+` `+t('payments')}</div>
          <div className="table-filters">
            <input
              type="text"
              className="table-search-input"
              placeholder={t('type_shop_name')}
			  onChange={(e) => setSearch(e.target.value)}
            />
            <div className="text-end pe-4 ">
				<DateRangeSelector
					startDate={startDate}
					endDate={endDate}
					setStartDate={setStartDate}
					setEndDate={setEndDate}
				/>
			</div>
          </div>
        </div>
        <div className="widget-container">
          <div
            className="table-header"
            style={{
              gridTemplateColumns: "50px 50px auto 150px 150px",
            }}
          >
            <div></div>
            <div>#</div>
            <div>{t('name')}</div>
            <div>{t('shop_name')}</div>
            <div className="text-center">{t('pay')+` `+t('amount')}</div>
          </div>
          <div>
			{ loading ? (<SimpleLoading />) : (
				currentItems?.map((payout, index) => (
				<div key={index}>
					<div
						className="table-row"
						style={{
							gridTemplateColumns: "50px 50px auto 150px 150px",
						}}
					>
						<div
							className="action-column"
							style={{ cursor: "pointer" }}
							onClick={() => toggle(index)}
						>
							<HiOutlineChevronRight />
						</div>
						<div>{payout.id}</div>
						<div>{payout.seller_name}</div>
						<div>{payout.shop_name}</div>
						<div className="text-center">{priceFormat(payout.amount,i18n.language)}</div>
					</div>
					{current === index && (
						<div className="row-extra">
							<div className="row-extra-row">
								<div>{t('payment')+` `+t('date')}</div>
								<div>{payout.created_at}</div>
							</div>
							<div className="row-extra-row">
								<div>{t('payment')+` `+ t('details')}</div>
								<div>{payout.message}</div>
							</div>
						</div>
					)}
				</div>

				))
			)}
			</div>

          {currentItems.length > 0 && (
				<PaginationCom
					currentItem={currentItems}
					perPage={perPage}
					pageCount={pageCount}
					currentPage={currentPage}
					setPerPage={setPerPage}
					setCurrentPage={setCurrentPage}
				/>
			)}
        </div>
      </div>
    </div>
  );
};

export default Payouts;

import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { markutosAdminApi } from "../../../../services/Api/api";
import authHeader from "../../../../services/auth-header";
import { FocusError } from "focus-formik-error";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { updateProductSchema } from "../../../../schema/productSchema";
import ProductInformation from "./components/productInformation/ProductInformation";
import ProductImages from "./components/productImages/ProductImages";
import ProductVideos from "./components/productVideos/ProductVideos";
import ProductVariation from "./components/productVariation/ProductVariation";
import ProductPriceStock from "./components/productPriceStock/ProductPriceStock";
import ProductDescription from "./components/productDescription/ProductDescription";
import PdfSpecification from "./components/pdfSpecification/PdfSpecification";
import ProductSEO from "./components/productSEO/ProductSEO";
import ShippingConfiguration from "./components/shippingConfiguration/ShippingConfiguration";
import LowStockQuantity from "./components/lowStockQuantity/LowStockQuantity";
import StockVisibility from "./components/stockVisibility/StockVisibility";
import CashOnDelivery from "./components/cashOnDelivery/CashOnDelivery";
import Featured from "./components/featured/Featured";
import TodaysDeal from "./components/todaysDeal/TodaysDeal";
import ShippingTime from "./components/shippingTime/ShippingTime";
import { useTranslation } from "react-i18next";

const EditGeneralProdModal = () => {
  const { t  } = useTranslation();
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [submiting, setSubmitting] = useState(false);
  const [selectedAttr, setSelectedAttribute] = useState([]);
  const [attrValues, setAttributeValues] = useState([]);
  const [selectedVariations, setVariations] = useState([]);
  const [selectedFinalVariants, setFinalVariants] = useState([]);
  const [categoryId, setCategoryId] = useState(false);

  const formik = useFormik({
    validationSchema: updateProductSchema,
    initialValues: {
      product_type: product?.product_type || "",
      name: product?.name || "",
      category_id: product?.category_id || "",
      brand_id: product?.brand_id || "",
      current_stock: product?.current_stock || "",
      unit: product?.unit || "",
      minimum_quantity: product?.min_qty || "",
      tags: product?.tags || [],
      barcode: product?.barcode || "",
      refundable: product?.refundable || 0,
      thumbnail_img: product?.thumbnail_img || "",
      photos: product?.photos || [],
      video_provider: product?.video_provider || "",
      video_link: product?.video_link || "",
      low_stock_quantity: product?.low_stock_quantity || "",
      stock_visibility_state: product?.stock_visibility_state || "text",
      description: product?.description || "",
      unit_price: product?.unit_price || "",
      purchase_price: product?.purchase_price || "",
      tax: product?.tax || "0",
      tax_type: product?.tax_type || "flat",
      discount: product?.discount || "",
      discount_type: product?.discount_type || "",
      colors: product?.colors || ["red"],
      choice_no: selectedAttr || [],
      choice_options: product?.choice_options || [],
      variants: product?.variants || [], // all variation with price / quantity
      variations: product?.variations || [], // slug of variant
      product_specification: product?.product_specification || "",
      shipping_type: product?.shipping_type || "test",
      est_shipping_days: product?.est_shipping_days || "",
      meta_title: product?.meta_title || "",
      meta_description: product?.meta_description || "",
      meta_img: product?.meta_img || "",
      pdf: product?.pdf || "",
      cash_on_delivery: product?.cash_on_delivery || 0,
      featured: product?.featured || 1,
      todays_deal: product?.todays_deal || 1,
      published: 1,
    },
    enableReinitialize: true,
    onSubmit: (values, action) => {
      const finalValues = values;
      finalValues.product_id = id;
      if (finalValues.discount_type == "no discount") {
        values.discount = 0;
      }

      setSubmitting(true);
      markutosAdminApi()
        .post("/update-product", values )
        .then((res) => {
          if (res.data.message == "Product updated successfully") {
            toast.success(res.data.message);
            getProduct();
            setSubmitting(false);
          }
        })
        .catch((e) => {
          toast.error(e.message);
          setSubmitting(false);
        });
    },
  });

  const getProduct = () => {
    markutosAdminApi()
      .get(`edit-product?product_id=${id}`, {
        headers: {
          Authorization: authHeader(),
        },
      })
      .then((res) => {
          setProduct(res.data.product);
          var product = res.data.product;
          // set attributes of edit
          const allAttr = [];
          const allAttrValues = [];
			product?.choice_options?.map((item) => {
				if ( item && item?.attribute_name ) {
				// set attributes
				const singleAttr = {
				value: item.attribute_id,
				label: item.attribute_name,
				};
				allAttr.push(singleAttr);
				// set attributes variation
				const singleAttrValue = { [item.attribute_name]: item.values };
				allAttrValues.push(singleAttrValue);
				}
			});
          // set category id
        //   let category_id = false;
        //   if (product?.subsubcategory_id == null && product?.subcategory_id == null ) {
        //     category_id = product?.category_id;
        //   }
        //   else if(product?.subsubcategory_id == null){
        //     category_id = product?.subcategory_id;
        //   }
        //   else {
        //     category_id = product?.subsubcategory_id;
        //   }
          setCategoryId(product?.category_id);
          //set attributes
          setSelectedAttribute(allAttr);
          //set attributes + variation
          setAttributeValues(allAttrValues);
          setVariations(product?.variations);
          setFinalVariants(product?.variants);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    getProduct();
  }, []);

  const {
    values,
    setErrors,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = formik;

  return (
    <div>
      <div className="add-product mx-3 mb-5">
        <form onSubmit={(e) => e.preventDefault()}>
          <FocusError formik={formik} />
          <div className=" d-flex justify-content-between  mb-3">
            <button
              disabled={submiting}
              onClick={handleSubmit}
              type="submit"
              className="add-btn"
            >
              {submiting ? (
                <div>
                  <div
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                  >
                    <span className="visually-hidden">{t('loading')}...</span>
                  </div>
                  {t('save') + ` `+ t('product')}
                </div>
              ) : (
                t('save') + ` `+ t('product')
              )}
            </button>
          </div>

          <div className="add-product-widget-container">
            <div className="">
              <ProductInformation
                setFieldValue={setFieldValue}
                categoryId={categoryId}
                setCategoryId={setCategoryId}
                setErrors={setErrors}
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                errors={errors}
                touched={touched}
              />
              <ProductImages
                setFieldValue={setFieldValue}
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                errors={errors}
                touched={touched}
              />
              <ProductVideos
                setFieldValue={setFieldValue}
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                errors={errors}
                touched={touched}
              />
              <ProductVariation
                setFieldValue={setFieldValue}
                selectedAttr={selectedAttr}
                attrValues={attrValues}
                selectedVariations={selectedVariations}
                selectedFinalVariants={selectedFinalVariants}
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                errors={errors}
                touched={touched}
              />
              <ProductPriceStock
                setFieldValue={setFieldValue}
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                errors={errors}
                touched={touched}
              />
              <ProductDescription
                setFieldValue={setFieldValue}
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                errors={errors}
                touched={touched}
              />
              <PdfSpecification
                setFieldValue={setFieldValue}
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                errors={errors}
                touched={touched}
              />
              <ProductSEO
                setFieldValue={setFieldValue}
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="">
              <ShippingConfiguration
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                errors={errors}
                touched={touched}
              />
              <LowStockQuantity
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                errors={errors}
                touched={touched}
              />
              <StockVisibility
                setFieldValue={setFieldValue}
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                errors={errors}
                touched={touched}
              />
              <CashOnDelivery
                setFieldValue={setFieldValue}
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                errors={errors}
                touched={touched}
              />
              <Featured
                setFieldValue={setFieldValue}
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                errors={errors}
                touched={touched}
              />
              <TodaysDeal
                setFieldValue={setFieldValue}
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                errors={errors}
                touched={touched}
              />
              <ShippingTime
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                errors={errors}
                touched={touched}
              />
            </div>
          </div>

          <div className="mt-4">
            <button
              disabled={submiting}
              onClick={handleSubmit}
              type="submit"
              className="add-btn"
            >
              {submiting ? (
                <div>
                  <div
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  Save Product
                </div>
              ) : (
                "Save Product"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditGeneralProdModal;

/**
 * Get price with format
 * @param {*} price
 * @param {*} code
 * @param {*} type
 * @returns
 */
export const priceFormat = (price, code = "en", type = "simple") => {
  var resultPrice = price;
  var country_code = "en-US";
  var currency = "USD";
  const languages = [
    { label: "EN", value: { code: "en-US", currency: "USD" }, code: "en" },
    { label: "FR", value: { code: "de-DE", currency: "EUR" }, code: "fr" },
  ];
  let current_lang = languages.find((item) => item.code === code);

  if (current_lang) {
    country_code = current_lang?.value?.code;
    currency = current_lang?.value?.currency;
  }
  if (price !== null) {
    var result = new Intl.NumberFormat(country_code, {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (type == "simple") {
      resultPrice = result.format(price);
    } else if (type == "variation") {
      const price_arr = price?.split("-");
      resultPrice =
        result.format(price_arr?.[0]) + `-` + result.format(price_arr?.[1]);
    }
  }

  return resultPrice;
};

/**
 * Get Slug from variation
 * @param {*} attributesArray
 * @returns
 */
export const getVariation = (attributesArray) => {
  var final = [];
  if (attributesArray.length == 1) {
    for (let i = 0; i < attributesArray[0]?.length; i++) {
      final.push(attributesArray[0][i]);
    }
    return final;
  } else if (attributesArray.length == 2) {
    for (let i = 0; i < attributesArray[0]?.length; i++) {
      for (let j = 0; j < attributesArray[1]?.length; j++) {
        final.push(attributesArray[0][i] + "-" + attributesArray[1][j]);
      }
    }

    return final;
  } else if (attributesArray.length == 3) {
    for (let i = 0; i < attributesArray[0]?.length; i++) {
      for (let j = 0; j < attributesArray[1]?.length; j++) {
        for (let k = 0; k < attributesArray[2]?.length; k++) {
          final.push(
            attributesArray[0][i] +
              "-" +
              attributesArray[1][j] +
              "-" +
              attributesArray[2][k]
          );
        }
      }
    }
    return final;
  } else if (attributesArray.length == 4) {
    for (let i = 0; i < attributesArray[0]?.length; i++) {
      for (let j = 0; j < attributesArray[1]?.length; j++) {
        for (let k = 0; k < attributesArray[2]?.length; k++) {
          for (let l = 0; l < attributesArray[3]?.length; l++) {
            final.push(
              attributesArray[0][i] +
                "-" +
                attributesArray[1][j] +
                "-" +
                attributesArray[2][k] +
                "-" +
                attributesArray[3][l]
            );
          }
        }
      }
    }
    return final;
  } else if (attributesArray.length == 5) {
    for (let i = 0; i < attributesArray[0]?.length; i++) {
      for (let j = 0; j < attributesArray[1]?.length; j++) {
        for (let k = 0; k < attributesArray[2]?.length; k++) {
          for (let l = 0; l < attributesArray[3]?.length; l++) {
            for (let m = 0; m < attributesArray[3]?.length; m++) {
              final.push(
                attributesArray[0][i] +
                  "-" +
                  attributesArray[1][j] +
                  "-" +
                  attributesArray[2][k] +
                  "-" +
                  attributesArray[3][l] +
                  "-" +
                  attributesArray[4][m]
              );
            }
          }
        }
      }
    }
    return final;
  }
};

/**
 * lower case and dash
 * @param {*} text
 * @returns
 */
export const textFormat = (text) => {
  var result_text = "";
  if (text !== null && text !== "") {
    result_text = text.split(" ").join("-").toLowerCase();
  }
  return result_text;
};

/**
 * Shipping Method
 * @returns
 */
export const shippingMethod = (t) => {
  var method = [
    { value: "free_shipping", label: t("free_shipping") },
    { value: "flat_rate", label: t("flat_rate") },
  ];
  return method;
};

/**
 * Shipping Method
 * @returns
 */
export const generateSlug = (name) => {
  let slug = "";
  if (name !== "") {
    slug = name.replace(/\s+/g, "-").toLowerCase();
  }
  return slug;
};

import React, { useEffect, useState } from "react";
import "./ProductInformation.css";
import Select from "react-select";
import "@pathofdev/react-tag-input/build/index.css";
import { BASE_URL, markutosAdminApi } from "../../../../../../services/Api/api";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { set } from "immutable";
import { NavLink } from "react-bootstrap";

const ProductInformation = ({
  values,
  categoryId,
  setCategoryId,
  handleBlur,
  handleChange,
  errors,
  touched,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [warrantyPeriod, setWarrantyPeriod] = useState(null);
  const [warrantyDuration, setWarrantyDuration] = useState(null);

  useEffect(() => {
    setFieldValue("warranty", `${warrantyPeriod} ${warrantyDuration}`);
  }, [warrantyPeriod, warrantyDuration, setFieldValue]);

  useEffect(() => {
    markutosAdminApi()
      .get("/all-category?search_value=&per_page=&page=")
      .then((res) => {
        setCategoryOptions(res?.data?.data);
      })
      .catch((e) => {});
    markutosAdminApi()
      .get("/all-brands?per_page=999999")
      .then((res) => {
        setBrandOptions(res?.data?.data);
      })
      .catch((e) => {});

    setWarrantyPeriod(
      values?.warranty ? values?.warranty.split(" ")?.[0] : null
    );
    setWarrantyDuration(
      values?.warranty ? values?.warranty.split(" ")?.[1] : null
    );
  }, []);

  return (
    <div className="add-product-single-widget">
      <div className="widget-title">{t("product_information")}</div>
      <div className="widget-content-container">
        <div className="product-informaiton-form">
          <label htmlFor="product__name">
            <span>{t("product") + ` ` + t("name")}</span>
            <i>*</i>
          </label>
          <div>
            <input
              name="name"
              value={values?.name}
              onChange={handleChange}
              onBlur={handleBlur}
              id="product__name"
              type="text"
            />
            {errors?.name && touched?.name && (
              <small className="text-danger"> {errors?.name} </small>
            )}
          </div>
          <label htmlFor="model">
            <span>{t("model")}</span>
          </label>
          <div>
            <input
              name="model"
              value={values?.model}
              onChange={handleChange}
              onBlur={handleBlur}
              id="model"
              type="text"
            />
          </div>
          <label htmlFor="product__Brand">
            <span>{t("brand")}</span>
          </label>
          <div>
            <Select
              onBlur={handleBlur}
              id="brand_id"
              name="brand_id"
              onChange={(option) => setFieldValue("brand_id", option.id)}
              options={brandOptions}
              placeholder="Brands"
              value={brandOptions.find((option) => {
                return option.id === values?.brand_id;
              })}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
            <span>
              <NavLink href="/pos-products/brands">Create New Brand</NavLink>
            </span>
          </div>
          <label htmlFor="origin">
            <span>{t("origin")}</span>
          </label>
          <div>
            <input
              name="origin"
              value={values?.origin}
              onChange={handleChange}
              onBlur={handleBlur}
              id="origin"
              type="text"
            />
          </div>
          <label htmlFor="category_id">
            <span>{t("category")}</span>
            <i>*</i>
          </label>
          <div>
            <Select
              onBlur={handleBlur}
              name="category_id"
              onChange={(option) =>
                setCategoryId(option.id) +
                setFieldValue("category_id", option.id)
              }
              id="category_id"
              options={categoryOptions}
              placeholder="Categories"
              value={categoryOptions.find((option) => {
                return option.value === categoryId;
              })}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
            {errors?.category_id && touched?.category_id && (
              <small className="text-danger"> {errors?.category_id} </small>
            )}
            <span>
              <NavLink href="/pos-products/categories">
                Create New Category
              </NavLink>
            </span>
          </div>
          <label htmlFor="product__unit">
            <span>{t("unit")}</span>
          </label>
          <div>
            <input
              onBlur={handleBlur}
              name="unit"
              placeholder="Piece/Kg/Litre"
              value={values?.unit}
              onChange={handleChange}
              id="product__unit"
              type="text"
            />

            {errors?.unit && touched?.unit && (
              <small className="text-danger"> {errors?.unit} </small>
            )}
          </div>
          <label htmlFor="barcode">
            <span>{t("barcode")}</span>
          </label>
          <div>
            <input
              name="barcode"
              onChange={handleChange}
              value={values?.barcode}
              id="barcode"
              type="text"
            />
          </div>
          <label htmlFor="warranty">
            <span>{t("warranty")}</span>
          </label>
          <div id="warranty" className="row">
            <div className="col-6">
              <input
                type="number"
                className="form-control"
                placeholder="Warranty Period"
                value={warrantyPeriod}
                onChange={(event) => setWarrantyPeriod(event.target.value)}
              />
            </div>
            <div className="col-6">
              <Select
                onBlur={handleBlur}
                onChange={(option) => setWarrantyDuration(option)}
                options={[
                  { label: "Days", value: "Days" },
                  { label: "Weeks", value: "Weeks" },
                  { label: "Months", value: "Months" },
                  { label: "Years", value: "Years" },
                ]}
                placeholder="Warranty Duration"
                value={warrantyDuration}
                styles={{
                  control: (baseStyles) => {
                    return {
                      ...baseStyles,
                      height: "44px",
                    };
                  },
                }}
              />
            </div>
          </div>

          <label>
            <span>{t("refundable")}</span>
          </label>
          <div>
            <label className="confi-switch" for="is-product-refundable-switch">
              <input
                id="is-product-refundable-switch"
                name="refundable"
                value={values?.refundable}
                onChange={(e) =>
                  setFieldValue("refundable", e.target.checked ? 1 : 0)
                }
                checked={values?.refundable === 1 ? true : false}
                type="checkbox"
              />

              <span className="slider round"></span>
            </label>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductInformation;

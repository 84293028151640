import SettingInfo from "./components/settingInfo/SettingInfo";
import SettingSocialLinks from "./components/settingSocialLinks/SettingSocialLinks";
import settingStyle from "./shopSetting.module.css";
import { useFormik } from "formik";
import { useState,useEffect } from "react";
import { markutosAdminApi } from "../../../../services/Api/api";
import { toast } from "react-toastify";
import authHeader from "../../../../services/auth-header";
import { shopSettingSchema } from "../../../../schema/index";
import { useTranslation } from "react-i18next";

const ShopSettings = () => {
  const { t  } = useTranslation();

  const [submiting, setSubmitting] = useState(false);
  const [setting, setSetting] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // get categories
  useEffect(() => {
    markutosAdminApi()
    .get(`/website-setup/get-data`, {
      headers: {
        Authorization: authHeader(),
      },
    })
    .then((response) => {
		setSetting(response?.data);
    })
    .catch((error) => {
      console.log(error.message);
    });
  }, [ loading ]);

  const { values, handleChange, touched, errors, handleSubmit, setFieldValue } =
    useFormik({
      validationSchema: shopSettingSchema,
      initialValues: {
        marketplace_name: setting?.marketplace_name || "",
        email: setting?.email || "",
        phone: setting?.phone || "",
        address: setting?.address || "",
        header_logo: setting?.header_logo || "",
        header_icon_logo: setting?.header_icon_logo || "",
        footer_logo: setting?.footer_logo || "",
        footer_icon_logo: setting?.footer_icon_logo || "",
        footer_about_text: setting?.footer_about_text || "",
        facebook: setting?.facebook || "",
        twitter: setting?.twitter || "",
        google: setting?.google || "",
        youtube: setting?.youtube || "",
        instagram: setting?.instagram || "",
      },
      enableReinitialize: true,
      onSubmit: (values, action) => {
        setSubmitting(true);
        values.language_switcher = 1;
        values.currency_switcher = 1;
        values.sticky_header = 1;
        markutosAdminApi()
          .post(`/website-setup/store`, values )
          .then((res) => {
            toast.success(res.data.message);
            setSetting(values);
            setSubmitting(false);
          })
          .catch((err) => {
            toast.error(err.message);
            setSubmitting(false);
          });
        action.resetForm();
      },
    });

  return (
    <>
      {loading ? (
        <h1>{t('loading')}</h1>
      ) : (
        <div className="add-product mx-3 mb-5">
          <div className={settingStyle.add_product_widget_container}>
            <SettingInfo
              submiting={submiting}
              touched={touched}
              values={values}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              setFieldValue={setFieldValue}
              errors={errors}
            />
            <SettingSocialLinks
              submiting={submiting}
              values={values}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ShopSettings;

import { useState, useEffect } from "react";
import { saveAs } from 'file-saver';
import "./UploadFiles.css";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiUploadCloud } from "react-icons/fi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import {
  AiOutlineInfoCircle,
  AiOutlineDownload,
  AiOutlineDelete,
} from "react-icons/ai";
import { RiFileCopyLine } from "react-icons/ri";
import RippleButton from "../../common/ui/rippleButton";
import PaginationCom from "../../common/pagination/PaginationCom";
import SimpleLoading from "../SimpleLoading";
import { useDebounce } from "../../hooks/useDebounce";
import UploadFilesModal from "./UploadFiles/UploadFilesModal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { markutosAdminApi } from "../../services/Api/api";
import { useTranslation } from "react-i18next";

const UploadFiles = () => {
  const { t } = useTranslation();
  const [current, setCurrent] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [reloading, setReload] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState(0);
//   modal states 
  const [show, setShow] = useState(false);
  const [fieldValue, setFieldValue] = useState([]);

  const toggle = (index) => {
    if (index === current) setCurrent(null);
    else setCurrent(index);
  };

  useEffect(() => {
    setLoading(true);
    markutosAdminApi()
      .get(`/uploads?search_value=${search}&per_page=${perPage}&page=${currentPage}`)
      .then((response) => {
        setLoading(false);
        setCurrentItems(response?.data?.data);
        setCurrentPage(response?.data?.current_page);
        setPerPage(response?.data?.per_page);
        setPageCount(response?.data?.last_page);
      });
  }, [perPage, currentPage, search , reloading ]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);

  const handleOptions = (index, name, size) => {
    if (index === current) setCurrent(null);
    else setCurrent(index);
    setFileName(name);
    setFileSize(size);
  };

  const handleUploadFile = e =>{
	  setShow(true);
  };

  const handleCopyLink = (url) =>{
    navigator.clipboard.writeText(url);
    toast("Copied",{autoClose: 1000})
  };

  const handleDownloadFile =(uri, name) =>{
    saveAs(uri, name)
};

  const handleDeleteItem = (id)=>{
		markutosAdminApi()
		.get(`/uploads/delete-file?file_id=${id}`)
		.then((response) => {
			toast( response.data.message , {autoClose: 1000});
      setReload((pre) => !pre);
		});
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <p>
          {fileName} <br /> {fileSize}
        </p>
      </Popover.Body>
    </Popover>
  );


  return (
    <div className="upload-files">
      <div className="upload-files-header">
        <div className="upload-files-title">{t('all_upload_file')}</div>
        <div className="ufh-right">
          <input
            type="text"
            className="table-search-input"
            placeholder={t('search_by_name')}
            onChange={(e) => setSearch(e.target.value)}
          />
          <RippleButton style={{ height: "37px" }} onClick={handleUploadFile}>
            <FiUploadCloud style={{ fontSize: "18px" }} />
            <span>{t('up_new_file')}</span>
          </RippleButton>
        </div>
      </div>
      <div className="uploaded-files-container">
        {loading ? (
          <SimpleLoading />
        ) : (
          currentItems?.map((file, index) => (
            <div className="single-uploaded-file" key={index}>
              <div className="file-img-container">
                <img src={file.file_url} alt={file.file_original_name} />
              </div>
              <div className="file-name">File Name :{file.file_original_name}</div>
              <div className="file-size">File Size : {file.file_size} kb</div>
              <div
                className="file-options"
                onClick={() =>
                  handleOptions(index, file.file_original_name, file.file_size)
                }
              >
                <div className="file-options-icon-container">
                  <BsThreeDotsVertical />
                  {current === index && (
                    <div className="file-options-box">
                      <OverlayTrigger
                        trigger="focus"
                        placement="right"
                        overlay={popover}
                      >
                        <div className="single-file-option">
                          <AiOutlineInfoCircle />
                          <span>{t('details_info')}</span>
                        </div>
                      </OverlayTrigger>
                      <div className="single-file-option" onClick={()=>handleDownloadFile(file.file_url,file.file_original_name)}>
                        <AiOutlineDownload />
                        <span>{t('download')}</span>
                      </div>
                      <div className="single-file-option" onClick={()=>handleCopyLink(file.file_url)}>
                        <RiFileCopyLine />
                        <span>{t('copy_link')}</span>
                      </div>
                      <div className="single-file-option" onClick={()=>handleCopyLink(file.file_name)}>
                        <RiFileCopyLine />
                        <span>{t('copy_file_name')}</span>
                      </div>
                      <div className="single-file-option" onClick={()=>handleDeleteItem(file.id)}>
                        <AiOutlineDelete />
                        <span>{t('delete')}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <div>
        {currentItems?.length > 0 && (
          <PaginationCom
            currentItem={currentItems}
            perPage={perPage}
            pageCount={pageCount}
            currentPage={currentPage}
            setPerPage={setPerPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
      {/* Upload new file */}
	    <UploadFilesModal setReload={setReload} show={show} setShow={setShow} fieldValue={fieldValue} setFieldValue={setFieldValue}/>
    
    <ToastContainer/> 
    </div>
  );
};

export default UploadFiles;

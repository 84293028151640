import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FocusError } from "focus-formik-error";
import { markutosAdminApi } from "../../../services/Api/api";
import * as yup from "yup";
import Select from "react-select";
import Modal from "../../../common/modal";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import BlogImages from "./blogImages/BlogImages";
import ReactTagInput from "@pathofdev/react-tag-input";
import { useTranslation } from "react-i18next";

const AddBlogModal = ({ currentItem, openModal, setOpenModal, setReload }) => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [allTags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiPath, setApiPath] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [getMetKeywords, setMetKeywords] = useState([]);

  useEffect(() => {
    const apiPath = currentItem?.id ? `update-blog` : `add-new-blog`;
    setApiPath(apiPath);
    setSelectedCategories(currentItem?.categories);
    setSelectedTags(currentItem?.tags);
    setMetKeywords(currentItem?.meta_keywords || []);
  }, [currentItem]);

  useEffect(() => {
    // categories
    markutosAdminApi()
      .get(`/all-blogs/get-categories`)
      .then((response) => {
        setCategories(response?.data);
      });

    // tags
    markutosAdminApi()
      .get(`/all-blogs/get-tags`)
      .then((response) => {
        setTags(response?.data);
      });
  }, [openModal]);

  const handleEditorChange = (state) => {
    setEditorState(state);
    sendContent();
    setFieldValue("description", sendContent());
  };
  
  const sendContent = () => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const schema = yup.object().shape({
    title: yup.string().required(t('title_req')),
    slug: yup.string().required(t('slug_req')),
    categories: yup
      .array()
      .min(1, t('category_req'))
      .required(t('category_req')),
    tags: yup.array().min(1, t('tags_req')).required(t('tags_req')),
    banner: yup.string().required(t('banner_req')),
    description: yup.string().required(t('desc_req')),
    short_description: yup.string().required(t('short_desc_req')),
  });
  
  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      title: currentItem?.title || "",
      slug: currentItem?.slug || "",
      categories: currentItem?.categories || [],
      tags: currentItem?.tags || [],
      short_description: currentItem?.short_description || "",
      banner: currentItem?.banner || "",
      description: currentItem?.description || "",
      meta_title: currentItem?.meta_title || "",
      meta_img: currentItem?.meta_img || "",
      meta_description: currentItem?.meta_description || "",
      meta_keywords: currentItem?.meta_keywords || [],
    },
    enableReinitialize: true,
    onSubmit: (values, action) => {
      if (currentItem) {
        values.blog_id = currentItem.id;
      }
      setLoading(true);
      markutosAdminApi()
        .post(`/all-blogs/${apiPath}`, values)
        .then((res) => {
          setReload(pre => !pre);
          setLoading(false);
          toast.success(res.data.message);
          if (currentItem) {
            setOpenModal(false);
          } else {
            action.resetForm();
          }
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e.message);
        });
    },
  });
  
  const {
    values,
    setErrors,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = formik;

  const handleMultiSelect = (type, item) => {
    if (type == "categories") {
      setSelectedCategories(item);
    } else if (type == "tags") {
      setSelectedTags(item);
    }
    setFieldValue(type, item);
  };

  const metKeywords = (newTags) => {
    setMetKeywords(newTags);
    setFieldValue("meta_keywords", newTags);
  };

  return (
    // <form onSubmit={(e) => e.preventDefault()}>
    <Modal
      open={openModal}
      title={currentItem ? t('edit_blog_info') : t('add_blog_info')}
      onClose={() => setOpenModal(false)}
      maxWidth={"950px"}
      buttons={
        <button
          type="submit"
          onClick={handleSubmit}
          disabled={loading}
          className="add-btn"
        >
          {loading && <span className="spinner-grow spinner-grow-sm"></span>}
          {`${currentItem ? t('edit') : t('add') }`}
        </button>
      }
    >
      <div
        style={{
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <div className="widget-container">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "200px auto",
              gap: "15px",
            }}
          >
            <FocusError formik={formik} />
            <label htmlFor="title">{t('blog_title')}</label>
            <input
              type="text"
              id="title"
              name="title"
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.title && touched.title && (
              <small className="text-danger"> {errors.title} </small>
            )}

            <label htmlFor="categories">{t('categories')}</label>
            <Select
              isMulti
              options={categories}
              placeholder={t('select_cats')}
              id="categories"
              onBlur={handleBlur}
              name="categories"
              onChange={(item) => handleMultiSelect("categories", item)}
              value={selectedCategories}
            />
            {errors.categories && touched.categories && (
              <small className="text-danger"> {errors.categories} </small>
            )}
            <label htmlFor="tags">{t('tags')}</label>
            <Select
              isMulti
              options={allTags}
              id="tags"
              placeholder={t('select_tags')}
              onBlur={handleBlur}
              name="tags"
              value={selectedTags}
              onChange={(option) => handleMultiSelect("tags", option)}
            />
            {errors.tags && touched.tags && (
              <small className="text-danger"> {errors.tags} </small>
            )}
            <label htmlFor="slug">{t('slug')}</label>
            <input
              type="slug"
              id="slug"
              value={values.slug}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.slug && touched.slug && (
              <small className="text-danger"> {errors.slug} </small>
            )}

            <BlogImages
              setFieldValue={setFieldValue}
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              errors={errors}
              touched={touched}
            />
            {errors.banner && touched.banner && (
              <small className="text-danger"> {errors.banner} </small>
            )}

            <label htmlFor="short_description">{t('short_desc')}</label>
            <textarea
              name="short_description"
              id=""
              cols="30"
              rows="5"
              value={values.short_description}
              onChange={handleChange}
              onBlur={handleBlur}
            ></textarea>
            {errors.short_description && touched.short_description && (
              <small className="text-danger">
                {" "}
                {errors.short_description}{" "}
              </small>
            )}
            <label htmlFor="desc">{t('description')}</label>
            <div className="prod-desc-container">
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={handleEditorChange}
              />
            </div>
            {errors.description && touched.description && (
              <small className="text-danger"> {errors.description} </small>
            )}
            <label htmlFor="meta-title">{t('meta_title')}</label>
            <input
              name="meta_title"
              type="text"
              id="meta-title"
              value={values.meta_title}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <label htmlFor="meta_description"></label>
            <textarea
              name="meta_description"
              id="meta_description"
              cols="30"
              rows="5"
              value={values.meta_description}
              onChange={handleChange}
              onBlur={handleBlur}
            ></textarea>

            <label htmlFor="meta-keywords">{t('meta_keys')}</label>
            <ReactTagInput
              tags={getMetKeywords}
              removeOnBackspace={true}
              onChange={(newTags) => metKeywords(newTags)}
            />
          </div>
        </div>
      </div>
    </Modal>
    //  </form>
  );
};

export default AddBlogModal;

import { useTranslation } from "react-i18next";
import "./addPurchase.css";
function PreviousPaymentInformation({ values = [] }) {
  const { t } = useTranslation();
  const handleDelete = (id) => {
    console.log(id);
  };
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>{t("date")}</th>
            <th>{t("payment_type")}</th>
            <th>{t("payment_note")}</th>
            <th>{t("payment")}</th>
            {/* <th>{t("action")}</th> */}
          </tr>
        </thead>

        <tbody>
          {values?.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item?.date}</td>
              <td className="text-capitalize">{item?.payment_type}</td>
              <td>{item?.payment_note}</td>
              <td>{item?.amount}</td>
              {/* <td>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleDelete(index)}
                >
                  Delete
                </button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PreviousPaymentInformation;

import { useState, useEffect } from "react";
import { HiOutlineChevronRight } from "react-icons/hi";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import AddBlogModal from "../addBlogModal/AddBlogModal";
import RippleButton from "../../../common/ui/rippleButton";
import { AiOutlinePlus } from "react-icons/ai";
import PaginationCom from "../../../common/pagination/PaginationCom";
import SimpleLoading from "../../SimpleLoading";
import { markutosAdminApi } from "../../../services/Api/api";
import { useDebounce } from "../../../hooks/useDebounce";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const AllBlogs = () => {
  const { t } = useTranslation();
  const [reload, setReload] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [current, setCurrent] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentItem, setCurrentItem] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const toggle = (index) => {
    if (index === current) setCurrent(null);
    else setCurrent(index);
  };

  useEffect(() => {
    setLoading(true);
    markutosAdminApi()
      .get(`/all-blogs?search_value=${search}&per_page=${perPage}&page=${currentPage}`)
      .then((response) => {
        setLoading(false);
        setCurrentItems(response?.data?.data);
        setCurrentPage(response?.data?.current_page);
        setPerPage(response?.data?.per_page);
        setPageCount(response?.data?.last_page);
      });
  }, [perPage, currentPage, search, reload]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);

  const removeItem = (id) => {
    Swal.fire({
      title: t("are_sure"),
      text: t("delete_revert"),
      confirmButtonText: t("yes_delete_it"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        markutosAdminApi()
          .get(`/all-blogs/delete?blog_id=${id}`)
          .then((response) => {
            setLoading(true);
            toast(response.data.message);
          });
      }
    });
  };

  const singleItem = (item) => {
    setOpenModal(true);
    setCurrentItem(item);
  };

  return (
    <div className="responsive-table-container">
      <div className="single-widget table">
        <div className="table-top-header">
          <div className="table-title">{t("all_posts")}</div>
          <div className="table-filters">
            <input
              type="text"
              className="table-search-input"
              placeholder={t("search_by_post_nam")}
            />
            <RippleButton
              style={{ height: "40px" }}
              onClick={() => setOpenModal(true)}
            >
              <AiOutlinePlus style={{ fontSize: "18px" }} />
              <span>{t('new_post')}</span>
            </RippleButton>
          </div>
        </div>
        <div className="widget-container">
          <div
            className="table-header"
            style={{
              gridTemplateColumns: "50px 50px auto 100px 150px",
            }}
          >
            <div></div>
            <div>#</div>
            <div>{t("title")}</div>
            <div className="text-center">{t("published")}</div>
            <div className="text-center">{t("options")}</div>
          </div>
          <div>
            {loading ? (
              <SimpleLoading />
            ) : (
              currentItems?.map((blog, index) => (
                <div key={index}>
                  <div
                    className="table-row"
                    style={{
                      gridTemplateColumns: "50px 50px auto 100px 150px",
                    }}
                  >
                    <div
                      className="action-column"
                      style={{ cursor: "pointer" }}
                      onClick={() => toggle(index)}
                    >
                      <HiOutlineChevronRight />
                    </div>
                    <div>{blog.id}</div>
                    <div>{blog.title}</div>
                    <div className="action-column">
                      <label className="confi-switch">
                        <input
                          type="checkbox"
                          defaultChecked={blog.published}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div className="action-column">
                      <button
                        title="Edit"
                        className="table-btn tb-edit"
                        onClick={() => singleItem(blog)}
                      >
                        <FiEdit />
                      </button>
                      <button
                        title="Delete"
                        className="table-btn tb-delete"
                        onClick={() => removeItem(`${blog.id}`)}
                      >
                        <RiDeleteBinLine />
                      </button>
                    </div>
                  </div>
                  {current === index && (
                    <div className="row-extra">
                      <div className="row-extra-row">
                        <div>{t("banner")}</div>
                        <div className="table-extra-img">
                          <img src={blog.banner} alt="" />
                        </div>
                      </div>
                      <div className="row-extra-row">
                        <div>{t("category")}</div>
                        <div>{blog.category}</div>
                      </div>
                      <div className="row-extra-row">
                        <div>{t("short_desc")}</div>
                        <div>{blog.short_desc}</div>
                      </div>
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
        </div>
        {currentItems.length > 0 && (
          <PaginationCom
            currentItem={currentItems}
            perPage={perPage}
            pageCount={pageCount}
            currentPage={currentPage}
            setPerPage={setPerPage}
            setCurrentPage={setCurrentPage}
          />
        )}
        <AddBlogModal
          currentItem={currentItem}
          openModal={openModal}
          setOpenModal={setOpenModal}
          setReload={setReload}
        />
      </div>
    </div>
  );
};

export default AllBlogs;

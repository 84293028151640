import React from "react";
import "./translations/i18next";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "../src/styles/sass/style.scss";
import "bootstrap/dist/js/bootstrap.min.js";
import AppProviders from "./setup/providers";
import { ToastContainer } from "react-toastify";
import { createRoot } from "react-dom/client";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <AppProviders>
    <App />
    <ToastContainer />
  </AppProviders>
);

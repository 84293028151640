import { loadFromLocalStorageSignIn } from "../utils/manageLocalStorage";

export default function authHeader(){
    if (typeof window !== 'undefined') {
        // Perform localStorage action
        const userStr = loadFromLocalStorageSignIn();
        let user = null;
        if (userStr)
            user = userStr?.access_token;
        if (user && user) {
            return 'Bearer ' + user.toString();
        } else {
            return null;
        }
    }
}
import  { Fragment } from "react";
import { useState } from "react";
import "./ProductImages.css";
import UploadFilesModal from "../../../../../common/UploadFiles/UploadFilesModal";
import { useTranslation } from "react-i18next";

const IconImage = ({
  values,
  errors,
  setFieldValue,
  catImage,
  BASE_URL,
}) => {
	const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [imageFor, setImageFor] = useState("");
  const [format, setFormat] = useState("");

  const openThumbnail = () => {
    setImageFor("icon");
    setFormat("string");
    setShow(!show);
  };
  return (
	<Fragment>
		<label> {t('image')+` `+t('icon')} </label>
		<div onClick={openThumbnail}  className="custom-browse">
			<div>{t('browse')}</div>
			<div>
			{ BASE_URL + values?.icon || t('choose_file')}
			</div>
		</div>
		<div>
			{errors.icon && (
			<small className="text-danger">{errors.icon}</small>
			)}
		</div>
		<p>
			{t('icon')} <span className="helper-text">(50x50)</span>
		</p>

		<UploadFilesModal
			setFieldValue={setFieldValue}
			useForm={true}
			format={format}
			values={values}
			imageFor={imageFor}
			show={show}
			setShow={setShow}
		/>
  	</Fragment>
  );
};

export default IconImage;

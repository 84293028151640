import React, { useState } from "react";
import UploadFilesModal from "../../../../../../common/UploadFiles/UploadFilesModal";
import { useTranslation } from "react-i18next";

const PdfSpecification = ({
  values,
  handleBlur,
  handleChange,
  errors,
  touched,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  return (
    <div className="add-product-single-widget">
      <div className="widget-title">{t('pdf_speci')}</div>
      <div className="widget-content-container">
        <div className="ap-single-content">
          <div>
            <p>{t('add_pdf')}</p>
          </div>
          <div>
            <div onClick={() => setShow(!show)} className="custom-browse">
              <div>{t('browse')}</div>
              <div>{values.pdf ? values.pdf : t('choose_file')}</div>
            </div>

            <UploadFilesModal
              setFieldValue={setFieldValue}
              format="string"
              values={values}
              imageFor="pdf"
              show={show}
              setShow={setShow}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfSpecification;


import { Fragment, useEffect } from "react";
import { useState } from "react";
import { markutosAdminApi } from "../../../services/Api/api";
import { toast } from "react-toastify";
import { Table } from "react-bootstrap";
import PaginationCom from "../../../common/pagination/PaginationCom";
import { useTranslation } from "react-i18next";

const ProductSetup = () => {
  const { t } = useTranslation();
  const [type, setType] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
	const [search, setSearch] = useState("");
  const allTypes = ["most_popular","daily_deals","featured_products"];

  const getProducts = ({_search="", _currentPage=currentPage, _perPage=perPage}) => {
    markutosAdminApi()
    .get(`/products?search_value=${_search}&sort_seller=&sort_by=price_high_to_low&per_page=${_perPage}&page=${_currentPage}`)
      .then((response) => {
      setProducts(response?.data?.data);
			setCurrentPage(response?.data?.current_page);
			setPerPage(response?.data?.per_page);
			setPageCount(response?.data?.last_page);
    })
    .catch((error) => {
      console.log(error.message);
    });
  }
  // get products
  useEffect(() => {

    getProducts({_search: search, _perPage: perPage, _currentPage: currentPage});
    setLoading(false);
    
  }, [perPage, currentPage, search ]);

  const updateType = ( productId , key , checked ) =>{
    setType(allTypes[key]);
    setLoading(true);
    markutosAdminApi()
    .get(`/products/index-state?product_id=${productId}&type=${allTypes[key]}`)
    .then((res) => {
      getProducts({_search: search, _perPage: perPage, _currentPage: currentPage});
      toast.success(res.data.message);
    })
    .catch((error) => {
      console.log(error.message);
    });
  }

  return (
	  <Fragment>
      <div className="table-top-header">
				<div className="table-title">{t('manage')+` `+t('products')} </div>
				<div className="tableFilters">
					<input
						type="text"
						className="table-search-input"
            placeholder={t('search_pro_name')}
						value={search}
						onChange={(e) => setSearch(e.target.value)}
					/>
				</div>
			</div>
        <Table bordered responsive>
              <thead>
                <tr>
                  <th>{t('product')}</th>
                  <th>{t('popular')}</th>
                  <th>{t('daily_deals')}</th>
                  <th>{t('featured')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  products?.map((item,key)=>{
                    return(
                      <tr key={key}>
                        <td>{item.name}</td>
                        <td>
                          <label className="confi-switch">
                            <input type="checkbox" 
                              onChange={(e)=> updateType( item.id , 0 , e.target.checked )}
                              checked={parseInt(item.most_popular)} />
                            <span className="slider round"></span>
                          </label>
                        </td>
                        <td>
                          <label className="confi-switch">
                            <input type="checkbox" 
                              onChange={(e)=> updateType( item.id , 1 , e.target.checked )}
                              checked={parseInt(item.daily_deals)} />
                            <span className="slider round"></span>
                          </label>
                        </td>
                        <td>
                          <label className="confi-switch">
                            <input type="checkbox" 
                              onChange={(e)=> updateType( item.id , 2 , e.target.checked )}
                              checked={parseInt(item.featured_products)} />
                            <span className="slider round"></span>
                          </label>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
        </Table>
        {products.length > 0 && (
            <PaginationCom
              currentItem={products}
              perPage={perPage}
              pageCount={pageCount}
              currentPage={currentPage}
              setPerPage={setPerPage}
              setCurrentPage={setCurrentPage}
            />
          )}
    </Fragment>
  );
};

export default ProductSetup;

import React from "react";
import "./ProductPriceStock.css";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const ProductPriceStockDigital = ({
  values,
  handleBlur,
  handleChange,
  errors,
  touched,
  setFieldValue,
}) => {
  const { t } = useTranslation();

  const discountOptions = [
    { value: "no discount", label: t('no_discount')},
    { value: "flat", label: t('flat') },
    { value: "percent", label: t('percent')},
  ];

  return (
    <div
      className={`add-product-single-widget ${
        values.discount_type == "no discount" && "pb-5"
      }`}
    >
      <div className="widget-title">{t('pro_price_stock')}</div>
      <div className="widget-content-container">
        <div className="ap-single-content">
          <p>{ t('purchase')+` `+t('price') }  *</p>
          <div>
            <input
              name="purchase_price"
              onBlur={handleBlur}
              value={values.purchase_price}
              onChange={handleChange}
              type="number"
              min={0}
            />

            {errors.purchase_price && touched.purchase_price && (
              <small className="text-danger"> {errors.purchase_price} </small>
            )}
          </div>
        </div>

        <div className="ap-single-content">
          <p>{ t('unit')+` `+ t('price') } *</p>
          <div>
            <input
              name="unit_price"
              onBlur={handleBlur}
              value={values.unit_price}
              onChange={handleChange}
              type="number"
              min={0}
            />

            {errors.unit_price && touched.unit_price && (
              <small className="text-danger"> {errors.unit_price} </small>
            )}
          </div>
        </div>
        <div className="ap-single-content">
          <p>{t('discount_type')} *</p>
          <div>
            <Select
              name="discount_type"
              onBlur={handleBlur}
              value={discountOptions.find((option) => {
                return option.value == values.discount_type;
              })}
              id="discount_type"
              onChange={(option) =>
                setFieldValue("discount_type", option.value)
              }
              options={discountOptions}
            />

            {errors.discount_type && touched.discount_type && (
              <small className="text-danger"> {errors.discount_type} </small>
            )}
          </div>
        </div>
        {values.discount_type != "no discount" && (
          <div className="ap-single-content">
            <p>{t('discount')}</p>
            <div>
              <input
                name="discount"
                onBlur={handleBlur}
                value={values.discount}
                onChange={handleChange}
                type="number"
                min={0}
              />

              {errors.discount && touched.discount && (
                <small className="text-danger"> {errors.discount} </small>
              )}
            </div>
          </div>
        )}
        <div className="ap-single-content">
          <p>{t('current_stock')} *</p>
          <div>
            <input
              name="current_stock"
              onBlur={handleBlur}
              value={values.current_stock}
              onChange={handleChange}
              type="number"
              min={0}
            />

            {errors.current_stock && touched.current_stock && (
              <small className="text-danger"> {errors.current_stock} </small>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPriceStockDigital;

import { useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FocusError } from "focus-formik-error";
import * as yup from "yup";
import { markutosAdminApi } from "../../../services/Api/api";
import Modal from "../../../common/modal";
import Select from "react-select";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const RefundRequestModal = ({ currentItem, openModal, setOpenModal, setReload }) => {
  const { t,i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState( currentItem?.refund_status || "Pending" );

  const schema = yup.object().shape({
    admin_note: yup.string().required(t('admin_note_req')),
  });

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      admin_note: currentItem?.admin_note || "",
      refund_status: currentItem?.refund_status || "Pending",
    },
    enableReinitialize: true,
    onSubmit: (values, action) => {
      if (currentItem) {
        values.request_id = currentItem.id;
      }
      setLoading(true);
      markutosAdminApi()
        .post(`/refund-requests/change-status`, values )
        .then((res) => {
          setReload((pre) => !pre);
          setLoading(false);
          toast.success(res.data.message);
          setOpenModal(false);
          
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e.message);
        });
    },
  });

  const {
    values,
    setErrors,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = formik;

  const  refundStatus =[
    {label:t('approved'),value: "Approved"},
    {label:t('rejected'),value:"Rejected"},
    {label:t('pending'),value:"Pending"}
  ];

  return (
    <Modal
      open={openModal}
      title={t('refund') +` `+ t('request')}
      onClose={() => setOpenModal(false)}
      maxWidth={"950px"}
      buttons={
        <button
          type="submit"
          onClick={handleSubmit}
          disabled={loading}
          className="add-btn"
        >
          {loading && <span className="spinner-grow spinner-grow-sm"></span>}
          {t('save_changes')}
        </button>
      }
    >
        <div
					style={{
					display: "grid",
					gridTemplateColumns: "160px auto",
					fontSize: "16px",
					marginBottom: "15px",
					}}
				>
					<div>{t('refund') +` `+ t('reason')}:</div>
					<div>{currentItem?.reason}</div>
          <div>{t('refund') +` `+ t('amount')}:</div>
					<div>{currentItem?.refund_amount}</div>
          <div>{t('seller') +` `+ t('notes')}:</div>
					<div>{currentItem?.seller_note}</div>
				</div>
        <div
          style={{
            marginBottom: "15px",
          }}
        >
          <div>{t('products')}:</div>
          <Table responsive>
              <thead>
                <tr>
                  <th>{t('name')}</th>
                  <th>{t('price')}</th>
                  <th>{t('quantity')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  currentItem?.products?.map((item,key)=>{
                    return(
                      <tr key={key}>
                        <td>{item.name}</td>
                        <td>{item.price}</td>
                        <td>{item.quantity}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
          </Table>
        </div>
        <form  onSubmit={(e) => e.preventDefault()}>
				    <FocusError formik={formik} />
            <div>
            <label htmlFor="refund_status">{t('refund') +` `+ t('status')}</label>
            <Select
              options={refundStatus}
              value = {
                refundStatus.filter(option => 
                  option.value === status )
              }
              placeholder={t('refund') +` `+ t('status')}
              onChange={(e)=>setFieldValue("refund_status",e.value) + setStatus(e.value) }
            />
            </div>
            <div>
              <label htmlFor="admin_note">{t('admin') +` `+ t('notes')}</label>
              <textarea name="admin_note" rows={"5"}  cols={"5"}
                id="admin_note" placeholder={t('ent_mess')}
                value={values.admin_note}
                onChange={handleChange}
                onBlur={handleBlur}
                >
              </textarea>
              {errors.admin_note && touched.admin_note && (
                <small className="text-danger"> {errors.admin_note} </small>
              )}
            </div>
          </form>
    </Modal>
  );
};

export default RefundRequestModal;

import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { markutosAdminApi } from "../../../../services/Api/api";
import { FocusError } from "focus-formik-error";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ExpenseForm from "./components/ExpenseForm";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

const schema = yup.object().shape({
  date: yup
    .date()
    .required("Date is required")
    .default(() => new Date()),
  category_id: yup.string().required("Category ID is required"),
  expense_for: yup.string().required("Expense for is required"),
  expense_by: yup.string().required("Expense by is required"),
  amount: yup
    .number()
    .typeError("Amount must be a number")
    .required("Amount is required")
    .positive("Amount must be a positive number"),
  details: yup.string(),
});
const defaultInitialValues = {
  date: null,
  category_id: null,
  expense_for: null,
  expense_by: null,
  amount: null,
  details: null,
};
const AddExpense = () => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [categoryId, setCategoryId] = useState(false);
  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const formik = useFormik({
    validationSchema: schema,
    initialValues: defaultInitialValues,
    onSubmit: (values, action) => {
      const finalValues = values;
      finalValues.date = new Date(finalValues?.date)
        ?.toISOString()
        ?.split("T")[0];
      finalValues.added_by = "pos";
      setSubmitting(true);
      if (id) {
        finalValues.expense_id = id;
        markutosAdminApi()
          .post("/pos/update-expense", finalValues)
          .then((res) => {
            if (res.data?.result) {
              action.resetForm();
            }
            toast.success(res.data.message);
            setSubmitting(false);
            navigate("/expenses/all");
          })
          .catch((e) => {
            toast.error(e.message);
            setSubmitting(false);
          });
      } else {
        markutosAdminApi()
          .post("/pos/add-new-expense", finalValues)
          .then((res) => {
            if (res.data?.result) {
              action.resetForm();
            }
            toast.success(res.data.message);
            setSubmitting(false);
            navigate("/expenses/all");
          })
          .catch((e) => {
            toast.error(e.message);
            setSubmitting(false);
          });
      }
    },
  });

  const {
    values,
    setErrors,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
    setValues,
  } = formik;

  useEffect(() => {
    if (id) {
      setLoading(true);
      markutosAdminApi()
        .get(`/pos/edit-expense?expense_id=${id}`)
        .then((res) => {
          setValues(res?.data);
        })
        .catch((e) => {
          toast.error(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);
  return (
    <div className="add-product mx-3 mb-5">
      {loading ? (
        <span className="spinner-grow spinner-grow-sm"></span>
      ) : (
        <form onSubmit={(e) => e.preventDefault()}>
          <FocusError formik={formik} />
          <div className=" d-flex justify-content-between mb-3">
            <h4>{t("new_expense")}</h4>
            <button
              disabled={submitting}
              onClick={handleSubmit}
              type="submit"
              className="add-btn"
            >
              {submitting ? (
                <div>
                  <div
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                  >
                    <span className="visually-hidden">{t("loading")}...</span>
                  </div>
                  {t("save")}
                </div>
              ) : (
                t("save")
              )}
            </button>
          </div>

          <div className="add-product-widget-container">
            <div className="">
              <ExpenseForm
                setFieldValue={setFieldValue}
                setErrors={setErrors}
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                setCategoryId={setCategoryId}
                categoryId={categoryId}
                errors={errors}
                touched={touched}
              />
            </div>
          </div>

          <div className="mt-4">
            <button
              disabled={submitting}
              onClick={handleSubmit}
              type="submit"
              className="add-btn"
            >
              {submitting ? (
                <div>
                  <div
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  {t("save")}
                </div>
              ) : (
                t("save")
              )}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddExpense;

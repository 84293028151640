import React from "react";
import "./AddProduct.css";
import AddProducts from "./addGeneralProduct/AddProducts";

const AddProduct = () => {
  return(
    <AddProducts/>
  )
};

export default AddProduct;

import { FocusError } from "focus-formik-error";
import { FieldArray, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosAddCircle } from "react-icons/io";
import { TiDelete } from "react-icons/ti";
import { toast } from "react-toastify";
import UploadFilesModal from "../../../common/UploadFiles/UploadFilesModal";
import { markutosAdminApi } from "../../../services/Api/api";
import "./addSupplier.css";
import { addSupplierSchema } from "./addSupplierSchema";
import { useNavigate, useParams } from "react-router-dom";
import SimpleLoading from "../../SimpleLoading";

const AddSupplier = () => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const initialValues = {
    name: "",
    company_name: "",
    designation: "",
    department: "",
    industry: "",
    emails: [""],
    mobiles: [""],
    phones: [""],
    fax: "",
    country: "",
    address: "",
    city: "",
    state: "",
    zip_code: "",
    tax_number: "",
    gst_number: "",
    image: "",
    payment_terms: "",
    website: "",
    notes: "",
    previous_due: 0,
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      markutosAdminApi()
        .get(`pos/supplier/edit?supplier_id=${id}`)
        .then((res) => {
          setFormValues(res.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  if (loading) {
    return <SimpleLoading />;
  }

  return (
    <Formik
      initialValues={formValues?.id ? formValues : initialValues}
      validationSchema={addSupplierSchema}
      onSubmit={async (values, actions) => {
        setIsSubmitting(true);
        try {
          if (!values?.image) {
            values.image = "/";
          }
          let response;
          if (id) {
            values.supplier_id = id;
            response = await markutosAdminApi().post(
              "pos/supplier/update",
              values
            );
          } else {
            response = await markutosAdminApi().post(
              "pos/supplier/add",
              values
            );
          }

          toast.success(response.data.message);
          actions.resetForm();
          navigate("/suppliers/all");
        } catch (e) {
          toast.error(e.message);
        } finally {
          setIsSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <div className="add-product mx-3 mb-5">
          <form onSubmit={handleSubmit}>
            <FocusError
              formik={{
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }}
            />
            <div className="form-title">
              <h4>{t("add") + " " + t("supplier")}</h4>
              <button disabled={isSubmitting} type="submit" className="add-btn">
                {isSubmitting ? (
                  <div>
                    <div
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    >
                      <span className="visually-hidden">{t("loading")}...</span>
                    </div>
                    {t("save")}
                  </div>
                ) : (
                  t("save")
                )}
              </button>
            </div>
            <div className="form-container">
              <div className="add-product-single-widget px-5 py-2 row">
                <div className="col-md-6 gap-2">
                  <div className="field-container">
                    <label htmlFor="supplier__full_name">
                      <span>{t("full_name")}</span>
                    </label>
                    <input
                      id="supplier__full_name"
                      type="text"
                      name="full_name"
                      value={values.full_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {(errors.full_name || touched.full_name) && (
                      <small className="text-danger">{errors.full_name}</small>
                    )}
                  </div>
                  <div className="field-container">
                    <label htmlFor="supplier__company_name">
                      <span>{t("company_name")}</span>
                    </label>
                    <input
                      id="supplier__company_name"
                      type="text"
                      name="company_name"
                      value={values.company_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {(errors.company_name || touched.company_name) && (
                      <small className="text-danger">
                        {errors.company_name}
                      </small>
                    )}
                  </div>
                  <div className="field-container">
                    <label htmlFor="supplier__designation">
                      <span>{t("designation")}</span>
                    </label>
                    <input
                      id="supplier__designation"
                      type="text"
                      name="designation"
                      value={values.designation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {(errors.designation || touched.designation) && (
                      <small className="text-danger">
                        {errors.designation}
                      </small>
                    )}
                  </div>

                  <div className="field-container">
                    <label htmlFor="supplier__dept">
                      <span>{t("dept")}</span>
                    </label>
                    <input
                      id="supplier__dept"
                      type="text"
                      name="department"
                      value={values.department}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.dept && touched.dept && (
                      <small className="text-danger">{errors.dept}</small>
                    )}
                  </div>

                  <div className="field-container">
                    <label htmlFor="supplier__industry">
                      <span>{t("industry")}</span>
                    </label>
                    <input
                      id="supplier__industry"
                      type="text"
                      className="form-control"
                      name="industry"
                      value={values.industry}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.industry && touched.industry && (
                      <small className="text-danger">{errors.industry}</small>
                    )}
                  </div>
                  <div className="field-container">
                    <label htmlFor="supplier__address">
                      <span>{t("address")}</span>
                    </label>
                    <input
                      id="supplier__address"
                      type="text"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.address && touched.address && (
                      <small className="text-danger">{errors.address}</small>
                    )}
                  </div>
                  <div className="field-container">
                    <label htmlFor="supplier__city">
                      <span>{t("city")}</span>
                    </label>
                    <input
                      id="supplier__city"
                      type="text"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.city && touched.city && (
                      <small className="text-danger">{errors.city}</small>
                    )}
                  </div>
                  <div className="field-container">
                    <label htmlFor="supplier__state">
                      <span>{t("state")}</span>
                    </label>
                    <input
                      id="supplier__state"
                      type="text"
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.state && touched.state && (
                      <small className="text-danger">{errors.state}</small>
                    )}
                  </div>
                  <div className="field-container">
                    <label htmlFor="supplier__zip_code">
                      <span>{t("zip_code")}</span>
                    </label>
                    <input
                      id="supplier__zip_code"
                      type="text"
                      name="zip_code"
                      value={values.zip_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.zip_code && touched.zip_code && (
                      <small className="text-danger">{errors.zip_code}</small>
                    )}
                  </div>
                  <div className="field-container">
                    <label htmlFor="supplier__country">
                      <span>{t("country")}</span>
                    </label>
                    <input
                      id="supplier__country"
                      type="text"
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.country && touched.country && (
                      <small className="text-danger">{errors.country}</small>
                    )}
                  </div>
                </div>
                {/* Next Column  */}
                <div className="col-md-6 gap-2">
                  <FieldArray name="emails" className="field-container">
                    {({ push, remove }) => (
                      <div>
                        <label htmlFor="supplier__emails">
                          <span>{t("emails")}</span>
                        </label>
                        {values.emails.map((email, index) => (
                          <div
                            key={index}
                            className=" row align-items-center my-2"
                          >
                            <div className="col-10">
                              <input
                                id={`supplier__emails_${index}`}
                                type="text"
                                name={`emails.${index}`}
                                value={email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.emails &&
                                errors.emails[index] &&
                                touched.emails &&
                                touched.emails[index] && (
                                  <small className="text-danger">
                                    {errors.emails[index]}
                                  </small>
                                )}
                            </div>
                            <div className="col-2 text-center">
                              <div className="row justify-content-between align-items-center">
                                {index > 0 && (
                                  <div className="col-6 rounded-circle text-danger">
                                    <TiDelete
                                      size={24}
                                      onClick={() => remove(index)}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                )}
                                {values.emails.length < 5 &&
                                  index + 1 === values.emails.length && (
                                    <div className="col-6">
                                      <button
                                        className="btn btn-primary btn-sm rounded-1 px-2 py-1 mb-2"
                                        type="button"
                                        onClick={() => push("")}
                                      >
                                        <IoIosAddCircle size={20} />
                                      </button>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </FieldArray>
                  <FieldArray name="phones" className="field-container">
                    {({ push, remove }) => (
                      <div>
                        <label htmlFor="supplier__phones">
                          <span>{t("phones")}</span>
                        </label>
                        {values.phones.map((phone, index) => (
                          <div
                            key={index}
                            className=" row align-items-center my-2"
                          >
                            <div className="col-10">
                              <input
                                id={`supplier__phones_${index}`}
                                type="text"
                                name={`phones.${index}`}
                                value={phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.phones &&
                                errors.phones[index] &&
                                touched.phones &&
                                touched.phones[index] && (
                                  <small className="text-danger">
                                    {errors.phones[index]}
                                  </small>
                                )}
                            </div>
                            <div className="col-2 text-center">
                              <div className="row justify-content-between align-items-center">
                                {index > 0 && (
                                  <div className="col-6 rounded-circle text-danger">
                                    <TiDelete
                                      size={24}
                                      onClick={() => remove(index)}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                )}
                                {values.phones.length < 5 &&
                                  index + 1 === values.phones.length && (
                                    <div className="col-6">
                                      <button
                                        className="btn btn-primary btn-sm rounded-1 px-2 py-1 mb-2"
                                        type="button"
                                        onClick={() => push("")}
                                      >
                                        <IoIosAddCircle size={20} />
                                      </button>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </FieldArray>
                  <FieldArray name="mobiles" className="field-container">
                    {({ push, remove }) => (
                      <div>
                        <label htmlFor="supplier__mobiles">
                          <span>{t("mobiles")}</span>
                        </label>
                        {values.mobiles.map((mobile, index) => (
                          <div
                            key={index}
                            className=" row align-items-center my-2"
                          >
                            <div className="col-10">
                              <input
                                id={`supplier__mobiles_${index}`}
                                type="text"
                                name={`mobiles.${index}`}
                                value={mobile}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.mobiles &&
                                errors.mobiles[index] &&
                                touched.mobiles &&
                                touched.mobiles[index] && (
                                  <small className="text-danger">
                                    {errors.mobiles[index]}
                                  </small>
                                )}
                            </div>
                            <div className="col-2 text-center">
                              <div className="row justify-content-between align-items-center">
                                {index > 0 && (
                                  <div className="col-6 rounded-circle text-danger">
                                    <TiDelete
                                      size={24}
                                      onClick={() => remove(index)}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                )}
                                {values.mobiles.length < 5 &&
                                  index + 1 === values.mobiles.length && (
                                    <div className="col-6">
                                      <button
                                        className="btn btn-primary btn-sm rounded-1 px-2 py-1 mb-2"
                                        type="button"
                                        onClick={() => push("")}
                                      >
                                        <IoIosAddCircle size={20} />
                                      </button>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </FieldArray>
                  <div className="field-container">
                    <label htmlFor="supplier__fax">
                      <span>{t("fax")}</span>
                    </label>
                    <input
                      id="supplier__fax"
                      type="text"
                      name="fax"
                      value={values.fax}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.fax && touched.fax && (
                      <small className="text-danger">{errors.fax}</small>
                    )}
                  </div>
                  <div className="field-container">
                    <label htmlFor="supplier__tax_number">
                      <span>{t("tax_number")}</span>
                    </label>
                    <input
                      id="supplier__tax_number"
                      type="text"
                      name="tax_number"
                      value={values.tax_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.tax_number && touched.tax_number && (
                      <small className="text-danger">{errors.tax_number}</small>
                    )}
                  </div>
                  <div className="field-container">
                    <label htmlFor="supplier__gst_number">
                      <span>{t("gst_number")}</span>
                    </label>
                    <input
                      id="supplier__gst_number"
                      type="text"
                      name="gst_number"
                      value={values.gst_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.gst_number && touched.gst_number && (
                      <small className="text-danger">{errors.gst_number}</small>
                    )}
                  </div>

                  <div className="field-container">
                    <label htmlFor="supplier__payment_terms">
                      <span>{t("payment_terms")}</span>
                    </label>
                    <input
                      id="supplier__payment_terms"
                      type="text"
                      className="form-control"
                      name="payment_terms"
                      value={values.payment_terms}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.payment_terms && touched.payment_terms && (
                      <small className="text-danger">
                        {errors.payment_terms}
                      </small>
                    )}
                  </div>
                  <div className="field-container">
                    <label htmlFor="supplier__website">
                      <span>{t("website")}</span>
                    </label>
                    <input
                      id="supplier__website"
                      type="text"
                      className="form-control"
                      name="website"
                      value={values.website}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.website && touched.website && (
                      <small className="text-danger">{errors.website}</small>
                    )}
                  </div>
                  <div className="field-container">
                    <label htmlFor="supplier__notes">
                      <span>{t("notes")}</span>
                    </label>
                    <input
                      id="supplier__notes"
                      type="text"
                      className="form-control"
                      name="notes"
                      value={values.notes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.notes && touched.notes && (
                      <small className="text-danger">{errors.notes}</small>
                    )}
                  </div>
                  <div className="field-container">
                    <label htmlFor="supplier__image">
                      <span>{t("image")}</span>
                    </label>
                    <div
                      onClick={() => setShowModal(true)}
                      className="custom-browse"
                    >
                      <div>{t("browse")}</div>
                      <div>
                        {values.image ? values.image : t("choose_file")}
                      </div>
                    </div>
                    <UploadFilesModal
                      setFieldValue={setFieldValue}
                      format={"string"}
                      values={values}
                      imageFor={"image"}
                      show={showModal}
                      setShow={setShowModal}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 d-flex justify-content-end">
              <button
                disabled={isSubmitting}
                onClick={handleSubmit}
                type="submit"
                className="add-btn me-md-5  me-0"
              >
                {isSubmitting ? (
                  <div>
                    <div
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    >
                      <span className="visually-hidden">{t("loading")}...</span>
                    </div>
                    {t("save")}
                  </div>
                ) : (
                  t("save")
                )}
              </button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default AddSupplier;

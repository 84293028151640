import { priceFormat } from "../../../../../hooks/helper";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useTranslation } from "react-i18next";

const ProductTable = ({
  products,
  handleQtyChange,
  handleProdDelete,
  handleProductClick,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="prw-products-table">
      <div className="prw-products-table-header">
        <div>{t("product")}</div>
        <div>{t("quantity")}</div>
        <div>{t("price")}</div>
        <div>{t("subtotal")}</div>
        <div>{t("remove")}</div>
      </div>
      <div className="prw-products-table-body">
        {products?.map((prod, key) => (
          <div className="prw-products-table-single-row" key={key}>
            <div
              className="prw-product-table-img-name"
              onClick={() => handleProductClick(prod, key)}
              style={{ cursor: "pointer" }}
            >
              <div className="prw-product-table-img">
                <img src={prod.thumbnail_img} alt="" />
              </div>
              <div className="prw-product-table-title">
                <div>{prod.name}</div>
                <i>{prod?.variation}</i>
              </div>
            </div>
            <div className="prw-table-qty">
              <input
                type="number"
                min={1}
                value={prod.quantity}
                onChange={(event) => handleQtyChange(event, prod.id, prod)}
              />
            </div>
            <div
              onClick={() => handleProductClick(prod, key)}
              style={{ cursor: "pointer" }}
            >
              {priceFormat(prod.sale_price, i18n.language)}
            </div>
            <div
              onClick={() => handleProductClick(prod, key)}
              style={{ cursor: "pointer" }}
            >
              {priceFormat(prod.sale_price * prod.quantity, i18n.language)}
            </div>
            <div className="prw-table-remove">
              <RiDeleteBin6Line
                className="product-delete-button"
                onClick={() => handleProdDelete(prod.id)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductTable;

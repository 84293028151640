import Modal from "../../../../common/modal";
import AddAttribute from "./AddCategory";

const AddCategoryModal = ({ currentItem, setReload , openModal, setOpenModal }) => {

  return (
    <Modal
      open={openModal}
      title={``}
      onClose={() => setOpenModal(false)}
      maxWidth={"1050px"}
    >
      <div
        style={{
          width: "100%",
          maxWidth: "800px",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <div className="widget-container">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "400px auto",
              gap: "15px",
            }}
          >
			  <AddAttribute currentItem={currentItem} setReload={setReload} setOpenModal={setOpenModal}/>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddCategoryModal;

import { ErrorMessage, Field, FieldArray } from "formik";
import "./addQuotation.css";
import ProductPriceEditModal from "./ProductPriceEditModal";
import { useEffect, useState, useCallback } from "react";
function ProductEntryTable({ values, handleChange }) {
  const [open, setOpen] = useState(false);
  const [dataToEdit, setDataToEdit] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(null);

  //calculate price after discount

  const calculateDiscountOnItem = (index) => {
    const {
      discount = 0,
      discount_type,
      quantity = 0,
      sale_price,
    } = values.items[index];

    let discountAmount = 0;
    if (discount_type === "percentage") {
      discountAmount = ((sale_price * discount) / 100) * quantity;
      values.items[index].discount_amount = discountAmount;
      return discountAmount;
    }
    discountAmount = discount * quantity;
    values.items[index].discount_amount = discountAmount;
    return discountAmount;
  };

  const calculatePriceAfterDiscount = (index) => {
    const { sale_price = 0, quantity = 0 } = values.items[index];
    const subTotal = sale_price * quantity;
    const discount = calculateDiscountOnItem(index);
    const priceAfterDiscount = subTotal - discount;
    return priceAfterDiscount?.toFixed(2);
  };
  //calculate tax
  const calculateTaxOnItem = (index) => {
    const { tax = "" } = values.items[index];
    if (!tax) return 0;
    const taxPercentage = parseInt(tax?.split("_")[1]);
    const priceAfterDiscount = calculatePriceAfterDiscount(index);
    const taxAmount = priceAfterDiscount * (taxPercentage / 100);
    values.items[index].tax_amount = taxAmount?.toFixed(2);
    return taxAmount?.toFixed(2);
  };
  //calculate total
  const calculateItemTotal = (index) => {
    const priceAfterDiscount = calculatePriceAfterDiscount(index);
    const tax = calculateTaxOnItem(index);
    const total = parseFloat(priceAfterDiscount) + parseFloat(tax);
    values.items[index].total = total;
    return total?.toFixed(2);
  };

  const handleRowClick = (data, index) => {
    setDataToEdit(data);
    setSelectedIndex(index);
    setOpen(true);
  };

  const handleEdit = (data) => {
    const itemsClone = [...values.items];
    itemsClone[selectedIndex] = data;
    handleChange("items", itemsClone);
  };

  /**
   * Calculate Summary
   */

  const calculateSubTotal = useCallback(
    (items = []) => {
      const subtotal = items?.reduce(
        (a, b) => a + (b.sale_price || 0) * (b.quantity || 0),
        0
      );
      handleChange("subtotal", subtotal);
      return subtotal;
    },
    [handleChange] // Adding handleChange as a dependency
  );

  const calculateDiscount = useCallback(
    (items) => {
      const discount = items.reduce(
        (a, b) => a + parseFloat(b.discount_amount) || 0,
        0
      );
      handleChange("discount", discount);
      return discount;
    },
    [handleChange] // Adding handleChange as a dependency
  );

  const calculateVat = useCallback(
    (items) => {
      const vat = items.reduce((a, b) => a + parseFloat(b.tax_amount) || 0, 0);
      handleChange("vat", vat);
      return vat;
    },
    [handleChange] // Adding handleChange as a dependency
  );

  const calculateTotal = useCallback(
    (items) => {
      const total =
        calculateSubTotal(items) -
        calculateDiscount(items) +
        calculateVat(items);
      handleChange("total", total);
      return total;
    },
    [calculateSubTotal, calculateDiscount, calculateVat, handleChange] // Adding the dependencies
  );

  useEffect(() => {
    if (!values?.items?.length) return;
    calculateTotal(values.items);
  }, [values.items, calculateTotal]);

  return (
    <div className="table-responsive">
      <table className="table table-bordered table-hover">
        <thead className="table-light">
          <tr>
            <th style={{ width: "20%" }}>Item Name</th>
            <th style={{ width: "10%" }}>Stock</th>
            <th style={{ width: "10%" }}>Quantity</th>
            <th style={{ width: "10%" }}>Unit Price</th>
            <th style={{ width: "10%" }}>Discount</th>
            <th style={{ width: "10%" }}>Tax</th>
            <th style={{ width: "10%" }}>Total</th>
            <th style={{ width: "5%" }}>Action</th>
          </tr>
        </thead>
        <FieldArray name="items">
          {({ remove, push }) => (
            <tbody>
              {values.items?.length > 0 ? (
                values.items.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div
                        className="form-control form-control-sm"
                        onClick={() => handleRowClick(item, index)}
                        style={{
                          fontSize: "14px",
                          backgroundColor: "#e9ecef",
                          border: "1px solid #ced4da",
                          cursor: "pointer",
                        }}
                      >
                        {item?.name}
                      </div>
                    </td>
                    <td>
                      <div
                        className="form-control form-control-sm"
                        onClick={() => handleRowClick(item, index)}
                        style={{
                          fontSize: "14px",
                          backgroundColor: "#e9ecef",
                          border: "1px solid #ced4da",
                          cursor: "pointer",
                        }}
                      >
                        {item?.stock}
                      </div>
                    </td>
                    <td>
                      <Field
                        name={`items.${index}.quantity`}
                        type="number"
                        placeholder="Quantity"
                        className="form-control form-control-sm"
                        style={{ fontSize: "14px" }}
                      />
                      <ErrorMessage
                        name={`items.${index}.quantity`}
                        component="div"
                        className="text-danger"
                      />
                    </td>
                    <td>
                      <Field
                        name={`items.${index}.sale_price`}
                        type="number"
                        placeholder="Unit Price"
                        className="form-control form-control-sm"
                        style={{ fontSize: "14px" }}
                      />
                      <ErrorMessage
                        name={`items.${index}.sale_price`}
                        component="div"
                        className="text-danger"
                      />
                    </td>
                    <td>
                      <div
                        className="form-control form-control-sm"
                        onClick={() => handleRowClick(item, index)}
                        style={{
                          fontSize: "14px",
                          backgroundColor: "#e9ecef",
                          border: "1px solid #ced4da",
                          cursor: "pointer",
                        }}
                      >
                        {calculateDiscountOnItem(index) || 0}
                      </div>
                    </td>
                    <td>
                      <div
                        className="form-control form-control-sm"
                        onClick={() => handleRowClick(item, index)}
                        style={{
                          fontSize: "14px",
                          backgroundColor: "#e9ecef",
                          border: "1px solid #ced4da",
                          cursor: "pointer",
                        }}
                      >
                        {calculateTaxOnItem(index) || 0}
                      </div>
                    </td>

                    <td>
                      <div
                        className="form-control form-control-sm"
                        onClick={() => handleRowClick(item, index)}
                        style={{
                          fontSize: "14px",
                          backgroundColor: "#e9ecef",
                          border: "1px solid #ced4da",
                          cursor: "pointer",
                        }}
                      >
                        {calculateItemTotal(index) || 0}
                      </div>
                    </td>
                    <td className="text-center">
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        onClick={() => remove(index)}
                      >
                        -
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={9} className="text-center">
                    No Items Added
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </FieldArray>
      </table>
      <ProductPriceEditModal
        open={open}
        setOpen={setOpen}
        data={dataToEdit}
        handleEdit={handleEdit}
      />
    </div>
  );
}

export default ProductEntryTable;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiEdit } from "react-icons/fi";
import { RiUserUnfollowLine } from "react-icons/ri";
import PaginationCom from "../../common/pagination/PaginationCom";
import { useDebounce } from "../../hooks/useDebounce";
import { markutosAdminApi } from "../../services/Api/api";
import SimpleLoading from "../SimpleLoading";

import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";


const AllPurchases = () => {
  const { t } = useTranslation();
  const [reload, setReload] = useState(false);
  const [paymentType, setPaymentType] = useState("all");
  const [current, setCurrent] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  const toggle = (index) => {
    if (index === current) setCurrent(null);
    else setCurrent(index);
  };

  useEffect(() => {
    setLoading(true);
    markutosAdminApi()
      .get(
        `/pos/purchases?search_value=${debouncedSearchTerm}&per_page=${perPage}&page=${currentPage}`
      )
      .then((response) => {
        setLoading(false);
        console.log(response);
        setCurrentItems(response?.data?.data);
        setCurrentPage(response?.data?.current_page);
        setPerPage(response?.data?.per_page);
        setPageCount(response?.data?.last_page);
      });
  }, [perPage, currentPage, reload, debouncedSearchTerm, paymentType]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);

  const removeItem = (id) => {
    Swal.fire({
      title: t("are_sure"),
      text: t("delete_revert"),
      confirmButtonText: t("yes_delete_it"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        markutosAdminApi()
          .get(`pos/purchases/delete?purchase_id=${id}`)
          .then((response) => {
            toast(response.data.message);
            setReload(true);
            setLoading(true);
          });
      }
    });
  };

  return (
    <div className="responsive-table-container">
      <div className="single-widget table">
        <div className="table-top-header">
          <div className="table-title">{t("all") + ` ` + t("purchase")}</div>
          <div className="table-filters">
            <input
              type="text"
              placeholder="Search by Name"
              className="table-search-input"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Link to="/purchases/add">
              <button className="btn btn-primary">Add New</button>
            </Link>
          </div>
        </div>
        <div className="widget-container">
          <div
            className="table-header"
            style={{
              gridTemplateColumns: "50px 90px 90px 90px 100px 90px 100px 100px 90px 90px",
            }}
          >
            <div>#</div>
            <div>{t("purchase_date")}</div>
            <div>{t("status")}</div>
            <div>{t("reference_no")}</div>
            <div>{t("supplier")}</div>
            <div>{t("total")}</div>
            <div>{t("paid_payment")}</div>
            <div>{t("payment_status")}</div>
            <div>{t("created_by")}</div>
            <div className="text-center">{t("actions")}</div>
          </div>
          <div>
            {loading ? (
              <SimpleLoading />
            ) : (
              currentItems?.length > 0 &&
              currentItems?.map((purchase, index) => (
                <div key={index}>
                  <div
                    className="table-row"
                    style={{
                      gridTemplateColumns: "50px 90px 90px 90px 100px 90px 100px 100px 90px 90px",
                    }}
                  >
                    <div>{purchase?.id}</div>
                    <div>{purchase?.purchase_date}</div>
                    <div>{purchase?.purchase_status}</div>
                    <div>{purchase?.reference_number}</div>
                    <div>{purchase?.supplier?.name}</div>
                    <div>{purchase?.grand_total}</div>
                    <div>
                      {purchase?.payments?.reduce((a, b) => a + b.amount, 0)}
                    </div>
                    <div>{purchase?.payment_status}</div>
                    <div>{purchase?.created_by}</div>
                    <div className="action-column">
                      <button
                        title="Edit"
                        className="table-btn tb-edit"
                        onClick={() =>
                          navigate(`/purchases/edit/${purchase.id}`)
                        }
                      >
                        <FiEdit />
                      </button>
                      <button
                        className="table-btn tb-delete"
                        onClick={() => removeItem(purchase.id)}
                      >
                        <RiUserUnfollowLine />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            )}
            {currentItems?.length > 0 && (
              <PaginationCom
                currentItem={currentItems}
                perPage={perPage}
                pageCount={pageCount}
                currentPage={currentPage}
                setPerPage={setPerPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllPurchases;

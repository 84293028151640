import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import PaginationCom from "../../common/pagination/PaginationCom";
import { markutosAdminApi } from "../../services/Api/api";
import SimpleLoading from "../SimpleLoading";

const AllRoles = () => {
  const { t, i18n } = useTranslation();
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [reload, setReload] = useState(false);



  useEffect(() => {
    setLoading(true);
    markutosAdminApi()
      .get(
        `/roles?per_page=${perPage}&page=${currentPage}`
      )
      .then((response) => {
        setLoading(false);
        setReload(false);
        setCurrentItems(response?.data);
        setCurrentPage(response?.data?.current_page || 1);
        setPerPage(response?.data?.per_page || 10);
        setPageCount(response?.data?.last_page);
      });
  }, [perPage, currentPage, reload]);



  const removeItem = (id) => {
    Swal.fire({
      title: t("are_sure"),
      text: t("delete_revert"),
      confirmButtonText: t("yes_delete_it"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        markutosAdminApi()
          .get(`/roles/delete?role_id=${id}`)
          .then((response) => {
            setReload(true);
            toast(response.data.message);
          })
          .catch((error) => {
            toast(error?.data?.message || "Couldn't delete the role!");
          });
      }
    });
  };

  return (
    <div className="responsive-table-container">
      <div className="single-widget table">
        <div className="table-top-header">
          <div className="table-title">{t("all") + ` ` + t("roles")} </div>

         
        </div>
        <div className="widget-container">
          <div
            className="table-header"
            style={{
              gridTemplateColumns: "50px 1fr 1fr 100px",
            }}
          >
            <div>#</div>
            <div>{t("name")}</div>
            <div>{t("description")}</div>
            <div className="text-center">{t("options")}</div>
          </div>
          <div>
            {loading ? (
              <SimpleLoading />
            ) : (
              currentItems?.map((role, index) => (
                <div key={index}>
                  <div
                    className="table-row"
                    style={{
                      gridTemplateColumns:
                        "50px 1fr 1fr 100px",
                    }}
                  >
                    <div>{role?.id}</div>
                    
                    <div>{role?.name}</div>
                    <div>{role?.description}</div>
                  
                    <div className="action-column">
                      <button title="Edit" className="table-btn tb-edit">
                        <NavLink
                          style={{
                            width: "100%",
                            padding: "6px 8px",
                            borderRadius: "50%",
                            color: "white",
                          }}
                          to={`/users/edit-role/${role.id}`}
                        >
                          <FiEdit />
                        </NavLink>
                      </button>
                      <button
                        title="Delete"
                        className="table-btn tb-delete"
                        onClick={() => removeItem(`${role.id}`)}
                      >
                        <RiDeleteBinLine />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          {currentItems?.length > 0 && (
            <PaginationCom
              currentItem={currentItems}
              perPage={perPage}
              pageCount={pageCount}
              currentPage={currentPage}
              setPerPage={setPerPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AllRoles;

import { useTranslation } from "react-i18next";
import "./lowStockQuantity.css";

const LowStockQuantity = ({
  values,
  handleBlur,
  handleChange,
  errors,
  touched,
}) => {
  const { t } = useTranslation();
  return (
    <div className="add-product-single-widget">
      <div className="widget-title">{t('low_stock_warning')}</div>
      <div className="widget-content-container">
        <label className="mb-2" htmlFor="low-stock">
          <small>{t('quantity')} *</small>
        </label>
        <div>
          <input
            name="low_stock_quantity"
            value={values.low_stock_quantity}
            onChange={handleChange}
            onBlur={handleBlur}
            id="low-stock"
            type="number"
            min={0}
          />
          {errors.low_stock_quantity && touched.low_stock_quantity && (
            <small className="text-danger"> {errors.low_stock_quantity} </small>
          )}
        </div>
      </div>
    </div>
  );
};

export default LowStockQuantity;

import { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { markutosAdminApi } from "../../services/Api/api";
import SelectPermissions from "./SelectPermissions";
import {
  posPermissions,
  eComPermissions,
  configurationPermissions,
} from "./constants";
import { useNavigate, useParams } from "react-router-dom";
const AddRole = () => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    name: yup.string().required("Role Name is required"),
  });

  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      name: "",
      description: "",
      permissions: []
    },
    enableReinitialize: true,
    onSubmit: (values, action) => {
      setLoading(true);
      
      
      if (id) {
        markutosAdminApi()
        .post("/roles/update", {...values,role_id:id})
        .then((res) => {
          toast.success(res.data.message);
          action.resetForm();
          navigate("/users/role-list");
        })
        .catch((e) => {
          toast.error(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
      } else {
        markutosAdminApi()
          .post("/roles/add-new-role", values)
          .then((res) => {
            toast.success(res.data.message);
            action.resetForm();
            navigate("/users/role-list");
          })
          .catch((e) => {
            toast.error(e.message);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  const {
    values,
    setErrors,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,setValues  } = formik;

  useEffect(()=>{
    if(id){
      setLoading(true)
      markutosAdminApi().get(`/roles/edit?role_id=${id}`).then(response => {
        setValues(response.data);
      }).catch(e => {
        console.error(e);
        toast.error(e?.message || "Something went wrong!");
      }).finally(()=>{setLoading(false)})
    }
  },[id])
  if(loading){
    return <span className="spinner-grow spinner-grow-sm"></span>
  }
  return (
    <div>
      <div className="widget-title">
        {`${id ? "Edit" : "Add New"}`} {t("role")}
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="single-widget">
          <FocusError formik={formik} />
          <div className="widget-container">
            <div
              className="vartical-inputs"
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div>
                <label htmlFor="name">{t("name")}</label>
                <input
                  name="name"
                  id="name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.name && touched.name && (
                  <small className="text-danger"> {errors.name} </small>
                )}
              </div>
              <div>
                <label htmlFor="description">{t("description")}</label>
                <textarea
                  name="description"
                  id="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  rows={3}
                  cols={5}
                >
                  {values.description}
                </textarea>
                {errors.name && touched.name && (
                  <small className="text-danger"> {errors.name} </small>
                )}
              </div>
            </div>
          </div>
          <SelectPermissions values={values} setFieldValue={setFieldValue} />
        </div>
        <div className="mt-4">
          <button
            type="submit"
            onClick={handleSubmit}
            disabled={loading}
            className="add-btn"
          >
            {loading && <span className="spinner-grow spinner-grow-sm"></span>}
            {t("submit")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddRole;

import { FocusError } from "focus-formik-error";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Modal from "../../../../common/modal";
import { markutosAdminApi } from "../../../../services/Api/api";

const LedgerModal = ({ currentValues, openModal, setOpenModal, setReload }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      deposit: currentValues?.deposit || 0,
      previous_due: currentValues?.previous_due || 0,
      wallet_balance: currentValues?.wallet_balance || 0,
      total_purchase: currentValues?.total_purchase || 0,
    },
    enableReinitialize: true,
    onSubmit: (values, action) => {
      if (currentValues) {
        values.customer_id = currentValues.id;
      }
      setLoading(true);
      markutosAdminApi()
        .post(`pos/customer/update`, { ...currentValues, ...values })
        .then((res) => {
          setReload((pre) => !pre);
          setLoading(false);
          toast.success(res.data.message);
          if (currentValues) {
            setOpenModal(false);
          } else {
            action.resetForm();
          }
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e.message);
        });
    },
  });

  const {
    values,
    setErrors,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = formik;

  return (
    <Modal
      open={openModal}
      title={t("customer_ledger")}
      onClose={() => setOpenModal(false)}
      maxWidth={"500px"}
      buttons={
        <button
          type="submit"
          onClick={handleSubmit}
          disabled={loading}
          className="add-btn"
        >
          {loading && <span className="spinner-grow spinner-grow-sm"></span>}
          {t("save")}
        </button>
      }
    >
      <div
        style={{
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <div className="widget-container">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "200px auto",
              gap: "15px",
            }}
          >
            <FocusError formik={formik} />
            <label htmlFor="previous_due">{t("previous_due")}</label>
            <input
              type="number"
              id="previous_due"
              name="previous_due"
              value={values.previous_due}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.previous_due && touched.previous_due && (
              <small className="text-danger"> {errors.previous_due} </small>
            )}
            <label htmlFor="deposit">{t("deposit")}</label>
            <input
              type="number"
              id="deposit"
              name="deposit"
              value={values.deposit}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.deposit && touched.deposit && (
              <small className="text-danger"> {errors.deposit} </small>
            )}
            <label htmlFor="wallet_balance">{t("wallet_balance")}</label>
            <input
              type="number"
              id="wallet_balance"
              name="wallet_balance"
              value={values.wallet_balance}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.wallet_balance && touched.wallet_balance && (
              <small className="text-danger"> {errors.wallet_balance} </small>
            )}

            <label htmlFor="total_purchase">{t("total_purchase")}</label>

            <input
              type="number"
              id="total_purchase"
              name="total_purchase"
              value={values.total_purchase}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={true}
            />
            {errors.total_purchase && touched.total_purchase && (
              <small className="text-danger"> {errors.total_purchase} </small>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LedgerModal;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlinePlus } from "react-icons/hi";
import Select from "react-select";
import PaginationCom from "../../../../../common/pagination/PaginationCom";
import QuickView from "../../../../../common/quickView/QuickView";
import { priceFormat } from "../../../../../hooks/helper";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { markutosAdminApi } from "../../../../../services/Api/api";
import {
  addToDb,
  getStoredCart,
} from "../../../../../utils/manageLocalStorage";
import SimpleLoading from "../../../../SimpleLoading";
import AddProductModal from "./AddProductModal";
import "./PosSearchingProduct.css";
import addUrlParams from "../../../../../utils/addUrlParams";

const PosSearchingProduct = (resultData) => {
  const { t, i18n } = useTranslation();
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [categoryId, setCategoryId] = useState(null);
  const [brandId, setBrandId] = useState(null);
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [quickView, setQuickView] = useState({ open: false, product: null });
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    setLoading(true);
    const paramsAddedURI = addUrlParams("/pos/sales/products", {
      search_value: search,
      category_id: categoryId,
      brand_id: brandId,
      per_page: perPage,
      page: currentPage,
    });
    markutosAdminApi()
      .get(paramsAddedURI)
      .then((response) => {
        setLoading(false);
        setCurrentItems(response?.data?.data);
        setCurrentPage(response?.data?.current_page);
        setPerPage(response?.data?.per_page);
        setPageCount(response?.data?.last_page);
        setReload(false);
      });
  }, [currentPage, search, categoryId, brandId,reload]);

  useEffect(() => {
    const storageItem = getStoredCart();
    const items = [];
    for (const key in storageItem) {
      const addedProduct = currentItems?.find(
        (product) => product?.id === parseInt(key)
      );
      if (addedProduct) {
        addedProduct.quantity = parseInt(storageItem[key]);
        items.push(addedProduct);
      }
    }
    resultData.setProducts(storageItem);
  }, [currentItems]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);

  /**
   * When click on product
   * @param {*} prod
   */

  const handleProdClick = (prod) => {
    handleAddToCart(prod);
    // if (prod?.type == "simple") {
    //   handleAddToCart(prod);
    // } else {
    //   setQuickView({ product: prod, open: true });
    // }
  };

  /**
   * Adding to cart
   * @param {*} prod
   */
  const handleAddToCart = (prod) => {
    if (resultData?.products?.length) {
      const filterProducts = resultData?.products.find(
        (product) => product.id === prod.id
      );
      if (filterProducts) {
        const itemIndex = resultData?.products.findIndex(
          (product) => product.id === prod.id
        );
        const newProduct = { ...filterProducts };
        newProduct.quantity = parseInt(newProduct.quantity) + 1;
        const newProducts = [...resultData?.products];
        newProducts[itemIndex] = newProduct;
        resultData?.setProducts(newProducts);
      } else {
        prod.quantity = 1;
        const newProducts = [...resultData?.products, prod];
        resultData?.setProducts(newProducts);
      }
    } else {
      prod.quantity = 1;
      resultData?.setProducts([prod]);
    }
    addToDb([prod]);
  };

  return (
    <div className="pos-searching-prod">
      <div className="pos-searching-header">
        <div className="row">
          <div className="col-10">
            <input
              type="text"
              className="prod-search-input"
              placeholder={t("search_pro_name")}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="col-2">
            <button
              className="prw-customer-address-btn"
              onClick={() => setProductModalOpen(true)}
            >
              <HiOutlinePlus />
            </button>
          </div>
        </div>
        <div className="pos-searching-filter-container">
          <Select
            options={resultData.catList}
            placeholder={t("all") + ` ` + t("categories")}
            onChange={(e) => setCategoryId(e?.id)}
            getOptionLabel={(option) => option.name}
            getOptionValue={option => option.id}
            isClearable={true}
          />
          <Select
            options={resultData.brandList}
            placeholder={t("all") + ` ` + t("brands")}
            onChange={(e) => setBrandId(e?.id)}
            getOptionLabel={(option) => option.name}
            getOptionValue={option => option.id}
            isClearable={true}
            
          />
        </div>
      </div>
      {/* <div className="pos-searching-products-container">
        <div
          className="single-psp"
          onClick={() => handleProdClick({ id: 1, type: "simple" })}
        >
          <div className="single-psp-img">
            <img src="" alt="" />
          </div>
          <div className="single-psp-title">test</div>
          <span className="single-psp-price">10</span>
        </div>
      </div> */}
      <div className="pos-searching-products-container">
        {loading ? (
          <SimpleLoading />
        ) : (
          currentItems?.map((prod, key) => (
            <div
              className="single-psp"
              key={key}
              onClick={() => handleProdClick(prod)}
            >
              <div className="single-psp-img">
                <img src={prod?.thumbnail} alt="" />
              </div>

              <div className="single-psp-title">{prod.name}</div>
              <span className="single-psp-price">
                {priceFormat(prod.sale_price, i18n.language)}
                {/* {prod.product_type == "simple"
                  ? priceFormat(prod.unit_price, i18n.language)
                  : priceFormat(
                      prod.discount_price_range,
                      i18n.language,
                      prod.product_type
                    )} */}
              </span>
            </div>
          ))
        )}
      </div>
      <AddProductModal open={productModalOpen} setOpen={setProductModalOpen} setReload={setReload} categories ={resultData.catList} />
      {currentItems?.length > 0 && (
        <PaginationCom
          currentItem={currentItems}
          perPage={perPage}
          pageCount={pageCount}
          currentPage={currentPage}
          setPerPage={setPerPage}
          setCurrentPage={setCurrentPage}
        />
      )}
      {quickView.open == true && (
        <QuickView
          quickView={quickView}
          setQuickView={setQuickView}
          productAddToCart={handleAddToCart}
        />
      )}
    </div>
  );
};

export default PosSearchingProduct;

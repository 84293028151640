import { FocusError } from "focus-formik-error";
import { Form, useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import Modal from "../../../common/modal";
import { markutosAdminApi } from "../../../services/Api/api";
import { toast } from "react-toastify";

const AddProductModal = ({ open, setOpen, setReload }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [apiPath, setApiPath] = useState(false);

  const schema = yup.object({
    name: yup.string(),
    stock: yup.number(),
    purchase_price: yup.number(),
    sale_price: yup.number(),
    tax: yup.number(),
  });

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      name: "",
      stock: 0,
      quantity: 0,
      purchase_price: 0,
      sale_price: 0,
      tax: 0,
      discount_type: "flat",
      discount: 0,
      thumbnail: "/",
      category_id: 0,
      refundable: 0,
    },
    enableReinitialize: true,
    onSubmit: async (values, action) => {
      setLoading(true);
      // Handle form submission
      const payload = { ...values };
      payload.last_sale_price = values.sale_price;

      try {
        const response = await markutosAdminApi().post(
          "pos/products/add",
          payload
        );
        if (response?.data?.result) {
          toast.success(response.data.message);
          setOpen(false);
          setReload(true);
        }
      } catch (e) {
        toast.error(e?.message || "Something went wrong!");
        console.log(e);
      } finally {
        setLoading(false);
        action.resetForm();
      }
    },
  });

  const {
    values,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = formik;

  return (
    <Modal
      open={open}
      title={t("add") + " " + t("product")}
      onClose={() => setOpen(false)}
      maxWidth={"950px"}
      buttons={
        <button
          type="submit"
          onClick={handleSubmit}
          disabled={loading}
          className="add-btn"
        >
          {loading && <span className="spinner-grow spinner-grow-sm"></span>}
          {t("add")}
        </button>
      }
    >
      <Form>
        <FocusError formik={formik} />
        <div
          style={{
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div className="widget-container">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "200px auto",
                gap: "15px",
              }}
            >
              <label htmlFor="name">{t("name")}</label>
              <input
                type="text"
                id="name"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`form-control ${
                  errors.name && touched.name ? "is-invalid" : ""
                }`}
              />
              {errors.name && touched.name && (
                <small className="text-danger">{errors.name}</small>
              )}

              <label htmlFor="stock">{t("stock")}</label>
              <input
                type="number"
                id="stock"
                name="stock"
                value={values.stock}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`form-control ${
                  errors.stock && touched.stock ? "is-invalid" : ""
                }`}
              />
              {errors.stock && touched.stock && (
                <small className="text-danger">{errors.stock}</small>
              )}

              <label htmlFor="purchase_price">{t("unit_price")}</label>
              <input
                type="number"
                id="purchase_price"
                name="purchase_price"
                value={values.purchase_price}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`form-control ${
                  errors.purchase_price && touched.purchase_price
                    ? "is-invalid"
                    : ""
                }`}
              />
              {errors.purchase_price && touched.purchase_price && (
                <small className="text-danger">{errors.purchase_price}</small>
              )}
              <label htmlFor="sale_price">{t("sale_price")}</label>
              <input
                type="number"
                id="sale_price"
                name="sale_price"
                value={values.sale_price}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`form-control ${
                  errors.sale_price && touched.sale_price ? "is-invalid" : ""
                }`}
              />
              {errors.sale_price && touched.sale_price && (
                <small className="text-danger">{errors.sale_price}</small>
              )}

              <label htmlFor="tax">{t("tax")}</label>
              <input
                type="number"
                id="tax"
                name="tax"
                value={values.tax}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`form-control ${
                  errors.tax && touched.tax ? "is-invalid" : ""
                }`}
              />
              {errors.tax && touched.tax && (
                <small className="text-danger">{errors.tax}</small>
              )}
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AddProductModal;

import React,{ useEffect, useState} from "react";
import { useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import * as yup from "yup";
import { toast } from "react-toastify";
import { markutosAdminApi } from "../../../../services/Api/api";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const ShopPage = () => {
	const { t  } = useTranslation();
	const schema = yup.object().shape({
		attributes:  yup.array().min(1, t('at_least_1_attr')).required(t('attributes')+` `+t("are_req")),
		brands: yup.array().min(1, t('at_least_1_brand')).required(t('brands')+` `+t("are_req")),
	});
	const [attributes, setAttributes] = useState([]);
	const [brands, setBrands] = useState([]);
	const [loading, setLoading] = useState(false);
	const [currentItem, setCurrentItem] = useState("");
	const [attributeOptions, setAttributeOptions] = useState([]);
	const [brandOptions, setBrandOptions] = useState([]);

	useEffect(() => {
		// get Attributes
		markutosAdminApi()
		  .get(`/get-attributes`)
		  .then((response) => {
			setAttributeOptions(response?.data);
		  });

		// get brands
		markutosAdminApi()
		.get("/get-brands")
		.then((res) => {
		const brands = [];
		res.data.map((item) => {
			const singleBrand = {
			id: item.value,
			value: item.value,
			label: item.label,
			};
			brands.push(singleBrand);
		});
		setBrandOptions(brands);
		})
		.catch((e) => {});

		// get shop page data
		markutosAdminApi()
		.get(`/website-setup/get-shop-page-data`)
		.then((response) => {
			const shopData = response?.data[0];
			const brands = shopData?.brands;
			const attributes = shopData?.attributes;
			let filteredBrands = brandOptions.filter(el =>  brands.includes(el.id));
			let filteredAttributes = attributeOptions?.filter(el =>  attributes?.includes(el.value));

			setBrands(filteredBrands);
			setAttributes(filteredAttributes);
			setCurrentItem(shopData);
		});

	  }, []);
	  
	const formik = useFormik({
		validationSchema: schema,
		initialValues: {
			id: currentItem?.id || '',
			attributes: currentItem?.attributes || [],
			brands: currentItem?.brands || [],
		},
		enableReinitialize: true,
		onSubmit: (values, action) => {
			setLoading(true);
			markutosAdminApi()
			.post(`/website-setup/shop-page-store`, values )
			.then((res) => {
				setLoading(false);
				toast.success(res.data.message);
			})
			.catch((e) => {
				setLoading(false)
			  	toast.error(e.message);
			});
		},
	});

	const {
	values,
	setErrors,
	handleChange,
	touched,
	errors,
	handleSubmit,
	handleBlur,
	setFieldValue,
	} = formik;

	const handleAttributesChange = (att,type) => {
		let result = att.map(item => item.id);
		if ( type == "product_attributes") {
			setAttributes(att);
		} else {
			setBrands(att);
		}
		setFieldValue( type , result );
	};
	
  return (
		<div className="single-widget">
			<div className="widget-title">{t('shop')+` `} {t('page')} </div>
			<form  onSubmit={(e) => e.preventDefault()}>
				<FocusError formik={formik} />
				<div className="widget-container product-informaiton-form">
					{/* Attributes */}
					<label htmlFor="attributes">{t('attributes')}</label>
					<Select
                    	onChange={(e) => handleAttributesChange(e,"product_attributes") }
						isMulti
						name="product_attributes"
						options={attributeOptions}
						className="basic-multi-select"
						value={attributes}
						classNamePrefix="select"
                  	/>
					{ errors.attributes && (
						<small className="text-danger"> {errors.attributes} </small>
					)}
					{/* Brands */}
					<label htmlFor="brands">{t('brands')}</label>
					<Select
                    	onChange={(e) => handleAttributesChange(e,"brands") }
						isMulti
						name="brands"
						options={brandOptions}
						value={brands}
						className="basic-multi-select"
						classNamePrefix="select"
                  	/>
					{ ( errors.brands ) && (
						<small className="text-danger"> {errors.brands} </small>
					)}
				<div className="btns-container">
					<button 
					type="submit"
					onClick={handleSubmit}
					disabled={loading}
					className="add-btn">
					{loading && (
						<span className="spinner-grow spinner-grow-sm"></span>
					)}
						{ currentItem ? t('edit') : t('add') }
					</button>
				</div>
				</div>
			</form>
		</div>
  );
};

export default ShopPage;

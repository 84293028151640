import { FocusError } from "focus-formik-error";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import * as yup from "yup";
import Modal from "../../common/modal";

const VatEditModal = ({ open, setOpen, data, handleEdit }) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: (values, action) => {
      handleEdit(values);
      setOpen(false);
    },
  });

  const {
    values,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = formik;

  return (
    <Modal
      open={open}
      title={t("edit") + " " + t("product") + " " + t("vat")}
      onClose={() => setOpen(false)}
      maxWidth={"600px"}
      minHeight={"250px"}
      buttons={
        <button type="submit" onClick={handleSubmit} className="add-btn">
          {t("save")}
        </button>
      }
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <FocusError formik={formik} />

        <div className="widget-container">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "200px auto",
              gap: "15px",
            }}
          >
            <label htmlFor="name">{t("name")}</label>
            <input
              type="text"
              id="name"
              name="name"
              value={values?.name}
              disabled={true}
            />

            <label htmlFor="tax_type">{t("tax_type")}</label>
            <Select
              id="tax_type"
              options={[
                {
                  label: "Select One",
                  value: "default",
                },
                {
                  label: "Exclusive",
                  value: "exclusive",
                },
                {
                  label: "Inclusive",
                  value: "inclusive",
                },
              ]}
              placeholder={t("select_tax_type")}
              onChange={(e) => {
                setFieldValue("tax_type", e.value);
              }}
            />
            <label htmlFor="tax">{t("tax")}</label>
            <Select
              id="tax"
              options={[
                {
                  label: "Vat 5%",
                  value: "vat_5",
                },
                {
                  label: "Tax 10%",
                  value: "tax_10",
                },
                {
                  label: "Tax 18%",
                  value: "tax_18",
                },
              ]}
              placeholder={t("select_tax")}
              onChange={(e) => {
                setFieldValue("tax", e.value);
              }}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default VatEditModal;

import { useState , useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "./PosConfiguration.css";
import Payments from "./tabs/Payments";
import Shipping from "./tabs/Shipping";
import Tax from "./tabs/Tax";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FocusError } from "focus-formik-error";
import { markutosAdminApi } from "../../../services/Api/api";
import { useTranslation } from "react-i18next";

const PosConfiguration = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState("");
  const [key, setKey] = useState("payments");

	useEffect(() => {
		markutosAdminApi()
		.get(`/pos-manager/configuration`)
		.then((res) => {
			setLoading(false);
			setCurrentItem(res.data);
		})
		.catch((e) => {
			setLoading(false);
			toast.error(e.message);
		});

	}, []);

  const formik = useFormik({
    initialValues: {
      cash_on_delivery: currentItem?.cash_on_delivery || 1,
      stripe: currentItem?.stripe || 0,
      stripe_live_mode: currentItem?.stripe_live_mode || 0,
      stripe_test_public_key: currentItem?.stripe_test_public_key || "",
      stripe_test_secret_key: currentItem?.stripe_test_secret_key || "",
      stripe_live_public_key: currentItem?.stripe_live_public_key || "",
      stripe_live_secret_key: currentItem?.stripe_live_secret_key || "",
      shipping_methods: currentItem?.shipping_methods || "free_shipping",
      shipping_charge: currentItem?.shipping_charge || 0,
      tax_types: currentItem?.tax_types || "zero_rate",
      tax: currentItem?.tax || 0,
    },
    enableReinitialize: true,
    onSubmit: (data, action) => {
      setLoading(true);
      markutosAdminApi()
        .post(`/pos-manager/configuration/post`, data )
        .then((res) => {
          setLoading(false);
          toast.success(res.data.message);
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e.message);
        });
    },
  });

  const {
    values,
    setErrors,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = formik;

  return (
    <div className="pos-configuration">
    <form onSubmit={(e) => e.preventDefault()}>
      <FocusError formik={formik} />
        <div className="pos-activation-widget">
          <div style={{ minHeight: "400px" }}>
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="payments" title={t('payments')}>
                <Payments 
                  setFieldValue={setFieldValue}
                  setErrors={setErrors}
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  errors={errors}
                  touched={touched} 
                />
              </Tab>
              <Tab eventKey="shipping" title={t('shipping')}>
                <Shipping 
                  setFieldValue={setFieldValue}
                  setErrors={setErrors}
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  errors={errors}
                  touched={touched}
                />
              </Tab>
              <Tab eventKey="tax" title={t('tax')}>
                <Tax 
                  setFieldValue={setFieldValue}
                  setErrors={setErrors}
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  errors={errors}
                  touched={touched}
                />
              </Tab>
            </Tabs>
          </div>

          <button
            type="submit"
            onClick={handleSubmit}
            disabled={loading}
            className="add-btn"
          >
          {loading && <span className="spinner-grow spinner-grow-sm"></span>}
          {t('save_changes')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PosConfiguration;

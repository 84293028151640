import {useEffect,useState} from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FocusError } from "focus-formik-error";
import { markutosAdminApi } from "../../services/Api/api";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

const ManageProfile = ({currentItem,apiUrl,setOpenModal,setReload}) => {
	const { t } = useTranslation();
	const schema = yup.object().shape({
		name: yup.string().required(t('name_req')),
    email: yup.string().email().required(t('email_req')),
		phone: yup.string().required(t('phone_req')),
	});

	const [profileInfo, setProfileInfo] = useState({});
	const [submiting, setSubmitting] = useState(false);
	const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [apiPath, setApiPath] = useState(`/profile/update`);

  useEffect(() => {

    setApiPath(apiUrl || `/profile/update`);

    if ( apiUrl == `update-user` ) {
      setProfileInfo( currentItem );
    }
    else if ( apiUrl == `add-new-user` ) {
      setProfileInfo( {} );
    }
    else {
      getData();
      setProfileInfo( profileInfo );
    }

	}, [apiUrl,currentItem]);

	const formik = useFormik({
		validationSchema: schema,
		initialValues: {
      id: profileInfo.id || "",
      name: profileInfo.name || "",
      phone: profileInfo.phone || "",
      email: profileInfo.email || "",
      password: profileInfo.password || ""
		},
		enableReinitialize: true,
		onSubmit: (values, action) => {
      setSubmitting(true);
      markutosAdminApi()
      .post(apiPath, values )
        .then((res) => {
          toast.success(res.data.message);
          getData();
          setSubmitting(false);
          setReload(true);
          if (currentItem) {
            setOpenModal(false)
          }else{
            action.resetForm();
          }
        })
        .catch((err) => {
        toast.error(err.message);
        setSubmitting(false);
        });

      action.resetForm();
		},
	});

	const getData = () => {
		setLoading(true);
		markutosAdminApi()
		.get( "/profile" )
		.then((res) => {
			setProfileInfo(res.data);
			setLoading(false);
			setError("");
		})
		.catch((e) => {
			setLoading(false);
			setError(e.message);
		});
	};

	const passGenerate = () => {
		const alpha = "abcdefghijklmnopqrstuvwxyz";
		const calpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		const num = "1234567890";
		const specials = ",.!@#$%^&*";
		const options = [alpha, alpha, alpha, calpha, calpha, num, num, specials];
		let opt, choose;
		let pass = "";
		for (let i = 0; i < 8; i++) {
		opt = Math.floor(Math.random() * options.length);
		choose = Math.floor(Math.random() * options[opt].length);
		pass = pass + options[opt][choose];
		options.splice(opt, 1);
		}
		setFieldValue("password", pass);
	};



	const {
		values,
		handleChange,
		touched,
		errors,
		handleSubmit,
		handleBlur,
		setFieldValue,
	} = formik;


  return (
    <div>
      <div className="single-widget">
        <div className="widget-title">
          {
            apiPath == `/profile/update` ? (
                <h5 className="px-3 py-2 pt-3">{t('manage')} {t('profile')} </h5>
            ) : (
                <h5 className="px-3 py-2 pt-3"> { apiPath == `add-new-user` ?  `${t('add')} ${t('new')} ${t('user')} `  : `${t('update')}  ${t('user')}` }</h5>
            )
          }
        </div>
        <form  onSubmit={(e) => e.preventDefault()}>
          <FocusError formik={formik} />
          <div className="widget-container vartical-inputs">
          <div>
            <label htmlFor="name">{t('name')}</label>
            <input type="text" name="name" id="name" placeholder={t('name')}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.name && touched.name && (
              <small className="text-danger"> {errors.name} </small>
            )}
          </div>
          <div>
            <label htmlFor="email">{t('email')}</label>
            <input type="email" name="email" id="email" placeholder={t('email')}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.email && touched.email && (
              <small className="text-danger"> {errors.email} </small>
            )}
          </div>
          <div>
            <label htmlFor="phone">{t('phone')}</label>
            <input type="tel" name="phone" id="phone" placeholder={t('phone')}
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.phone && touched.phone && (
              <small className="text-danger"> {errors.phone} </small>
            )}
          </div>
          <div>
          <label htmlFor="password">{t('password')} *</label>
            <div>
              <input
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                id="password"
              />

              {errors.password && touched.password ? (
                <small className="text-danger"> {errors.password} </small>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="btns-container">
            <button 
            type="submit"
            onClick={handleSubmit}
            disabled={submiting}
            className="add-btn">
            {submiting && (
              <span className="spinner-grow spinner-grow-sm"></span>
            )}
            { currentItem ? t('edit') : t('add')}
            </button>
            <button
              onClick={passGenerate}
              type="button"
              className="btn btn-outline-primary"
            >
              {t('generate')} {t('password')}
            </button>
          </div>
          </div>
        </form>
      </div>
    </div>
  );

};

export default ManageProfile;

import {useEffect,useState} from "react";
import Select from "react-select";
import Modal from "../../../common/modal";
import { markutosAdminApi } from "../../../services/Api/api";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FocusError } from "focus-formik-error";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

const AddCouponModal = ({ currentItem ,openModal,setOpenModal, setReload }) => {
  const { t } = useTranslation();
  const cupon_type = [{label: t("product_based"),value:"product_based"},{label:t("order_based"),value:"order_based"}]
  const discount_type = [{label:t("flat"),value:"flat"},{label:t("percent"),value:"percent"}];

  const [apiPath, setApiPath] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cuponType, setCouponType] = useState({label:t("order_based"),value:"order_based"});
  const [discountType, setDiscountType] = useState({label:t("flat"),value:"flat"});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const apiPath = currentItem?.id ? `update-coupon` : `add-new-coupon`;
    setApiPath(apiPath);
    if ( currentItem !== null ) {
      setCouponType(cupon_type.filter(el => el.value == currentItem?.coupon_type));
      setDiscountType(discount_type.find(el => el.value == currentItem?.discount_type));
      setStartDate( currentItem !== null ? new Date(currentItem?.start_date) :  new Date());
      setEndDate( currentItem !== null ? new Date(currentItem?.end_date) : new Date());
    }
  }, []);

  const schema = yup.object().shape({
    code: yup.string().required(t("coupon")+` `+t("code")+` `+t("is_req")),
    coupon_type: yup.string().required(t("coupon_type")+` `+t("is_req")),
    discount_type: yup.string().required(t("discount_type")+` `+t("is_req")),
    discount: yup.string().required(t("discount")+` `+t("is_req")),
    start_date: yup.string().required(t("start_date")+` `+t("is_req")),
    end_date: yup.string().required(t("end_date")+` `+t("is_req")),
  });

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      details: currentItem?.details || "",
      code: currentItem?.code || "",
      coupon_type: currentItem?.coupon_type || "",
      discount_type: currentItem?.discount_type || "",
      discount: currentItem?.discount || "",
      start_date: new Date(currentItem?.start_date) || new Date(),
      end_date: new Date(currentItem?.end_date)  || new Date(),
    },
    enableReinitialize: false,
    onSubmit: (values, action) => {
      if (currentItem) {
        values.coupon_id = currentItem.id;
      }
      setLoading(true);
      markutosAdminApi()
        .post(`/coupons/${apiPath}`, values)
        .then((res) => {
          setReload(pre => !pre);
          setLoading(false);
          toast.success(res.data.message);
          if (!currentItem) {
            action.resetForm();
          }
          setOpenModal(false);

        })
        .catch((e) => {
          setLoading(false);
          toast.error(e.message);
        });
    },
  });

  const {
    values,
    setErrors,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = formik;

  const selectOnChange =(data,type)=>{
    setFieldValue(type,data.value);
    if ( type == "coupon_type" ) {
      setCouponType(data);
    } else {
      setDiscountType(data);
    }
  }

  return (
    <Modal
      open={openModal}
      title={currentItem ? t("edit")+` `+t("code")+` `+t("information") : t("add")+` `+t("code")+` `+t("information") }
      onClose={(e) => setOpenModal(false)}
      maxWidth={"1050px"}
      buttons={
        <button className="add-btn" 
        type="submit"
        onClick={handleSubmit}
        disabled={loading}
        >
          {loading && <span className="spinner-grow spinner-grow-sm"></span>}
          {`${currentItem ? t('edit') : t('add') }`}
        </button>
      }
    >
      <div
        style={{
          width: "100%",
          maxWidth: "800px",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <div className="widget-container">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "50% auto",
              gap: "15px",
            }}
          >
            <FocusError formik={formik} />
            <div>
              <label htmlFor="coupon-name">{t("coupon")+` `+t("code")}</label>
              <input type="text" name="code" 
                  id="coupon-name"
                  value={values?.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              {errors.code && touched.code && (
                <small className="text-danger"> {errors.code} </small>
              )}
            </div>
            <div>
              <label>{t("coupon_type")}</label>
              <Select placeholder={t("select")+` `+t("coupon_type")}
                  value={cuponType}
                  options={cupon_type}
                  onChange={(e)=> selectOnChange(e,"coupon_type") } 
              />
              {errors.coupon_type && touched.coupon_type && (
                <small className="text-danger"> {errors.coupon_type} </small>
              )}
            </div>
            <div>
              <label>{t("discount_type")}</label>
              <Select placeholder={t("select")+` `+t("discount_type")} 
                value={discountType}
                options={discount_type} 
                onChange={(e)=> selectOnChange(e,"discount_type") }
              />
              {errors.discount_type && touched.discount_type && (
                <small className="text-danger"> {errors.discount_type} </small>
              )}
            </div>
            <div>
              <label htmlFor="discount">{t("discount")}</label>
              <input type="text" name="discount" 
                  id="discount"
                  value={values?.discount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              {errors.discount && touched.discount && (
                <small className="text-danger"> {errors.discount} </small>
              )}
            </div>
            <div>
              <label htmlFor="str-date">{t("start_date")}</label>
              <DatePicker
                  dateFormat={"yyy-MM-dd"}
                  name={"start_date"}
                  selected={startDate}
                  onChange={(date) => setFieldValue("start_date", date) + setStartDate(date)}
                />
              {errors.start_date && touched.start_date && (
                <small className="text-danger"> {errors.start_date} </small>
              )}
            </div>
            <div>
              <label htmlFor="end-date">{t("end_date")}</label>
                <DatePicker
                  dateFormat={"yyy-MM-dd"}
                  name={"end_date"}
                  selected={endDate}
                  onChange={(date) => setFieldValue("end_date", date) + setEndDate(date) }
                />
              {errors.end_date && touched.end_date && (
                <small className="text-danger"> {errors.end_date} </small>
              )}
            </div>
            <div>
              <label htmlFor="details">{t("details")}</label>
              <textarea name="details" 
                id="details"
                onChange={handleChange}
                onBlur={handleBlur}
                rows={"5"} cols={"5"}
                value={values?.details}
              >
                {values?.details}
              </textarea>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddCouponModal;

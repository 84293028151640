import { useTranslation } from "react-i18next";
import "./DashboardTopProducts.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import SimpleLoading from "../../../SimpleLoading";
import { priceFormat } from "../../../../hooks/helper";

const DashboardTopProducts = (data) => {
  const { t, i18n } = useTranslation();
  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const topProducts = Array.isArray(data?.top_products)
    ? data?.top_products
    : [];

  return (
    <div className="single-widget">
      <div className="widget-title">{t("top_pro")}</div>
      <div className="top-products-slider-container">
        <Slider {...settings}>
          {data?.loading ? (
            <SimpleLoading />
          ) : (
            topProducts?.map((prod, key) => (
              <div className="tps-single-prod" key={key}>
                <div className="tps-single-prod-img">
                  <img src={prod?.thumbnail_img} alt="" />
                </div>
                <div className="tps-single-prod-price">
                  {priceFormat(prod?.unit_price, i18n.language)}
                </div>
                <div className="tps-single-prod-name">{prod?.name}</div>
              </div>
            ))
          )}
        </Slider>
      </div>
    </div>
  );
};

export default DashboardTopProducts;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import "./style.css";
function PriceSection({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) {
  const { t } = useTranslation();
  const [selectedOtherCharges, setSelectedOtherCharges] = useState(null);
  const [selectedDiscountOnAll, setSelectedDiscountOnAll] = useState(null);

  const handleCalculateGrandTotal = () => {
    const finalOtherCharges =
      (values?.other_charges ||
      0) + ((values?.other_charges * selectedOtherCharges?.value) ||
      0);
     
    const subTotal = (values?.total || 0) + finalOtherCharges;
    let finalDiscount = 0;
    if (selectedDiscountOnAll?.value === "fixed") {
      finalDiscount = values?.discount_on_all || 0;
    } else if (selectedDiscountOnAll?.value === "percentage") {
      finalDiscount = (values?.discount_on_all/100 || 0) * subTotal;
    }
    setFieldValue("other_charges_type", selectedOtherCharges?.value);
    setFieldValue("discount_type", selectedDiscountOnAll?.value);
    setFieldValue("grand_total", subTotal - finalDiscount);
    setFieldValue("discount_on_all_amount", finalDiscount);
    setFieldValue("other_charges_amount", finalOtherCharges);
  };

useEffect(()=>{
  if(values?.discount_type){
    setSelectedDiscountOnAll(discountOptions.find((option) => option.value === values?.discount_type));
  }
  if(values?.other_charges_type){
    setSelectedOtherCharges(fixedChargesOptions.find((option) => option.value === Number(values?.other_charges_type)));
  }
},[])

  useEffect(()=>{
    handleCalculateGrandTotal();
  },[values?.other_charges,values?.total,values?.discount_on_all,selectedDiscountOnAll,selectedOtherCharges])
  return (
    <div className="row align-items-start">
      <div className="col-8">
        <div className="row my-2">
          <div className="col-6">{t("total_quantity")}</div>
          <div className="col-6">{values?.total_quantity || 0}</div>
        </div>
        <div className="row align-items-center my-2">
          <div className="col-4">{t("other_charges")}</div>
          <div className="col-4">
            <input
              type="number"
              name="other_charges"
              value={values.other_charges}
              onChange={handleChange}
              onBlur={handleBlur}
              style={{ height: "38px" }}
            />
            {errors.other_charges && touched.other_charges && (
              <small className="text-danger">{errors.other_charges}</small>
            )}
          </div>
          <div className="col-4">
            <Select
              id="other_fixed_charges"
              name="other_fixed_charges"
              value={selectedOtherCharges}
              onChange={(option) => setSelectedOtherCharges(option)}
              onBlur={handleBlur}
              options={fixedChargesOptions}
            />
          </div>
        </div>

        <div className="row align-items-center my-2">
          <div className="col-4">{t("discount_on_all")}</div>
          <div className="col-4">
            <input
              type="number"
              name="discount_on_all"
              value={values.discount_on_all}
              onChange={handleChange}
              onBlur={handleBlur}
              style={{ height: "38px" }}
            />
            {errors.discount_on_all &&
              touched.discount_on_all && (
                <small className="text-danger">
                  {errors.discount_on_all}
                </small>
              )}
          </div>
          <div className="col-4">
            <Select
              id="discount_on_all_type"
              name="discount_on_all_type"
              value={selectedDiscountOnAll}
              onChange={(option) => setSelectedDiscountOnAll(option)}
              onBlur={handleBlur}
              options={discountOptions}
            />
          </div>
        </div>
        <div className="row align-items-center my-2">
          <div className="col-4">{t("note")}</div>
          <div className="col-8">
            <textarea
              name="note"
              value={values.note}
              onChange={handleChange}
              onBlur={handleBlur}
              rows={5}
            />
            {errors.note && touched.note && (
              <small className="text-danger">{errors.note}</small>
            )}
          </div>
        </div>
      </div>
      <div className="col-4">
        <div className="row my-2">
          <div className="col-md-6">
            <h6>{t("Sub Total")}:</h6>
          </div>
          <div className="col-md-6">{values?.total || 0}</div>
        </div>
        <div className="row my-2">
          <div className="col-md-6">
            <h6>{t("Other Charges")}:</h6>
          </div>
          <div className="col-md-6">
            {values?.other_charges_amount || 0}
          </div>
        </div>
        <div className="row my-2">
          <div className="col-md-6">
            <h6>{t("Discount on All")}:</h6>
          </div>
          <div className="col-md-6">
            {values.discount_on_all_amount || 0}
          </div>
        </div>
        <div className="row my-2">
          <div className="col-md-6">
            <h6>{t("Grand Total")}:</h6>
          </div>
          <div className="col-md-6">
            {values?.grand_total || 0}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PriceSection;

const fixedChargesOptions = [
  { value: 0, label: "None" },
  { value: 0.05, label: "Vat 5%" },
  { value: 0.1, label: "Tax 10%" },
  { value: 0.18, label: "Tax 18%" },
  { value: 0.045, label: "IGST 4.5%" },
  { value: 0.045, label: "SGST 4.5%" },
  { value: 0.09, label: "GST 9%" },
  { value: 0.09, label: "ISGT 9%" },
  { value: 0.09, label: "SGST 9%" },
  { value: 0.18, label: "GST 18%" },
];

const discountOptions = [
  { value: "fixed", label: "Fixed" },
  { value: "percentage", label: "Percentage (%)" },
]
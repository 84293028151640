import React, { useEffect, useState } from "react";
import "./ProductInformation.css";
import Select from "react-select";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import UploadFilesModal from "../../../../../../common/UploadFiles/UploadFilesModal";
import authHeader from "../../../../../../services/auth-header";
import { markutosAdminApi } from "../../../../../../services/Api/api";
import { useTranslation } from "react-i18next";

const ProductInfoDigital = ({
  values,
  handleBlur,
  handleChange,
  errors,
  touched,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const [tags, setTags] = useState(["example tag"]);
  const [show, setShow] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);

  useEffect(() => {
    markutosAdminApi()
      .get("/add-new-digital-products/get-digital-categories", {
        headers: {
          Authorization: authHeader(),
        },
      })
      .then((res) => {
        const category = [];

        res.data.map((item) => {
          const singleCategory = {
            id: item.value,
            value: item.label,
            label: item.label,
          };
          category.push(singleCategory);
        });
        setCategoryOptions(category);
      })
      .catch((e) => {});
  }, []);

  return (
    <div className="add-product-single-widget">
      <div className="widget-title">{t('general')}</div>
      <div className="widget-content-container ">
        <div className="product-informaiton-form">
          {/*
          <div>
            <label htmlFor="product__name">
              <span>Product Type</span>
              <i>*</i>
            </label>
          </div>
           <div>
            <Select
              onBlur={handleBlur}
              name="product_type"
              onChange={(option) => setFieldValue("product_type", option.value)}
              id="product_type"
              options={productTypeOptions}
              placeholder="Product Type"
              value={productTypeOptions.find((option) => {
                return option.value == values.product_type;
              })}
            />
            {errors.product_type && touched.product_type && (
              <small className="text-danger"> {errors.product_type} </small>
            )}
          </div> */}

          <div className="">
            <label htmlFor="product__name">
              <span>{t('pro_name')}</span>
              <i>*</i>
            </label>
          </div>
          <div>
            <input
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              id="name"
              type="text"
            />
            {errors.name && touched.name && (
              <small className="text-danger"> {errors.name} </small>
            )}
          </div>

          <div>
            <label htmlFor="product__category">
              <span>{t('category')}</span>
              <i>*</i>
            </label>
          </div>
          <div>
            <Select
              onBlur={handleBlur}
              name="category_id"
              onChange={(option) => setFieldValue("category_id", option.id)}
              id="category_id"
              options={categoryOptions}
              value={categoryOptions.find((option) => {
                return option.id == values.category_id;
              })}
              placeholder={t('all_categories')}
            />

            {errors.category_id && touched.category_id && (
              <small className="text-danger"> {errors.category_id} </small>
            )}
          </div>

          <div>
            <label>
              <span>{t('tags')} *</span>
            </label>
          </div>
          <div>
            <ReactTagInput
              name="tags"
              tags={values.tags}
              onChange={(newTags) => setFieldValue("tags", newTags)}
              removeOnBackspace={true}
            />

            {errors.tags && touched.tags && (
              <small className="text-danger">{errors.tags}</small>
            )}
          </div>
          <div>
            <label>
              <span> {t('add_files')} *</span>
            </label>
          </div>
          <div>
            <div onClick={() => setShow(!show)} className="custom-browse">
              <div>{t('browse')}</div>
              <div>

                {values?.file ? (
                  <small className="me-2">{values.file},</small>
                ) : (
                  t('choose_file')
                )}
              </div>
            </div>

            {errors.file && touched.file && (
              <small className="text-danger"> {errors.file} </small>
            )}
          </div>
          <UploadFilesModal
            setFieldValue={setFieldValue}
            format="file"
            values={values}
            imageFor={"file"}
            show={show}
            setShow={setShow}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductInfoDigital;

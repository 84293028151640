import React, { useState, useEffect } from "react";
import { HiOutlineChevronRight } from "react-icons/hi";
import { MdPayment } from "react-icons/md";
import "./PayoutRequests.css";
import RippleButton from "../../../common/ui/rippleButton";
import PaginationCom from "../../../common/pagination/PaginationCom";
import SimpleLoading from "../../SimpleLoading";
import { markutosAdminApi } from "../../../services/Api/api";
import { useDebounce } from "../../../hooks/useDebounce";
import DateRangeSelector from "../../../common/ui/dateRangeSelector";
import { priceFormat } from "../../../hooks/helper";
import PayoutRequestModal from "./PayoutRequestModal";
import { useTranslation } from "react-i18next";

const PayoutRequests = () => {
  const { t,i18n } = useTranslation();
  const [reload, setReload] = useState(false);
  const [current, setCurrent] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentItem, setCurrentItem] = useState("");

  const toggle = (index) => {
    if (index === current) setCurrent(null);
    else setCurrent(index);
  };

  useEffect(() => {
    setLoading(true);
    markutosAdminApi()
      .get(`/all-sellers/payout-requests?search_value=${search}&date_from=${startDate}&date_to=${endDate}&per_page=${perPage}&page=${currentPage}`)
      .then((response) => {
        setLoading(false);
        setCurrentItems(response?.data?.data);
        setCurrentPage(response?.data?.current_page);
        setPerPage(response?.data?.per_page);
        setPageCount(response?.data?.last_page);
      });
  }, [perPage, currentPage, search, startDate, endDate, reload]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);

  const singleItem = (item) => {
    setOpenModal(true);
    setCurrentItem(item);
  };
  return (
    <div className="responsive-table-container">
      <div className="single-widget table">
        <div className="table-top-header">
          <div className="table-title">{t('seller')+` `+t('payment')+` `+t('requests')}</div>
          <div className="table-filters">
            <input
              type="text"
              className="table-search-input"
              placeholder={t('type_shop_name')}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="text-end pe-4 ">
              <DateRangeSelector
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </div>
          </div>
        </div>
        <div className="widget-container ">
          <div
            className={
              currentItems?.length < 10
                ? "table-header table__height"
                : "table-header"
            }
            style={{
              gridTemplateColumns: "50px 50px auto 150px 150px 100px 100px",
            }}
          >
            <div></div>
            <div>#</div>
            <div>{t('name')}</div>
            <div>{t('shop_name')}</div>
            <div className="text-center">{t('pending')+` `+t('amount')}</div>
            <div className="text-center">{t('status')}</div>
            <div className="text-center">{t('options')}</div>
          </div>
          {loading ? (
            <SimpleLoading />
          ) : (
            currentItems?.length > 0 &&
            currentItems.map((payout, index) => (
              <div key={index}>
                <div
                  className="table-row"
                  style={{
                    gridTemplateColumns:
                      "50px 50px auto 150px 150px 100px 100px",
                  }}
                >
                  <div
                    className="action-column"
                    style={{ cursor: "pointer" }}
                    onClick={() => toggle(index)}
                  >
                    <HiOutlineChevronRight />
                  </div>
                  <div>{payout.id}</div>
                  <div>{payout.seller_name}</div>
                  <div>{payout.shop_name}</div>
                  <div className="text-center">
                    {priceFormat(payout.amount,i18n.language)}
                  </div>
                  <div className="text-center">
                    {payout.status}
                  </div>
                  <div className="action-column">
				  {
				  payout.status == "paid" ?
				  	(t('done')) : 
					(
						<RippleButton onClick={(e) => singleItem(payout)}>
							<MdPayment />
							<span>{t('pay')}</span>
					  	</RippleButton>	
					)
					}
                    
                  </div>
                </div>
                {current === index && (
                  <div className="row-extra">
                    <div className="row-extra-row">
                      <div>{t('date')}</div>
                      <div>{payout.created_at}</div>
                    </div>
                    <div className="row-extra-row">
                      <div>{t('message')}</div>
                      <div>{payout.message}</div>
                    </div>
                  </div>
                )}
              </div>
            ))
          )}
          {currentItems.length > 0 && (
            <PaginationCom
              currentItem={currentItems}
              perPage={perPage}
              pageCount={pageCount}
              currentPage={currentPage}
              setPerPage={setPerPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
      {openModal && (
        <PayoutRequestModal
          setReload={setReload}
          currentItem={currentItem}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      )}
    </div>
  );
};

export default PayoutRequests;

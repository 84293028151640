import { useState } from "react";
import "./Category.css";
import AddCategory from "./components/AddCategory";
import CategoryTable from "./components/CategoryTable";

const Category = () => {
	const [reload, setReload] = useState(false);
    return (
        <div className="prod-grid-container">
			<CategoryTable reload={reload} setReload={setReload}/>
			<AddCategory setReload={setReload}/>
        </div>
    );
};

export default Category;

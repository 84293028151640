import { FocusError } from "focus-formik-error";
import { Form, useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import Modal from "../../../../../common/modal";
import { markutosAdminApi } from "../../../../../services/Api/api";
import Select from "react-select";

const AddProductModal = ({ open, setOpen, setReload, categories }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [apiPath, setApiPath] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const schema = yup.object({
    name: yup.string(),
    stock: yup.number(),
    quantity: yup.number(),
    unit_price: yup.number(),
    tax: yup.number(),
    category: yup.object().required(),
  });

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      name: "",
      stock: 0,
      quantity: 0,
      unit_price: 0,
      tax: 0,
    },
    enableReinitialize: true,
    onSubmit: (values, action) => {
      values.sale_price = values.unit_price;
      values.thumbnail = "/";
      values.category_id = values.category.id;
      markutosAdminApi()
        .post("/pos/products/add", values)
        .then((response) => {
          setOpen(false);
          setReload(true);
        });
    },
  });

  const {
    values,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
    handleReset,
  } = formik;

  return (
    <Modal
      open={open}
      title={t("add") + " " + t("product")}
      onClose={() => setOpen(false)}
      maxWidth={"600px"}
      buttons={
        <button
          type="submit"
          onClick={handleSubmit}
          disabled={loading}
          className="add-btn"
        >
          {loading && <span className="spinner-grow spinner-grow-sm"></span>}
          {t("add")}
        </button>
      }
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <FocusError formik={formik} />
        <div
          style={{
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div className="widget-container">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "200px auto",
                gap: "15px",
              }}
            >
              <label htmlFor="name">{t("name")}</label>
              <input
                type="text"
                id="name"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`form-control ${
                  errors.name && touched.name ? "is-invalid" : ""
                }`}
              />
              {errors.name && touched.name && (
                <small className="text-danger">{errors.name}</small>
              )}
              <label htmlFor="category">{t("category")}</label>
              <Select
                options={categories}
                placeholder={t("select") + ` ` + t("categories")}
                value={values.category}
                onChange={(e) => setFieldValue("category", e)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              />
              {errors.category && touched.category && (
                <small className="text-danger">{errors.category}</small>
              )}

              <label htmlFor="stock">{t("stock")}</label>
              <input
                type="number"
                id="stock"
                name="stock"
                value={values.stock}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`form-control ${
                  errors.stock && touched.stock ? "is-invalid" : ""
                }`}
              />
              {errors.stock && touched.stock && (
                <small className="text-danger">{errors.stock}</small>
              )}

              <label htmlFor="quantity">{t("quantity")}</label>
              <input
                type="number"
                id="quantity"
                name="quantity"
                value={values.quantity}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`form-control ${
                  errors.quantity && touched.quantity ? "is-invalid" : ""
                }`}
              />
              {errors.quantity && touched.quantity && (
                <small className="text-danger">{errors.quantity}</small>
              )}

              <label htmlFor="unit_price">{t("unit_price")}</label>
              <input
                type="number"
                id="unit_price"
                name="unit_price"
                value={values.unit_price}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`form-control ${
                  errors.unit_price && touched.unit_price ? "is-invalid" : ""
                }`}
              />
              {errors.unit_price && touched.unit_price && (
                <small className="text-danger">{errors.unit_price}</small>
              )}

              <label htmlFor="tax">{t("tax")}</label>
              <input
                type="number"
                id="tax"
                name="tax"
                value={values.tax}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`form-control ${
                  errors.tax && touched.tax ? "is-invalid" : ""
                }`}
              />
              {errors.tax && touched.tax && (
                <small className="text-danger">{errors.tax}</small>
              )}
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AddProductModal;

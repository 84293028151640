import { Fragment, useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FocusError } from "focus-formik-error";
import { markutosAdminApi } from "../../../services/Api/api";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

const AddSeller = ({ currentItem, setOpenModal,setReload }) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    first_name: yup.string().required(t('f_name_req')),
    last_name: yup.string().required(t('l_name_req')),
    shop_name: yup.string().required(t('shop_name_req')),
    email: yup.string().email().required(t('email_req')),
    phone: yup.string().required(t('phone_req')),
  });

  const [loading, setLoading] = useState(false);
  const [apiPath, setApiPath] = useState(false);

  useEffect(() => {
    const apiPath = currentItem?.id ? `update-seller` : `add-new-seller`;
    setApiPath(apiPath);
  }, [currentItem]);

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
	  verification_status: currentItem?.verification_status || 0,
      first_name: currentItem?.first_name || "",
      last_name: currentItem?.last_name || "",
      shop_name: currentItem?.shop_name || "",
      shop_address: currentItem?.shop_address || "",
      email: currentItem?.email || "",
      phone: currentItem?.phone || "",
      country: currentItem?.country || "",
      city: currentItem?.city || "",
      area: currentItem?.area || "",
      pin_code: currentItem?.pin_code || "",
      commission: currentItem?.commission || "",
      account_number: currentItem?.account_number || "",
      bank_name: currentItem?.bank_name || "",
      branch_name: currentItem?.branch_name || "",
      routing_number: currentItem?.routing_number || "",
      swift: currentItem?.swift || "",
      account_holder: currentItem?.account_holder || "",
      password: currentItem?.password || "",
    },
    enableReinitialize: true,
    onSubmit: (values, action) => {
      if (values.commission == "") {
        values.commission = 0;
      }
      if (currentItem) {
        values.seller_id = currentItem.id;
      }
      setLoading(true);
      markutosAdminApi()
        .post(`/all-sellers/${apiPath}`, values )
        .then((res) => {
          setReload(pre=>!pre);
          setLoading(false);
          toast.success(res.data.message);
          if (currentItem) {
            setOpenModal(false);
          } else {
            action.resetForm();
          }
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e.message);
        });
    },
  });

  const {
    values,
    setErrors,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = formik;
  
  return (
    <div style={{ maxWidth: "700px", margin: "0 auto" }}>
      <form onSubmit={(e) => e.preventDefault()}>
        <FocusError formik={formik} />
        <div className="single-widget">
          <div className="widget-header">
			<div className="widget-title">{`${ currentItem ? t('edit') : t('add') }`}  {t('seller') +` `+t('information')} </div>
          </div>
          <div className="widget-container">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "10px",
              }}
            >
              <p
                style={{ gridColumn: "1 / 2 span", fontWeight: "500" }}
                className="color-main"
              >
                {t('basic')+` `+t('information')}
              </p>
			  	<label htmlFor="seller_status">{t('seller')+` `+t('publish')+` `+t('status')}  </label>
				<label className="confi-switch">
					<input type="checkbox" defaultChecked={ parseInt( values?.verification_status == true ? 1 : values?.verification_status) }
					onChange={(e)=> setFieldValue('verification_status', e.target.checked ||  0 )}
					/>
					<span className="slider round"></span>
				</label>
              <div>
                <label htmlFor="first_name">{t('first_name')}</label>
                <input
                  type="text"
                  id="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t('enter_f_name')}
                />
                {errors.first_name && (
                  <p className="error"> {errors.first_name} </p>
                )}
              </div>
              <div>
                <label htmlFor="last_name">{t('last_name')}</label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t('enter_f_name')}
                />
                {errors.last_name && (
                  <p className="error"> {errors.last_name} </p>
                )}
              </div>
              <div>
                <label htmlFor="phone">{t('phone')}</label>
                <input
                  type="tell"
                  id="phone"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t('enter_phone')}
                />
                {errors.email && <p className="error"> {errors.phone} </p>}
              </div>
              <div>
                <label htmlFor="email">{t('email')}</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t('enter_email')}
                />
                {errors.email && <p className="error"> {errors.email} </p>}
              </div>

              <p
                style={{ gridColumn: "1 / 2 span", fontWeight: "500" }}
                className="color-main"
              >
                {t('address')}
              </p>
              <label htmlFor="shop_name">{t('shop_name')}</label>
              <input
                type="text"
                id="shop_name"
                name="shop_name"
                value={values.shop_name}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t('enter_shop_name')}
              />
              {errors.shop_name && (
                <p className="error"> {errors.shop_name} </p>
              )}

              <label htmlFor="shop_address">{t('shop')+` `+t('address')}</label>
              <textarea
                id="shop_address"
                rows={"5"}
                cols={"30"}
                name="shop_address"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.shop_address}
                placeholder={t('enter_shop_address')}
              ></textarea>
              <div>
                <label htmlFor="country">{t('country')}</label>
                <input
                  type="text"
                  id="country"
                  name="country"
                  value={values.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t('enter_country')}
                />
              </div>
              <div>
                <label htmlFor="city">{t('city')}</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t('enter_city')}
                />
              </div>
              <div>
                <label htmlFor="area">{t('area')}</label>
                <input
                  type="text"
                  id="area"
                  name="area"
                  value={values.area}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t('enter_area')}
                />
              </div>
              <div>
                <label htmlFor="pin_code">{t('pin_code')}</label>
                <input
                  type="text"
                  id="pin_code"
                  name="pin_code"
                  value={values.pin_code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t('enter_pin_code')}
                />
              </div>
              <p
                style={{ gridColumn: "1 / 2 span", fontWeight: "500" }}
                className="color-main"
              >
                {t('bank')+` `+t('details')} 
              </p>
              <label htmlFor="account_number">{t('account_number')}</label>
              <input
                type="text"
                id="account_number"
                name="account_number"
                value={values.account_number}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t('enter_account_number')}
              />
              <label htmlFor="bank_name">{t('bank')+` `+t('name')}</label>
              <input
                type="text"
                id="bank_name"
                name="bank_name"
                value={values.bank_name}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t('enter_bank_name')}
              />
              <label htmlFor="branch_name">{t('branch')+` `+t('name')}</label>
              <input
                type="text"
                id="branch_name"
                name="branch_name"
                value={values.branch_name}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t('enter_branch_name')}
              />
              <label htmlFor="routing_number">{t('routing_number')}</label>
              <input
                type="text"
                id="routing_number"
                name="routing_number"
                value={values.routing_number}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t('enter_route_no')}
              />
              <label htmlFor="swift">{t('swift')}</label>
              <input
                type="text"
                id="swift"
                name="swift"
                value={values.swift}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t('enter_swift')}
              />
              <label htmlFor="account_holder">{t('account_holder')}</label>
              <input
                type="text"
                id="account_holder"
                name="account_holder"
                value={values.account_holder}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t('ent_account_holder')}
              />
			{
				! currentItem && (
					<Fragment>
						<label htmlFor="password">{t('password')}</label>
						<input
						type="text"
						id="password"
						name="password"
						value={values.password}
						onChange={handleChange}
						onBlur={handleBlur}
						placeholder={t('enter_password')}
						/>
						{errors.password && <p className="error"> {errors.password} </p>}
					</Fragment>
				)
			}


              <p
                style={{ gridColumn: "1 / 2 span", fontWeight: "500" }}
                className="color-main"
              >
                {t('config')}
              </p>

              <label htmlFor="seller-commission">{t('seller')+` `+t('commission')} </label>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto 40px",
                  gap: "5px",
                }}
              >
                <input
                  type="text"
                  id="seller-commission"
                  name="commission"
                  value={values.commission}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t('ent_seller_commission')}
                />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#efefef",
                  }}
                >
                  %
                </span>
              </div>
            </div>
            <div className="btns-container">
              <button
                type="submit"
                onClick={handleSubmit}
                disabled={loading}
                className="add-btn"
              >
                {loading && (
                  <span className="spinner-grow spinner-grow-sm"></span>
                )}
                {currentItem ? t('edit') : t('add')}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddSeller;


import { Fragment, useEffect } from "react";
import { useState } from "react";
import { markutosAdminApi } from "../../../services/Api/api";
import { toast } from "react-toastify";
import { Table } from "react-bootstrap";
import PaginationCom from "../../../common/pagination/PaginationCom";
import { useTranslation } from "react-i18next";

const CategorySetup = () => {
  const { t } = useTranslation();
  const [type, setType] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
	const [search, setSearch] = useState("");
  const allTypes = ["featured","pinned","most_popular","daily_deals","featured_products","shop"];


  const getCategories = ({_search="", _currentPage=currentPage, _perPage=perPage}) => {
    markutosAdminApi()
    .get(`/all-category?search_value=${_search}&per_page=${_perPage}&page=${_currentPage}`)
    .then((response) => {
			setCategories(response?.data?.data);
			setCurrentPage(response?.data?.current_page);
			setPerPage(response?.data?.per_page);
			setPageCount(response?.data?.last_page);
    })
    .catch((error) => {
      console.log(error.message);
    });
  }
  // get categories
  useEffect(() => {
    getCategories({_search: search, _perPage: perPage, _currentPage: currentPage});
    setLoading(false);
    
  }, [perPage, currentPage, search ]);

  const updateType = ( categoryId , key , checked ) =>{
    setType(allTypes[key]);
    setLoading(true);
    markutosAdminApi()
    .get(`/category/index-state?category_id=${categoryId}&type=${allTypes[key]}`)
    .then((res) => {
      getCategories({_search: search, _perPage: perPage, _currentPage: currentPage});
      toast.success(res.data.message);
    })
    .catch((error) => {
      console.log(error.message);
    });
  }

  return (
	  <Fragment>
      <div className="table-top-header">
        <div className="table-title">{t('manage')+` `+t('categories')} </div>
				<div className="tableFilters">
					<input
						type="text"
						className="table-search-input"
						placeholder={t('search_by_cat')}
						value={search}
						onChange={(e) => setSearch(e.target.value)}
					/>
				</div>
			</div>
        <Table bordered responsive>
              <thead>
                <tr>
                  <th>{t('category')}</th>
                  <th>{t('left_sidebar')}</th>
                  <th>{t('pinned')}</th>
                  <th>{t('popular')}</th>
                  <th>{t('daily_deals')}</th>
                  <th>{t('featured')}</th>
                  <th>{t('shop')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  categories?.map((item,key)=>{
                    return(
                      <tr key={key}>
                        <td>{item.name}</td>
                        <td>
                          <label className="confi-switch">
                            <input type="checkbox" 
                              onChange={(e)=> updateType( item.id , 0 , e.target.checked )}
                              checked={parseInt(item.featured)}  />
                            <span className="slider round"></span>
                          </label>
                        </td>
                        <td>
                          <label className="confi-switch">
                            <input type="checkbox" 
                              onChange={(e)=> updateType( item.id , 1 , e.target.checked )}
                              checked={parseInt(item.pinned)} />
                            <span className="slider round"></span>
                          </label>
                        </td>
                        <td>
                          <label className="confi-switch">
                            <input type="checkbox" 
                              onChange={(e)=> updateType( item.id , 2 , e.target.checked )}
                              checked={parseInt(item.most_popular)} />
                            <span className="slider round"></span>
                          </label>
                        </td>
                        <td>
                          <label className="confi-switch">
                            <input type="checkbox" 
                              onChange={(e)=> updateType( item.id , 3 , e.target.checked )}
                              checked={ parseInt(item.daily_deals) } />
                            <span className="slider round"></span>
                          </label>
                        </td>
                        <td>
                          <label className="confi-switch">
                            <input type="checkbox" 
                              onChange={(e)=> updateType( item.id , 4 , e.target.checked )}
                              checked={parseInt(item.featured_products)} />
                            <span className="slider round"></span>
                          </label>
                        </td>
                        <td>
                          <label className="confi-switch">
                            <input type="checkbox" 
                              onChange={(e)=> updateType( item.id , 5 , e.target.checked )}
                              checked={parseInt(item.shop)} />
                            <span className="slider round"></span>
                          </label>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
        </Table>
        {categories.length > 0 && (
            <PaginationCom
              currentItem={categories}
              perPage={perPage}
              pageCount={pageCount}
              currentPage={currentPage}
              setPerPage={setPerPage}
              setCurrentPage={setCurrentPage}
              // onChange={getCategories}
            />
          )}
    </Fragment>
  );
};

export default CategorySetup;
